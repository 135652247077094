<template>
  <div class="indexMapthree">
    <div class="left">
      <div class="item1 flex-column">
        <div>
          <span class="num">
            <span>{{
              obj.sumCurrEfficiency ? Number(obj.sumCurrEfficiency) : 0
            }}</span>
          </span>
          <span class="unit">w</span>
        </div>
        <div class="mt10">实时功率</div>
      </div>
      <div class="item1 flex-column">
        <div>
          <span class="num">
            <span>{{
              obj.sumCurrDayPowerNum ? (obj.sumCurrDayPowerNum * 1).toFixed(0) : 0
            }}</span>
          </span>
          <span class="unit">度</span>
        </div>
        <div class="mt10">当日发电量</div>
      </div>
      <div class="item2">
        <div class="">
          <div>
            <span class="num">
              <span>{{
                obj.sumCurrMonthPowerNum
                  ? (obj.sumCurrMonthPowerNum * 1).toFixed(0)
                  : 0
              }}</span>
            </span>
            <span class="unit">度</span>
          </div>
          <div class="mt10">当月发电量</div>
        </div>
        <div class="">
          <div>
            <span class="num">
              <span>{{
                obj.sumCurrYearPowerNum
                  ? Number(obj.sumCurrYearPowerNum).toFixed(0)
                  : 0
              }}</span>
            </span>
            <span class="unit">度</span>
          </div>
          <div class="mt10">当年发电量</div>
        </div>
      </div>
      <div class="item2">
        <div class="">
          <div>
            <span class="num">
              <span>{{
                efficient.equivalentHours
                  ? Number(efficient.equivalentHours)
                  : 0
              }}</span>
            </span>
            <span class="unit">h</span>
          </div>
          <div class="mt10">月有效发电时长</div>
        </div>
        <div class="">
          <div>
            <span class="num">
              <span>{{
                efficient.yaervalentHours
                  ? Number(efficient.yaervalentHours)
                  : 0
              }}</span>
            </span>
            <span class="unit">h</span>
          </div>
          <div class="mt10">年有效发电时长</div>
        </div>
      </div>
      <div class="left3">
        <div class="left3-box">
          <dv-border-box-10 :color="['#11aeda']">
            <div style="height: 100%">
              <div class="left3-title">月日均等有效时TOP5</div>
              <div style="height: 98%; margin-left: -30px">
                <top5House :top5="top5"></top5House>
              </div>
            </div>
          </dv-border-box-10>
        </div>
      </div>
    </div>
    <div class="mapbox">
      <mapVue class="map"></mapVue>
      <div class="cicle">
        <el-row :gutter="20" type="flex" justify="space-between">
          <el-col :span="6">
            <div class="round">
              <img src="../../assets/mapNav/map1.png" alt="" />
              <div class="num1">
                <div>
                  <span>
                    {{ obj.volumeCount ? obj.volumeCount * 1 : 0 }}
                  </span>
                  kw
                </div>
                <div>装机容量</div>
              </div>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="round">
              <img src="../../assets/mapNav/map2.png" alt="" />
              <div class="num1">
                <div>
                  <span>
                    {{ obj.stationCount ? obj.stationCount * 1 : 0 }}</span>&nbsp;座
                </div>
                <div>电站数量</div>
              </div>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="round">
              <img src="../../assets/mapNav/map3.png" />
              <div class="num1">
                <div>
                  <span>
                    {{ obj.sumCurrYearPowerNum ? 114 * Number(obj.sumCurrYearPowerNum).toFixed(0) : 0 }}</span>&nbsp;t
                </div>
                <div>年累计节约标准煤</div>
              </div>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="round">
              <img src="../../assets/mapNav/map.png" />
              <div class="num1">
                <div>
                  <span>
                    {{ obj.sumCurrYearPowerNum ? (obj.sumCurrYearPowerNum * 1).toFixed(0) : 0 }}</span>&nbsp;t
                </div>
                <div>年累计CO₂减排</div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="right">
      <dv-border-box-10 class="mymon" :color="['#11aeda']">
        <div class="mymon-title">月电量统计<span class="unit">(度)</span></div>
        <monthEcharts style="widht: 100%; height: 24vh" :color="'#ed8700'"></monthEcharts>
      </dv-border-box-10>
      <dv-border-box-10 class="mymon" :color="['#11aeda']">
        <div class="mymon-title">年电量统计<span class="unit">(度)</span></div>
        <yearEcharts style="widht: 100%; height: 24vh" :color="'#ed8700'"></yearEcharts>
      </dv-border-box-10>
      <dv-border-box-10 class="mymon" :color="['#11aeda']">
        <div class="mymon-title">
          逆变器工作实况<span class="unit">(台)</span>
        </div>
        <nibianqiEcharts style="height: 90%"></nibianqiEcharts>
      </dv-border-box-10>
    </div>
    <timeVue class="times"></timeVue>
  </div>
</template>

<script>
import mapVue from "./components/map.vue";
import top5House from "./components/top5House.vue";
import monthEcharts from "./components/monthEcharts.vue";
import yearEcharts from "./components/yearEcharts.vue";
import timeVue from "./components/time.vue";
import nibianqiEcharts from "./components/nibianqiEcharts.vue";
export default {
  components: {
    mapVue,
    top5House,
    monthEcharts,
    yearEcharts,
    timeVue,
    nibianqiEcharts,
  },
  data() {
    return {
      modedata: {},
      numone: [],
      numtwo: [],
      completeness: {}, // 完成度
      top5plus: [],
      top5: [],
      count: {}, // 装机
      co: {}, // 二氧化碳
      efficient: {}, //有效
      obj: {},
      workOrderList: [
        {
          name: "通讯中断",
          value: 30,
          icon: require("../../assets/bbar.png"),
          color: "#00b2e6",
        },
        {
          name: "故障停机",
          value: 30,
          icon: require("../../assets/bbar.png"),
          color: "#fe53bd",
        },
        {
          name: "告警运行",
          value: 30,
          icon: require("../../assets/bbar.png"),
          color: "#d81e06",
        },
        // {
        //   name: "正常停机",
        //   value: 30,
        //   icon: require("../../assets/bbar.png"),
        //   color: "#d57200",
        // },
        {
          name: "正常运行",
          value: 70,
          icon: require("../../assets/bbar.png"),
          color: "#3be9bd",
        },
      ], //
    };
  },

  created() {
    this.getData();
    this.getData2();
  },

  methods: {
    formatProgress(item) {
      return () => {
        return `${item}h`;
      };
    },
    // 获取数据
    async getData() {
      const res = await this.$http.post("PcRealMonitoring/PCPositionList1", {});
      if (res.data.code === 200) {
        this.numone = Number(res.data.data[0][0].currEfficiency)
          .toFixed(0)
          .toString()
          .split("");
        if (this.numone.length < 8) {
          while (this.numone.length < 8) {
            this.numone.unshift(0);
          }
        }
        this.numtwo = Number(res.data.data[0][0].currDayPowerNum)
          .toFixed(0)
          .toString()
          .split("");
        if (this.numtwo.length < 8) {
          while (this.numtwo.length < 8) {
            this.numtwo.unshift(0);
          }
        }
        // 电站建设发电量
        this.modedata = res.data.data[0][0];
        console.log(this.modedata, 502);
        // // 电站建设完成度
        this.completeness = res.data.data[3][0];
        const colorList = [
          "#f9d071",
          "#fe6dc7",
          "#8400ff",
          "#1fb5fc",
          "#69edd1",
        ];
        res.data.data[1].forEach((item, index) => {
          item.color = colorList[index];
        });

        this.top5 = res.data.data[1];
        // this.top5plus = res.data.data[2];

        //二氧化碳
        this.co = res.data.data[4][0];
        // 装机容量
        this.count = res.data.data[7][0];
        // 等有效
        this.efficient = res.data.data[5][0];
      } else {
        this.$message.error(res.data);
      }
    },
    async getData2() {
      const res = await this.$http.post(
        "device/queryUserAllStationDailyPower",
        { userCode: JSON.parse(localStorage.getItem("userInfo")).userCode
        // stationCodeList: JSON.parse(localStorage.getItem('stationCodeList')) 
      }
      );
      if (res.data.code === 200) {
        this.obj = res.data.data;
      } else {
        this.$message.error(res.data);
      }
    },
  },
};
</script>

<style scoped lang="less">
.indexMapthree {
  position: absolute;
  display: flex;
  top: 85px;
  left: 0;
  bottom: 0;
  right: 0;
  background: linear-gradient(to bottom, #051525, #0e2944ed);
}

::v-deep .rightBox .el-progress__text {
  color: #d6f2ff !important;
  font-size: 16px !important;
}

.mapbox {
  width: 50%;

  .map {
    width: 100%;
    height: 67vh;
    border: 1px solid #11aeda;
  }

  .cicle {
    margin-top: 1vh;
    height: 22vh;
    display: flex;
    align-items: center;
    justify-content: space-around;

    .round {
      width: 170px;
      height: 170px;
      text-align: center;
      position: relative;
      margin: 0 20px;
      color: #d6f2ff;

      // background: url("../../assets/mapNav/map.png") no-repeat center center;
      img {
        position: absolute;
        top: 0;
        left: 10px;
        width: 150px;
        display: block;
        animation: circle 4s infinite linear;
      }

      .num1 {
        position: absolute;
        width: 100%;
        padding-top: 10px;

        span {
          font-size: 28px;
          color: #e6db3c;
          font-family: "YouSheBiaoTiHei";
        }
      }
    }
  }
}

@keyframes circle {
  0% {
    transform: translateY(0);
  }

  25% {
    transform: translateY(8px);
  }

  75% {
    transform: translateY(-8px);
  }

  100% {
    transform: translateY(0);
  }
}

.transition-300 {
  transition: all 300ms;
  cursor: pointer;
}

.shake-vertical {
  animation: shake-vertical 3s linear infinite;
}

.overlay {
  color: white;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: space-around;
}

::v-deep .el-progress-bar {
  margin-top: 2% !important;
}

.flex-column {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #d6f2ff;
}

.flex {
  display: flex;
  justify-content: space-between;
}

.left {
  width: 25%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin: 0 20px;

  .item1 {
    width: 100%;
    height: 130px;
    background: url("../../assets/mapNav/mp43.png") no-repeat center center;
    background-size: 440px 130px;
  }

  .item2 {
    width: 100%;
    height: 130px;
    background: url("../../assets/mapNav/mp43.png") no-repeat center center;
    background-size: 440px 130px;
    display: flex;
    align-items: center;

    >div {
      flex: 1;
      text-align: center;
      color: #dcf4ff;
    }
  }

  .item3 {
    width: 100%;
    height: 130px;
    background: url("../../assets/mapNav/mp43.png") no-repeat center center;
    background-size: 440px 130px;
    display: flex;
    align-items: center;

    >div {
      flex: 1;
      text-align: center;
      color: #dcf4ff;
    }
  }

  .left3 {
    margin-left: 20px;
    margin-right: 20px;

    // width: 100%;
    .left3-title {
      padding-top: 20px;
      // margin-bottom:10px;
      text-align: center;
      color: #dcf4ff;
      font-size: 20px;
    }

    .progress-area {
      padding-left: 40px;
    }

    .left3-box {
      width: 100%;
      height: 28vh;
      position: relative;
      color: #11aeda;
    }
  }

  .num {
    font-size: 26px;
    color: #67c23a;
    font-family: "YouSheBiaoTiHei";
  }
}

.right {
  width: 25%;
  height: 100%;
  margin: 0 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.unit {
  font-size: 18px;
  margin-left: 5px;
}

.mt10 {
  margin-top: 10px;
}

.mymon {
  width: 100%;
  height: 28vh;
  box-sizing: border-box;

  .wat {
    width: 100%;
    // border: 1px solid #02a7f0;
    border-radius: 5px;
    // margin-top: 37px;
    justify-content: space-between;
    padding: 20px;
    position: relative;

    .bar-name {
      text-align: center;
      margin-top: 8px;
    }

    .loadbar {
      width: 78px;
      height: 150px;
      background-color: #1d2429;
      border-radius: 10px;
      //  border:1px solid #ccc;
      position: relative;

      .bbar {
        position: absolute;
        top: 30%;
        left: 30%;

        .bbarnum {
          color: #fff;
          margin-bottom: 20px;
        }

        img {
          width: 32px;
          height: 32px;
        }
      }
    }

    .bar {
      width: 100%;
      display: block;
      font-family: arial;
      font-size: 12px;
      border-radius: 0 0 10px 10px;
      // background-color: #ff534a;
      color: #fff;
      position: absolute;
      bottom: 0;
      text-align: center;
    }

    .bar-top {
      position: absolute;
      top: -18px;
      left: 33%;
      background-image: url("../../assets/bar-top.png");
      background-size: contain;
      background-repeat: no-repeat;
      color: #0967ae;
      text-align: center;
      width: 181px;
      height: 45px;
      padding-top: 10px;
    }
  }

  .mymon-title {
    width: 100%;
    text-align: center;
    color: #d6f2ff;
    font-size: 20px;
    text-align: center;
    padding-top: 20px;
  }
}

.times {
  width: 300px;
  left: 50%;
  margin-left: -150px;
  position: absolute;
}

.theCircle {
  position: relative;
  width: 80px;
  /* 设置圆形容器的宽度 */
  height: 150px;
  /* 设置圆形容器的高度 */
  overflow: hidden;
  /* 确保容器裁剪水波纹效果 */
  border-radius: 10px;

  .Water {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // background-color: #38b973; /* 水波纹的颜色 */
    overflow: hidden;
    z-index: 1;
    /* 确保水波纹在文字之上 */
  }

  .CenteredText {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* 居中显示文字 */
    color: #ffffff;
    /* 文字颜色 */
    z-index: 2;
    /* 确保文字在水波纹之上 */
  }

  .Water::after {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    width: 150%;
    height: 150%;
    border-radius: 40%;
    background-color: #1d2429;
    /* 水波纹内部颜色 */
    animation: real 5s linear infinite;
    /* 实际水波纹的动画效果 */
  }

  .Water::before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    width: 150%;
    height: 150%;
    border-radius: 42%;
    background-color: #1d2429;
    /* 水波纹外部颜色及透明度 */
    animation: virtual 7s linear infinite;
    /* 虚拟水波纹的动画效果 */
  }
}

@keyframes real {
  0% {
    /* 初始状态：向上平移50%、左平移65%并旋转0度 */
    transform: translate(-50%, -65%) rotate(0deg);
  }

  100% {
    /* 终止状态：向上平移50%、左平移65%并旋转360度，形成旋转一周的效果 */
    transform: translate(-50%, -65%) rotate(360deg);
  }
}

@keyframes virtual {
  0% {
    /* 初始状态：向上平移50%、左平移60%，不进行缩放，旋转0度 */
    transform: translate(-50%, -60%) scale(1) rotate(0deg);
  }

  100% {
    /* 终止状态：向上平移50%、左平移60%，进行1.1倍的缩放，旋转360度，
            形成旋转一周的效果并放大水波纹 */
    transform: translate(-50%, -60%) scale(1.1) rotate(360deg);
  }
}
</style>
