<template>
  <div :class="{'scale-dance': !parentDance, 'scale-dance--item': parentDance}" :style="parentStyle"
  >
    <div class="scale-dance--item" ref="scale_dance"
      :style="{width:'100%', height:'100%', animationName:animationName, animationDuration:duration, animationTimingFunction:timingFunction}"
    >
      <slot></slot>
    </div>
  </div>
</template>

<script>
import Util from "../../../utils/util.js";
export default {
  name: "ScaleDance",
  data() {
    return {
      styleTag: null,
      animationName: "scale-dance-animation"
    }
  },
  props: {
    from: {
      type: Number,
      default: 1
    },
    to: {
      type: Number,
      default: 0.5
    },
    duration: String,
    width: String,
    height: String,
    timingFunction: String,
    parentDance: Boolean
  },
  computed: {
    parentStyle() {
      const style = {width:this.width, height:this.height};
      const animationStyle = {animationName:this.animationName, animationDuration:this.duration, animationTimingFunction:this.timingFunction};
      if (this.parentDance) {
        Object.assign(style, animationStyle);
      }
      return style;
    }
  },
  created() {
    this.createStyle(); //此组件肯定是在主题页面已经加载后才加载，所以可以在created中创建style标签
  },
  mounted() {
    this.$refs.scale_dance.style.transform = `scale(${this.from})`;
  },
  methods: {
    createStyle() {
      this.animationName += `__${Util.getRandomString(6)}`;
      // js创建@keyframes
      const runkeyframes =` @keyframes ${this.animationName} {
          0% {
            transform: scale(${this.from})
          }
          40% {
            transform: scale(${this.to})
          }
          80%,100% {
            transform: scale(${this.from})
          }
      }`
      // 创建style标签
      this.styleTag = document.createElement('style');
      this.styleTag.type = 'text/css';
      this.styleTag.innerHTML = runkeyframes;
      // 将style样式存放到head标签
      document.getElementsByTagName('head')[0].appendChild(this.styleTag);
    }
  },
  beforeDestroyed() {
    this.styleTag.parentNode.removeNode(this.styleTag);
  }
}
</script>

<style lang="less" scoped>
@import "./style.less";
</style>