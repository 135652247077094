import Vue from "vue";
import VueRouter from "vue-router";
/**
 * 重写路由的push方法
 */
const routerPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch((error) => error);
};

// 路由懒加载
const Login = () => import("../views/Login.vue");
const Index = () => import("../views/Index.vue");
const Welcome = () => import("../views/auth/index/Welcome.vue");
const RoleMenu = () => import("../views/auth/roleMenu/RoleMenu.vue");

const OrganizationManagement = () =>
  import("../views/auth/organization/OrganizationManagement.vue");
const Post = () => import("../views/auth/post/Post.vue");

const Authority = () => import("../views/auth/authority/Authority.vue");
const OrganizationTypeMaintenance = () =>
  import("../views/auth/organizationType/OrganizationType.vue");
const myAccountView = () => import("../views/auth/my/MyAccountView.vue");
const SystemConfig = () => import("../views/systemConfig/SystemConfig.vue");
const SystemConfigDetails = () =>
  import("../views/systemConfig/SystemConfigDetails.vue");
const TableDesign = () => import("../views/coding/tableDesign/TableDesign.vue");
const Table = () => import("../views/coding/table/Table.vue");
const TableDetails = () => import("../views/coding/table/TableDetails.vue");
const TableDdl = () => import("../views/coding/tableDdl/TableDdl.vue");
const TableDdlDetails = () =>
  import("../views/coding/tableDdl/TableDdlDetails.vue");
// const FromDesigner = () => import('../views/fromDesigner/FromDesigner.vue')

const LoginLog = () => import("../views/loginLog/LoginLog.vue");
const LoginLogDetails = () => import("../views/loginLog/LoginLogDetails.vue");

//design
const FromDesigner = () => import("../components/formDesigner.vue");
const DialogTest = () => import("../views/fromDesigner/dialogTest.vue");
const QueryDialogTest = () =>
  import("../views/fromDesigner/queryDialogTest.vue");
const View = () => import("../views/fromDesigner/view.vue");
const DesignTable = () => import("../views/fromDesigner/table.vue");

import MapNavigation from "../views/MapNavigation/Index.vue";

import Mapone from "../views/MapNavigation/indexMapone.vue";
import Maptwo from "../views/MapNavigation/indexMaptwo.vue";
import Mapthree from "../views/MapNavigation/indexMapthree.vue";
import Mapfour from "../views/MapNavigation/indexMapfour.vue";
import Mapfive from "../views/MapNavigation/indexMapfive.vue";
import Mapsix from "../views/MapNavigation/indexMapsix.vue";
import Mapseven from "../views/MapNavigation/indexMapseven.vue";
import Mapeight from "../views/MapNavigation/indexMapeight.vue";
import Mapnine from "../views/MapNavigation/indexMapnine.vue";
// import MonitorScreen from "../views/montitorScreen/home.vue";
import MonitorScreen from "../views/monitorScreen/home.vue";

import SubMenu from "../views/SubMenu.vue";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "index",
    redirect: "/map",
    component: Index,
    children: [
      {
        path: "/map",
        name: "map",
        component: MapNavigation,
        meta: { flexable: true },
      },
      {
        path: "/maptwo",
        name: "maptwo",
        component: Maptwo,
        meta: { flexable: true },
      },
      {
        path: "/Mapthree",
        name: "Mapthree",
        component: Mapthree,
        meta: { flexable: true },
      },
      {
        path: "/mapfour",
        name: "Mapfour",
        component: Mapfour,
        meta: { flexable: true },
      },
      {
        path: "/mapfive",
        name: "Mapfive",
        component: Mapfive,
        meta: { flexable: true },
      },
      {
        path: "/mapsix",
        name: "Mapsix",
        component: Mapsix,
        meta: { flexable: true },
      },
      {
        path: "/mapseven",
        name: "Mapseven",
        component: Mapseven,
        meta: { flexable: true },
      },
      {
        path: "/mapeight",
        name: "Mapeight",
        component: Mapeight,
        meta: { flexable: true },
      },
      {
        path: "/mapnine",
        name: "Mapnine",
        component: Mapnine,
        meta: { flexable: true },
      },
      {
        path: "/mapone",
        name: "mapone",
        component: Mapone,
        meta: { flexable: true },
      },
      {
        // 实施监管首页
        path: "/monitor/monitor/power",
        component: () => import("../views/Monitor/Menu/power.vue"),
      },
      {
        // 告警管理
        path: "/Alarm",
        component: () => import("../views/Alarm/AlarmManagement"),
      },
      {
        // 设置
        path: "/set/set",
        component: () => import("../views/set/set"),
      },
      {
        path: "/monitor",
        name: "monitor",
        redirect: "/monitor/monitor/power",
        component: () => import("../views/SubMenu.vue"),
        children: [
          {
            // 实施监管首页
            path: "/monitor/monitor/index",
            component: () => import("../views/Monitor/Menu/Index.vue"),
          },

          {
            // 厂站概括
            path: "/monitor/monitor/overView",
            component: () => import("../views/Monitor/Menu/overView.vue"),
          },
          {
            // 厂站详情
            path: "/monitor/monitor/detail",
            component: () => import("../views/Monitor/Menu/detail.vue"),
          },
          {
            // 厂站详情的详情页
            path: "/monitor/monitor/detailItem",
            component: () => import("../views/Monitor/Menu/detail-item.vue"),
          },
          {
            // 设备管理
            path: "/monitor/monitor/device",
            component: () => import("../views/Monitor/Menu/device.vue"),
          },
          {
            // 设备管理
            path: "/monitor/monitor/deviceItem",
            component: () => import("../views/Monitor/Menu/device-item.vue"),
          },
        ],
      },
      {
        path: "/report",
        name: "report",
        redirect: "/report/product/ele",
        component: () => import("../views/SubMenu.vue"),
        children: [
          {
            //电站月用电统计
            path: "/report/product/powerstationele",
            component: () =>
              import(
                "../views/report/powerstationEveryMonthPower/powerstationEveryMonthPower.vue"
              ),
          },
          {
            //设备月用电统计
            path: "/report/product/ele",
            component: () =>
              import(
                "../views/report/dataStickEveryMonthPower/DataStickEveryMonthPower.vue"
              ),
          },

          {
            //预测电量统计
            path: "/report/product/prediction",
            component: () =>
              import(
                "../views/report/powerStationMonthElectricityTotal/PowerStationMonthElectricityTotal.vue"
              ),
          },
          {
            //月度等效时统计
            path: "/report/product/time",
            component: () =>
              import(
                "../views/report/powerStationMonthEquivalentTime/PowerStationMonthEquivalentTime.vue"
              ),
          },
          {
            //运行指标对比
            path: "/report/power/balance",
            component: () =>
              import(
                "../views/report/powerStationRunTarget/PowerStationRunTarget.vue"
              ),
          },
          {
            ///运行指标统计
            path: "/report/power/total",
            component: () =>
              import(
                "../views/report/powerStationRunTarget/PowerStationRunTarget.vue"
              ),
          },
          {
            ///电能计量表日报
            path: "/report/power/statistics",
            component: () =>
              import(
                "../views/report/powerStationElectrityTotal/PowerStationElectrityTotal.vue"
              ),
          },
          {
            ///逆变器运行报表
            path: "/report/power/inverter",
            component: () =>
              import(
                "../views/report/powerStationDeviceNbqRun/PowerStationDeviceNbqRun.vue"
              ),
          },
          {
            ///箱变峰值功率
            path: "/report/rate/boXtran",
            component: () =>
              import(
                "../views/report/powerStationXiangBianTotal/PowerStationXiangBianTotal.vue"
              ),
          },
          {
            ///逆变器峰值功率
            path: "/report/rate/inverter",
            component: () =>
              import(
                "../views/report/powerStationNbqPowerTotal/PowerStationNbqPowerTotal.vue"
              ),
          },
          {
            ///支路峰值功率
            path: "/report/rate/pass",
            component: () =>
              import(
                "../views/report/powerStationNbqBranchPowerTotal/PowerStationNbqBranchPowerTotal.vue"
              ),
          },
          {
            ///环境监测仪数据
            path: "/report/device/tester",
            component: () =>
              import(
                "../views/report/powerStationEnvironmentalDetectorData/PowerStationEnvironmentalDetectorData.vue"
              ),
          },

          {
            ///逆变器数据
            path: "/report/device/combinerInverter",
            component: () => import("../views/report/dataStick/DataStick.vue"),
          },

          {
            ///集电线路数据
            path: "/report/device/line",
            component: () =>
              import(
                "../views/report/powerStationCircuitData/PowerStationCircuitData.vue"
              ),
          },
          {
            ///升压站数据
            path: "/report/device/booster",
            component: () =>
              import(
                "../views/report/powerStationBoosterStationData/PowerStationBoosterStationData.vue"
              ),
          },
          {
            ///电能计量表数据
            path: "/report/device/gauges",
            component: () =>
              import(
                "../views/report/powerStationElectrityData/PowerStationElectrityData.vue"
              ),
          },
        ],
      },
      {
        path: "/analysis/analysis/power",
        component: () => import("../views/analysis/power.vue"),
      },
      {
        path: "/analysis",
        name: "analysis",
        redirect: "/analysis/analysis/power",
        component: () => import("../views/SubMenu.vue"),
        children: [
          {
            //组串离散诊断
            path: "/analysis/analysis/discrete",
            component: () => import("../views/analysis/discrete/Discrete.vue"),
          },
          {
            //组串离散诊断
            path: "/analysis/analysis/produceElectricity",
            component: () =>
              import(
                "../views/analysis/produceElectricity/ProduceElectricity.vue"
              ),
          },
        ],
      },
      {
        path: "/property",
        name: "property",
        redirect: "/property/property/fac",
        component: () => import("../views/SubMenu.vue"),
        children: [
          {
            //厂站管理
            path: "/property/property/fac",
            component: () =>
              import("../views/property/powerStation/PowerStation.vue"),
          },
          {
            //采购入库
            path: "/property/store/procure",
            component: () =>
              import(
                "../views/property/materialIntoBillItem/MaterialIntoBillItem.vue"
              ),
          },
          {
            //领用出库
            path: "/property/store/Outbound",
            component: () =>
              import(
                "../views/property/materialOutBillItem/MaterialOutBillItem.vue"
              ),
          },
          {
            //库存明细查询
            path: "/property/store/inventory",
            component: () =>
              import("../views/property/materialStock/MaterialStock.vue"),
          },
          {
            //实时库存查询
            path: "/property/store/invNow",
            component: () => import("../views/property/material/Material.vue"),
          },
        ],
      },
      {
        path: "/system",
        name: "system",
        redirect: "/system/system/employee",
        component: () => import("../views/SubMenu.vue"),
        children: [
          {
            path: "/system/system/employee",
            component: () => import("../views/institution/employee/index.vue"),
          },
          {
            path: "/system/system/employeeDetails",
            component: () =>
              import("../views/institution/employee/EmployeeDetail.vue"),
          },
          {
            path: "/system/system/engineerDept",
            component: () =>
              import("../views/institution/engineerDept/index.vue"),
          },
          {
            path: "/system/system/engineerRole",
            component: () =>
              import("../views/institution/engineerRole/EngineeringRole.vue"),
          },
          {
            path: "/system/system/post",
            component: () => import("../views/institution/post/Post.vue"),
          },
          {
            path: "/system/system/menu",
            component: () => import("../views/institution/menu/Menu.vue"),
          },
          {
            path: "/system/system/systemUpdateRecord",
            component: () =>
              import(
                "../views/institution/engineeringSystemUpdateRecord/EngineeringSystemUpdateRecord.vue"
              ),
          },
        ],
      },
      {
        // 实施监管首页
        path: "/yunwei/yunwei/power",
        component: () => import("../views/yunwei/power.vue"),
      },
      {
        path: "/yunwei",
        name: "yunwei",
        redirect: "/yunwei/yunwei/index",
        component: () => import("../views/SubMenu.vue"),
        children: [
          {
            path: "/yunwei/yunwei/index",
            component: () => import("../views/yunwei/yunweiHome.vue"),
            showFather:true
          },
          {
            path: "/yunwei/yunwei/inspectionClass",
            component: () => import("../views/yunwei/inspectionClass.vue"),
            showFather:false
          },
          {
            path: "/yunwei/yunwei/inspectionRecord",
            component: () => import("../views/yunwei/inspectionRecord.vue"),
            showFather:false
          },
          {
            path: "/yunwei/yunwei/inspectionPlan",
            component: () => import("../views/yunwei/inspectionPlan.vue"),
            showFather:false
          },
          {
            path: "/yunwei/yunwei/workManage",
            component: () => import("../views/yunwei/workManage.vue"),
            showFather:false
          },
          {
            path: "/yunwei/yunwei/workOrderManage",
            component: () => import("../views/yunwei/workOrderManage.vue"),
            showFather:false
          },
          {
            path: "/yunwei/yunwei/alarmManage",
            component: () => import("../views/yunwei/alarmManage.vue"),
            showFather:false
          },
          {
            path: "/yunwei/yunwei/createWorkOrder",
            component: () => import("../views/yunwei/createWorkOrder.vue"),
            showFather:false
          },
          {
            path: "/yunwei/yunwei/addWorkOrder",
            component: () =>
              import("../views/yunwei/components/addWorkOrder.vue"),
            showFather:false
          },
          {
            path: "/yunwei/yunwei/inspectionClassList",
            component: () => import("../views/yunwei/inspectionClassList.vue"),
            showFather:false
          },
          {
            path: "/yunwei/yunwei/goWorkOrder",
            component: () => import("../views/yunwei/goWorkOrder/goWorlOrder.vue"),
            showFather:false
          }
        ],

      },

      {
        path: "/config",
        name: "config",
        redirect: "/config/config/powerStation",
        component: () => import("../views/SubMenu.vue"),
        children: [
          {
            //电站注册
            path: "/config/config/powerStation",
            component: () =>
              import("../views/configView/powerStation/PowerStation.vue"),
          },
          {
            //电站设备
            path: "/config/config/device",
            component: () =>
              import(
                "../views/configView/powerStationDeviceJoin/PowerStationDeviceJoin.vue"
              ),
          },
          {
            //设备点位
            path: "/config/config/devicepoint",
            component: () =>
              import(
                "../views/configView/powerStationDevicePoint/PowerStationDevicePoint.vue"
              ),
          },
          {
            //指标正常范围定义
            path: "/config/config/index",
            component: () =>
              import(
                "../views/configView/powerStationDefineRange/PowerStationDefineRange.vue"
              ),
          },
          {
            //组串逆变器支路配置
            path: "/config/config/inverter",
            component: () =>
              import(
                "@/views/configView/powerStationDeviceBranchConfig/PowerStationDeviceBranchConfig.vue"
              ),
          },
          {
            //工作票步骤
            path: "/config/config/workStep",
            component: () =>
              import("@/views/configView/workTicket/WorkTicket.vue"),
          },
          {
            //汇流箱通信状态
            path: "/config/config/combinerCom",
            component: () =>
              import(
                "@/views/configView/powerStationCombinerBoxStatus/PowerStationCombinerBoxStatus.vue"
              ),
          },
          {
            //短信发送功能
            path: "/config/config/send",
            component: () =>
              import(
                "@/views/configView/powerStationSendSms/PowerStationSendSms.vue"
              ),
          },
          {
            //故障分类
            path: "/config/config/fault",
            component: () =>
              import("@/views/configView/faultType/FaultType.vue"),
          },
          {
            //故障解决方案
            path: "/config/config/alarmSuggestion",
            component: () =>
              import("../views/configView/alarmSuggestion/alarmSuggestion.vue"),
          },
          {
            //设备产品定义
            path: "/config/config/devicedef",
            component: () =>
              import(
                "../views/configView/powerStationProduct/PowerStationProduct.vue"
              ),
          },
          {
            //设备类型定义
            path: "/config/config/deviceKind",
            component: () =>
              import("../views/configView/deviceType/DeviceType.vue"),
          },
          {
            //环境检测仪
            path: "/config/config/envidev",
            component: () =>
              import(
                "../views/configView/powerStationEnvironmentalDetector/PowerStationEnvironmentalDetector.vue"
              ),
            // component:() => import('../views/configView/powerStationElectricityMeter/PowerStationElectricityMeter.vue')
          },
          {
            // 电表管理
            path: "/config/config/meter",
            component: () =>
              import(
                "../views/configView/powerStationElectricityMeter/PowerStationElectricityMeter.vue"
              ),
          },
          {
            // sim卡管理
            path: "/config/config/sim",
            component: () =>
              import("../views/configView/powerStationSim/PowerStationSim.vue"),
          },
          {
            // sim卡管理
            path: "/config/config/brand",
            component: () => import("../views/configView/powerBrand/Brand.vue"),
          },
          {
            //告警管理
            path: "/config/config/alarmManage",
            component: () => import("../views/configView/alarmManage/alarmManage.vue"),
            showFather:false
          },
          // {
          //   // 品牌
          //   path: "/config/config/brand",
          //   component: () =>
          //     import("../views/configView/powerStationDeviceBranchConfig/PowerStationDeviceBranchConfig.vue"),
          // },
        ],
      },
      {
        path: "/monitorScreen",
        name: "monitorScreen",
        component: MonitorScreen,
        meta: { flexable: true },
      },
    ],
  },
  // 以下为系统生成路由部分
  // { path: "/", redirect: "/index" },
  { path: "/login", component: Login },
  {
    path: "/index",
    component: Index,
    // redirect: "/Welcome",
    redirect: "/map",
    children: [
      // { path: "/index", component: Welcome },
      { path: "/index", component: MapNavigation },
      { path: "/organization", component: OrganizationManagement },
      { path: "/post", component: Post },
      // { path: "/menu", component: Menu },
      { path: "/authority", component: Authority },
      { path: "/organizationType", component: OrganizationTypeMaintenance },
      { path: "/myAccountView", component: myAccountView },
      { path: "/roleMenu", component: RoleMenu },
      { path: "/systemConfig", component: SystemConfig },
      { path: "/systemConfig/details", component: SystemConfigDetails },
      { path: "/tableDesign", component: TableDesign },
      { path: "/coding", component: Table },
      { path: "/table/details", component: TableDetails },
      { path: "/tableDdl", component: TableDdl },
      { path: "/tableDdl/details", component: TableDdlDetails },
      { path: "/loginLog", component: LoginLog },
      { path: "/loginLog/details", component: LoginLogDetails },
      { path: "/fromDesigner", name: "designer", component: FromDesigner },
      { path: "/dialog", name: "dialogTest", component: DialogTest },
      {
        path: "/queryDialog",
        name: "queryDialogTest",
        component: QueryDialogTest,
      },
      { path: "/view", name: "view", component: View },
      { path: "/table", name: "table", component: DesignTable },
      // {
      //   path: "/yunwei/yunwei/inspectionClass",
      //   component: () => import("../views/yunwei/inspectionClass.vue"),
      // },
    ],
  },

];

const router = new VueRouter({
  mode:"hash",
  routes,
});

// 挂载路由导航守卫,to表示将要访问的路径，from表示从哪里来，next是下一个要做的操作 next('/login')强制跳转login
router.beforeEach((to, from, next) => {
  if (to.path === "/login") {
    next();
  } else {
    let token = ""; //token保存在localstorage中
    let item = localStorage.getItem("token");
    // 先将拿到的试着进行json转为对象的形式
    try {
      item = JSON.parse(item);
    } catch (error) {
      // eslint-disable-next-line no-self-assign
      item = item;
    }
    if (item && item.startTime) {
      let date = new Date().getTime();
      // 如果大于就是过期了，如果小于或等于就还没过期
      let timel = date - item.startTime;
      console.log("timelength:" + timel + ",expires:" + item.expires);
      if (timel < item.expires) {
        token = item.value;
      }
    }
    if (token === null || token === "") {
      // next("/login");
      next();
    } else {
      next();
    }
  }
});

export default router;
