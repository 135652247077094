<template>
  <div ref="pictorial_bar" class="pictorial-bar" :style="{width: width, height: height}"></div>
</template>

<script>
import props from "../../common/js/props";
import Util from "../../../utils/util.js";
export default {
  name: "PictorialBar",
  mixins: [ props ],
  data() {
    return {
      echart: null,
      op: {
        xAxis: {
          axisLine: {
            lineStyle: {
              color: this.axisLineColor || '#ccc'
            }
          },
          type: 'category'
        },
        yAxis: {
          splitLine: {
            show: false
          },
          axisLine: {
            lineStyle: {
              color: this.axisLineColor || '#ccc'
            }
          },
          type: 'value'
        },
        series: [{
          type: 'pictorialBar',
          symbol: this.iconPath, // 对 data 中所有数据项生效。
          data: this.data
        }]
      }
    }
  },
  props: {
    iconPath: {      //矢量图 数据
      type: String,
      required: true
    },
    svgColor: String,
    category: Array,
    data: Array,
    axisLineColor: String,
    yAxisName: String
  },
  created() {
    Util.merge(this.op, Util.deleteKeysOfValueUndefinedToNew({
      xAxis: {
        data: this.category
      },
      yAxis: {
        name: this.yAxisName
      },
      color: this.svgColor
    }), this.option);
  },
  mounted() {
    this.echart = this.$Echarts.init(this.$refs.pictorial_bar);
    this.echart.setOption(this.op);
  }
}
</script>

<style lang="less" scoped>
@import "./style.less";
</style>