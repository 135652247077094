<template>
  <div class="right-run" :style="{width: w+1, height: height}">
    <canvas ref="canvas" :width="w+1" :height="height"></canvas>
  </div>
</template>

<script>
import canvasProps from "../../common/js/canvas-props";
import Util from "../../../utils/util";
export default {
  name: "RightRun",
  mixins: [ canvasProps ],
  data() {
    return {
      cvs: null,
      w: parseInt(this.width),
      h: parseInt(this.height),
      s: this.size
    }
  },
  props: {
    count: {
      type: Number,
      default: 3
    },
    lineWidth: {
      type: Number,
      default: 3
    },
    size: Number, //单个的宽高（正方形）
    color: {
      type: String,
      default: "white"
    },
    duration: {
      type: Number,
      default: 1000
    },
    sleep: {
      type: Number,
      default: 2000
    }
  },
  created() {
    this.s = this.s || this.h;
  },
  mounted() {
    this.$$_init();
    this.$$_move();
  },
  methods: {
    $$_init() {
      this.cvs = this.$refs.canvas.getContext('2d');
      this.cvs.fillStyle = this.color;
    },
    $$_draw(x, y) {
      this.cvs.beginPath();
      this.cvs.moveTo(x, y);
      this.cvs.lineTo(x + this.lineWidth, y);
      this.cvs.lineTo(x + this.s, y + this.s / 2);
      this.cvs.lineTo(x + this.lineWidth, y + this.s);
      this.cvs.lineTo(x, y + this.s);
      this.cvs.lineTo(x + this.s - this.lineWidth, y + this.s / 2);
      this.cvs.fill();
    },
    async $$_move() {
      const w = this.w / this.count, d = this.duration / this.count;
      let [x, y] = [0, 0];

      this.$$_draw(x, y);
      await Util.sleep(d);
      for (let i = 1; i < this.count; i++) {
        x += w;
        this.$$_draw(x, y);
      await Util.sleep(d);
      }
      await Util.sleep(d);
      this.cvs.clearRect(0, 0, this.w + 2, this.h + 1);
      this.$$_move();
    }
  }
}
</script>

<style>

</style>