<template>
  <div class="mask">
    <div class="topContainer">
      <div class="top">
        <div class="topContent">
          <!-- <img src="@/assets/dianzhanselect.png" alt="" style="width: 32px;height: 32px;margin: 8px 12px 0;">
          <el-button type="primary" style="height: 30px;margin: 8px 15px 0 0;">分布式</el-button> -->
          <div class="select-title">电站名称</div>
          <div class="select">
            <el-input v-model="form.powerStationName"></el-input>
          </div>
          <div class="select-title">公司名称</div>
          <div class="select">
            <el-input v-model="form.deptName"></el-input>
          </div>
          <!-- <div class="select-title">年月</div>
          <div class="select"><el-input></el-input></div> -->
          <el-button
            type="primary"
            style="height: 30px; margin: 8px 0px 0 5px"
            @click="resert"
            >重置</el-button
          >
          <el-button
            type="primary"
            style="height: 30px; margin: 8px 40px 0 10px"
            @click="search"
            >搜索</el-button
          >
        </div>
      </div>
    </div>
    <div
      style="
        display: flex;
        width: calc(100vw - 80px);
        flex-wrap: wrap;
        justify-content: space-between;
        margin: 10px 40px 0 40px;
        height: calc(100vh - 140px);
        overflow: auto;
      "
    >
      <div
        class="content"
        v-for="(item, index) in powerStationList"
        :key="index"
        @click="displayMask"
      >
        <div class="item">
          <div class="itemTop">
            <div class="itemName">{{ item.powerStationName }}</div>
            <div class="itemUnit">
              <span>{{ item.volume }}</span
              ><span>kwp</span>
            </div>
          </div>
          <div class="itemContent">
            <div class="itemContent_left">
              <div class="itemContent_left-item">
                <div>
                  <span style="color: #2acfa9; font-size: 20px">{{
                    item.monthPowerNum
                  }}</span>
                  <span style="color: #d7d7d7; font-size: 17px">万kwh</span>
                </div>
                <div style="margin-top: 16px">
                  <span style="color: #d7d7d7; font-size: 15px">月发电量</span>
                </div>
              </div>
              <div class="itemContent_left-item" style="margin-top: 30px">
                <div>
                  <span style="color: #2acfa9; font-size: 20px">{{
                    item.equivalentHours
                  }}</span>
                  <span style="color: #d7d7d7; font-size: 17px">万kwh</span>
                </div>
                <div style="margin-top: 16px">
                  <span style="color: #d7d7d7; font-size: 15px"
                    >月日均等时效</span
                  >
                </div>
              </div>
            </div>
            <div class="itemContent_right">
              <el-progress
                type="circle"
                :percentage="item.volume"
              ></el-progress>
              <div class="itemContent_right_title">消缺率</div>
              <div class="itemContent_right_content">
                待处理缺陷
                <span style="color: #d9001b; font-size: 24px; font-weight: 600"
                  >0</span
                >
                个
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      powerStationList: [],
      form: {
        powerStationName: "",
        deptName: "",
      },
    };
  },
  mounted() {
    this.getPowerStation();
  },
  methods: {
    async getPowerStation() {
      const res = await this.$http.post("/RealMonitoring/PCSix", this.form);
      this.powerStationList = res.data.data;
    },
    search() {
      this.getPowerStation();
    },
    resert() {
      this.form = {
        powerStationName: "",
        deptName: "",
      };
    },
    displayMask(){
      this.$emit('displayMask')
    }
  },
};
</script>

<style lang="scss" scoped>
.mask {
  height: calc(100vh);
  width: calc(100vw);
  position: fixed;

  z-index: 11111111;
  background: #223f6c;
  .topContainer {
    position: relative;
    height: 55px;
    display: flex;
    justify-content: flex-end;
    .top {
      margin-top: 10px;
      .topContent {
        display: flex;
        flex-wrap: wrap;
        line-height: 40px;
        font-size: 16px;

        color: #97b3cb;
        .select-title {
          padding-top: 2px;
        }
      }
    }
  }
  .content {
    margin-top: 30px;

    .item {
      width: 500px;
      .itemTop {
        height: 50px;
        line-height: 50px;
        font-size: 20px;
        font-weight: 600;
        padding: 0 10px;
        background: #00b2e6;
        color: #d7d7d7;
        display: flex;
        justify-content: space-between;
      }
      .itemContent {
        height: 240px;
        padding: 20px 20px;
        background: #14304c;
        display: flex;
        .itemContent_left {
          margin-left: 50px;
          margin-right: 140px;
        }
        .itemContent_right {
          position: relative;
          margin-top: 10px;
          .itemContent_right_title {
            position: absolute;
            top: 70px;
            left: 50%;
            color: #d7d7d7;
            font-size: 16px;
            transform: translateX(-50%);
          }
          .itemContent_right_content {
            position: absolute;
            width: 100%;
            left: 50%;
            bottom: 20px;
            color: #d7d7d7;
            font-size: 16px;
            transform: translateX(-50%);
          }
        }
      }
    }
  }
}
/deep/ .el-input__inner {
  height: 28px;
  width: 120px !important;
}
.select {
  margin: 0 8px;
}
/deep/ .el-progress__text {
  margin-top: -20px;
  font-size: 16px;
  color: rgb(42, 207, 169);
}
</style>