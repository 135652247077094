<template>
  <div class="legend">
    <div class="legend--header" v-if="title" v-text="title" :style="{fontSize: fontSize}"></div>
    <div class="legend--li" v-for="(li, i) in list" :key="i" :style="{fontSize: fontSize}">
      <div class="legend--li__icon" v-if="li.icon">
        <icon :i="`sx-${li.icon.i}`" :color="li.icon.color"></icon>
      </div>
      <div class="legend--li__name" v-text="li.name.text" :style="{color: li.name.color}"></div>
      <div class="legend--li__value" v-text="v(li.value.v)" :style="{color: li.value.color}"></div>
      <div class="legend--li__solt"></div>
    </div>
  </div>
</template>

<script>
import legendProps from "../../common/js/legend-props";
import Icon from "../../icon";
export default {
  name: "XLegend",
  components: { Icon },
  mixins: [ legendProps ],
  data() {
    return {
      sum: 0
    }
  },
  props: {
    list: {
      type: Array,
      required: true
    }
  },
  watch: {
    percentage: {
      handler(v) {
        if (v) {
          this.list.forEach(item => {
            this.sum += parseFloat(item.value.v);
          })
        }
      },
      immediate: true
    }
  },
  computed: {
    v() {
      return v => {
        return `${this.percentage ? (parseFloat(v) / this.sum * 100).toFixed(2) + '%' : v}${this.unit ? this.unit : ''}`;
      }
    }
  }
}
</script>

<style lang="less">
@import "./style.less";
</style>