export const transDate = (date, formatStr) => {
    date = date || new Date()
    formatStr = formatStr || 'yyyy-MM-dd HH:mm'
  
    let zeroize = (value, length) => {
      if (!length) {
        length = 2
      }
      value += ''
      let zeros = ''
      for (let i = 0; i < length - value.length; i++) {
        zeros += '0'
      }
      return zeros + value
    }
  
    return formatStr.replace(
      /"[^"]*"|'[^']*'|\b(?:d{1,4}|M{1,4}|yy(?:yy)?|([hHmstT])\1?|[lLZ])\b/g,
      $0 => {
        switch ($0) {
          case 'd':
            return date.getDate()
          case 'dd':
            return zeroize(date.getDate())
          case 'ddd':
            return ['Sun', 'Mon', 'Tue', 'Wed', 'Thr', 'Fri', 'Sat'][
              date.getDay()
            ]
          case 'dddd':
            return [
              'Sunday',
              'Monday',
              'Tuesday',
              'Wednesday',
              'Thursday',
              'Friday',
              'Saturday'
            ][date.getDay()]
          case 'M':
            return date.getMonth() + 1
          case 'MM':
            return zeroize(date.getMonth() + 1)
          case 'MMM':
            return [
              'Jan',
              'Feb',
              'Mar',
              'Apr',
              'May',
              'Jun',
              'Jul',
              'Aug',
              'Sep',
              'Oct',
              'Nov',
              'Dec'
            ][date.getMonth()]
          case 'MMMM':
            return [
              'January',
              'February',
              'March',
              'April',
              'May',
              'June',
              'July',
              'August',
              'September',
              'October',
              'November',
              'December'
            ][date.getMonth()]
          case 'yy':
            return date.getFullYear().toString().substr(2)
          case 'yyyy':
            return date.getFullYear()
          case 'h':
            return date.getHours() % 12 || 12
          case 'hh':
            return zeroize(date.getHours() % 12 || 12)
          case 'H':
            return date.getHours()
          case 'HH':
            return zeroize(date.getHours())
          case 'm':
            return date.getMinutes()
          case 'mm':
            return zeroize(date.getMinutes())
          case 's':
            return date.getSeconds()
          case 'ss':
            return zeroize(date.getSeconds())
          case 'l':
            return date.getMilliseconds()
          case 'll':
            return zeroize(date.getMilliseconds())
          case 'tt':
            return date.getHours() < 12 ? 'am' : 'pm'
          case 'TT':
            return date.getHours() < 12 ? 'AM' : 'PM'
          default:
            return ''
        }
      }
    )
}
// 字体大小自适应
export const nowSize = (val, initWidth = 1920) => {
  let nowClientWidth = document.documentElement.clientWidth
  return val * (nowClientWidth / initWidth)
}