<template>
  <div class="echarts" id="ranking-board">
    <div id="echartDom" class="echarts-main"></div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import { nowSize } from "@/utils/common";
export default {
  data() {
    return {};
  },
  created(){
    this.initEcharts();
  },
  methods: {
    // 饼图
    initEcharts() {
      this.echartDom = echarts.init(document.getElementById("echartDom"));
      let option = {
        title: {
          text: "第三采油厂",
          subtext: "总数: 599",
          textStyle: {
            color: "#fff",
            fontSize: 60,
          },
          subtextStyle: {
            color: "#999",
            fontSize: 34,
          },
          x: "center",
          top: "0%",
        },
        grid: {
          top: 0,
          bottom: 0,
        },
        tooltip: {},
        xAxis: {
          data: ["关井数", "开井数", "不在线"],
          axisTick: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
        },
        yAxis: {
          splitLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
        },
        series: [
          {
            name: "",
            type: "pictorialBar",
            symbolSize: [100, 45],
            symbolOffset: [0, -14],
            z: 12,
            data: [
              {
                name: "关井数",
                value: "981",
                trueVal: "98",
                symbolPosition: "end",
                itemStyle: {
                  normal: {
                    color: "#00fff5",
                  },
                },
              },
              {
                name: "开井数",
                value: "491",
                trueVal: "49",
                symbolPosition: "end",
                itemStyle: {
                  normal: {
                    color: "#ffcc00",
                  },
                },
              },
              {
                name: "不在线",
                value: "21",
                trueVal: "2",
                symbolPosition: "end",
                itemStyle: {
                  normal: {
                    color: "#b9b7ff",
                  },
                },
              },
            ],
          },
          {
            name: "",
            type: "pictorialBar",
            symbolSize: [100, 45],
            symbolOffset: [0, 24],
            z: 12,
            data: [
              {
                name: "关井数",
                value: "981",
                trueVal: "98",
                itemStyle: {
                  normal: {
                    color: "#43bafe",
                  },
                },
              },
              {
                name: "开井数",
                value: "4991",
                trueVal: "499",
                itemStyle: {
                  normal: {
                    color: "#ff7800",
                  },
                },
              },
              {
                name: "不在线",
                value: "21",
                trueVal: "2",
                itemStyle: {
                  normal: {
                    color: "#e9a5ff",
                  },
                },
              },
            ],
          },
          {
            name: "",
            type: "pictorialBar",
            symbolSize: [150, 75],
            symbolOffset: [0, 44],
            z: 11,
            data: [
              {
                name: "关井数",
                value: "981",
                trueVal: "98",
                itemStyle: {
                  normal: {
                    color: "transparent",
                    borderColor: "#43bafe",
                    borderWidth: 5,
                  },
                },
              },
              {
                name: "开井数",
                value: "491",
                trueVal: "49",
                itemStyle: {
                  normal: {
                    color: "transparent",
                    borderColor: "#ff7800",
                    borderWidth: 5,
                  },
                },
              },
              {
                name: "不在线",
                value: "21",
                trueVal: "2",
                itemStyle: {
                  normal: {
                    color: "transparent",
                    borderColor: "#e9a5ff",
                    borderWidth: 5,
                  },
                },
              },
            ],
          },
          {
            name: "",
            type: "pictorialBar",
            symbolSize: [200, 100],
            symbolOffset: [0, 62],
            z: 10,
            data: [
              {
                name: "关井数",
                value: "981",
                trueVal: "98",
                itemStyle: {
                  normal: {
                    color: "transparent",
                    borderColor: "#43bafe",
                    borderType: "dashed",
                    borderWidth: 5,
                  },
                },
              },
              {
                name: "开井数",
                value: "491",
                trueVal: "49",
                itemStyle: {
                  normal: {
                    color: "transparent",
                    borderColor: "#ff7800",
                    borderType: "dashed",
                    borderWidth: 5,
                  },
                },
              },
              {
                name: "不在线",
                value: "21",
                trueVal: "2",
                itemStyle: {
                  normal: {
                    color: "transparent",
                    borderColor: "#e9a5ff",
                    borderType: "dashed",
                    borderWidth: 5,
                  },
                },
              },
            ],
          },
          {
            type: "bar",
            silent: true,
            barWidth: 100,
            barGap: "-100%",
            label: {
              normal: {
                show: true,
                position: "top",
                distance: 40,
                textStyle: {
                  color: "#fff",
                  fontSize: 40,
                },
              },
            },
            data: [
              {
                name: "关井数",
                value: "981",
                trueVal: "98",
                itemStyle: {
                  normal: {
                    color: {
                      x: 0,
                      y: 0,
                      x2: 0,
                      y2: 1,
                      type: "linear",
                      global: false,
                      colorStops: [
                        {
                          offset: 0,
                          color: "rgba(0,255,245,0.5)",
                        },
                        {
                          offset: 1,
                          color: "#43bafe",
                        },
                      ],
                    },
                  },
                },
              },
              {
                name: "开井数",
                value: "491",
                trueVal: "49",
                itemStyle: {
                  normal: {
                    color: {
                      x: 0,
                      y: 0,
                      x2: 0,
                      y2: 1,
                      type: "linear",
                      global: false,
                      colorStops: [
                        {
                          offset: 0,
                          color: "rgba(255,204,0,0.5)",
                        },
                        {
                          offset: 1,
                          color: "#ff7800",
                        },
                      ],
                    },
                  },
                },
              },
              {
                name: "不在线",
                value: "21",
                trueVal: "2",
                itemStyle: {
                  normal: {
                    color: {
                      x: 0,
                      y: 0,
                      x2: 0,
                      y2: 1,
                      type: "linear",
                      global: false,
                      colorStops: [
                        {
                          offset: 0,
                          color: "rgba(185,183,255,0.5)",
                        },
                        {
                          offset: 1,
                          color: "#e9a5ff",
                        },
                      ],
                    },
                  },
                },
              },
            ],
          },
        ],
      };
      this.echartDom.setOption(option);
      window.onresize = () => {
        if (this.echartDom) {
          this.echartDom.resize(); // 调用 ECharts 实例的 resize 方法
        }
      };
    },
    init(data) {
      this.top5plus = data;
      this.initEcharts();
    },
  },
};
</script>

<style lang="scss" scoped>
.echarts {
  display: flex;
  align-items: center;
  .echarts-main {
    width: 100%;
    height: 200px;
  }
  .echarts-desc {
    margin-top: 16px;
    flex: 1;
    .item {
      position: relative;
      display: flex;
      margin-bottom: 6px;
      justify-content: space-between;
      align-items: center;
      &::before {
        content: "";
        left: -24px;
        display: block;
        position: absolute;
        width: 12.8px;
        top: 4px;
        height: 13px;
        background: #026795;
        border-radius: 50%;
      }
      &:nth-child(1)::before {
        background-color: #1fb5fc;
      }
      &:nth-child(2)::before {
        background-color: #69edd1;
      }
      &:nth-child(3)::before {
        background-color: #f9d071;
      }
      &:nth-child(4)::before {
        background-color: #f36dc7;
      }
      &:nth-child(5)::before {
        background-color: #8400ff;
      }

      .left {
        color: #fff;
        font-size: 14px;
        width: 200px;

        // // 省略号css
        // overflow: hidden;
        // text-overflow: ellipsis;
        // white-space: nowrap;
      }
      .right {
        font-size: 14px;
      }
      &:nth-child(1) {
        color: #1fb5fc;
      }
      &:nth-child(2) {
        color: #69edd1;
      }
      &:nth-child(3) {
        color: #f9d071;
      }
      &:nth-child(4) {
        color: #f36dc7;
      }
      &:nth-child(5) {
        color: #8400ff;
      }
    }
  }
}
.dv-scroll-ranking-board {
  .ranking-info {
    font-size: 12px !important;
  }
}
.ranking-board-title {
  font-weight: bold;
  height: 50px;
  display: flex;
  align-items: center;
  font-size: 20px;
}
</style>
