<template>
  <div class="containerEight">
    <!-- <div class="eh" ref="globeContainer">
        </div> -->
    <div class="box">
      <div class="center-top">
        <div class="onei">
          <div class="gl-one">
            <div class="on-left">今日收益</div>
            <div class="on-right">
              <div class="num">
                <div class="num-l" v-for="(item, i) in incomelist" :key="i">
                  {{ item }}
                </div>
              </div>
              <div style="color: #fff; font-size: 18px">万元</div>
            </div>
          </div>
          <div class="gl-one">
            <div class="on-left">当日发电量</div>
            <div class="on-right">
              <div class="num">
                <div class="num-2" v-for="(item, i) in numtwo" :key="i">
                  {{ item }}
                </div>
              </div>
              <div style="color: #fff; font-size: 18px">万kWh</div>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom-box">
        <!-- 电站信息 -->
        <div class="dz-info">
          <dv-border-box-10 style="width: 100%; height: 100%">
            <div class="info-box">
              <div class="info-name">电站信息</div>
              <div class="info-o">
                <div>
                  <img src="../../assets/mapNav/state.png" alt="" />
                  <span class="info-num">电站数量</span>
                </div>
                <div class="info-n">
                  <span>{{ modedata.counts ? modedata.counts : 0 }}</span> 座
                </div>
              </div>
              <div class="info-o">
                <div>
                  <img src="../../assets/mapNav/contain.png" alt="" />
                  <span class="info-num">装机容量</span>
                </div>
                <div class="info-n">
                  <span>{{ modedata.valuen ? modedata.valuen : 0 }}</span> MW
                </div>
              </div>
              <div class="info-o">
                <div>
                  <img src="../../assets/mapNav/power.png" alt="" />
                  <span class="info-num">实时功率</span>
                </div>
                <div class="info-n">
                  <span>{{
                    modedata.currEfficiency ? modedata.currEfficiency : 0
                  }}</span>
                  kWh
                </div>
              </div>
            </div>
          </dv-border-box-10>
        </div>
        <!-- 月年发电量 -->
        <div class="box-center">
          <dv-border-box-10 style="width: 100%; height: 100%">
            <div class="ok">
              <div class="ok-left">
                <div class="ok-one">
                  <div class="ok-num">
                    <span style="color: #4ba6e7">{{
                      modedata.monthPowerNum ? modedata.monthPowerNum : 0
                    }}</span
                    >万kWh
                  </div>
                  <div class="ok-name">月发电量</div>
                </div>
                <div class="month-finish">
                  <el-progress width="100" type="circle" :percentage="98.36" />
                </div>
              </div>
              <div class="ok-left">
                <div class="ok-one">
                  <div class="ok-num">
                    <span style="color: #f5bc61">{{
                      modedata.totalPowerNum ? modedata.totalPowerNum : 0
                    }}</span
                    >万kWh
                  </div>
                  <div class="ok-name">年发电量</div>
                </div>
                <div class="year-finish">
                  <el-progress
                    width="100"
                    type="circle"
                    color="#f5bc61"
                    :percentage="98.36"
                  />
                </div>
              </div>
            </div>
          </dv-border-box-10>
        </div>
        <!-- 日月均等有效时 -->
        <div class="right-one">
          <dv-border-box-10 style="width: 100%; height: 100%">
            <div class="chart-box">
              <div class="info-name">月有效发电时长</div>
              <div class="flex">
                <div style="height: 150px; flex: 2" id="myChartthree"></div>
                <div class="ch-rig">
                  <div class="ch-num"><span>2.6</span>h</div>
                  <div class="ch-na">电站可利用率平均值</div>
                </div>
              </div>
            </div>
          </dv-border-box-10>
        </div>
      </div>
      <div class="bottom-box" style="margin-top: 10px">
        <!-- 电站可利用率对比 -->
        <div class="rate">
          <dv-border-box-10 style="width: 100%; height: 100%">
            <div class="info-name">电站可利用率对比</div>
            <div class="rate-box">
              <div class="b-le">
                <div class="rate-t">
                  <div class="rate-i">星级</div>
                  <div class="rate-n">电站名称</div>
                  <div class="rate-r">可利用率</div>
                </div>
                <div class="rate-t">
                  <div class="rate-i">
                    <img src="../../assets/star-1.png" alt="" />
                  </div>
                  <div class="rate-n">演示电站1</div>
                  <div class="rate-r"><span>78</span>%</div>
                </div>
                <div class="rate-t">
                  <div class="rate-i">
                    <img src="../../assets/star-2.png" alt="" />
                  </div>
                  <div class="rate-n">演示电站2</div>
                  <div class="rate-r"><span>35</span>%</div>
                </div>
                <div class="rate-t">
                  <div class="rate-i">
                    <img src="../../assets/star-3.png" alt="" />
                  </div>
                  <div class="rate-n">演示电站</div>
                  <div class="rate-r"><span>38</span>%</div>
                </div>
                <div class="rate-t">
                  <div class="rate-i">4</div>
                  <div class="rate-n">演示电站</div>
                  <div class="rate-r"><span>29</span>%</div>
                </div>
              </div>
              <div class="b-ri">
                <div class="bi"><span>68</span>%</div>
                <div class="ri">电站可利用率平均值</div>
              </div>
            </div>
          </dv-border-box-10>
        </div>
        <div class="chart-one">
          <dv-border-box-10 style="width: 100%; height: 100%">
            <div class="info-name">月发电量(度)</div>
            <div class="chart-box">
              <monthEcharts></monthEcharts>
              <!-- <div style="height: 170px; width: 100%" id="myChart"></div> -->
            </div>
          </dv-border-box-10>
        </div>
        <div class="right-one">
          <dv-border-box-10 style="width: 100%; height: 100%">
            <div class="chart-box">
              <div class="info-name">月有效发电时长</div>
              <div class="flex">
                <div style="height: 180px; flex: 2" id="myChartfour"></div>
                <div class="ch-rig" style="flex: 1">
                  <div class="ch-num"><span>30</span>h</div>
                  <div class="ch-na">月综合评分平均值</div>
                </div>
              </div>
            </div>
          </dv-border-box-10>
        </div>
      </div>
      <div class="bottom-box" style="margin-top: 10px">
        <!-- 电站信息 -->
        <div class="dz-info">
          <dv-border-box-10 style="width: 100%; height: 100%">
            <div class="info-name">节能减排</div>
            <div class="info-box">
              <div class="info-o">
                <img src="../../assets/info-one.png" alt="" />
                <div class="info-num">
                  年累计节约标准煤
                </div>
                <div class="info-n">
                  <span style="color: #d9001b">{{
                    co.yearCO2Reduction ? co.yearCO2Reduction : 0
                  }}</span>
                  t
                </div>
              </div>
              <div class="info-o">
                <img src="../../assets/info-two.png" alt="" />
                <div class="info-num">
                  年累计减排CO2
                </div>
                <div class="info-n">
                  <span style="color: #d9001b">{{
                    co.yearCoalSaving ? co.yearCoalSaving : 0
                  }}</span>
                  t
                </div>
              </div>
            </div>
          </dv-border-box-10>
        </div>

        <div class="chart-one">
          <dv-border-box-10 style="width: 100%; height: 100%">
            <div class="info-name">年发电量(度)</div>
            <div class="chart-box" style="width: 100%">
              <yearEcharts></yearEcharts>
              <!-- <div style="height: 150px; width: 100%" id="myCharttwo"></div> -->
            </div>
          </dv-border-box-10>
        </div>

        <div class="right-one">
          <dv-border-box-10 style="width: 100%; height: 100%">
            <div class="info-name">月均等有效时对比</div>
            <div class="chart-box" style="display: flex">
              <div style="flex: 4">
                <div class="echarts">
                  <top5Score ref="top5Score" class="echarts-main"></top5Score>
                  <!-- <div ref="echartDom" class="echarts-main" style="width:200px;height:200px"></div> -->
                  <!-- <div class="echarts-desc">
                <div class="item" v-for="(e, i) in toplist" :key="i">
                  <div class="left">{{ e.powerStationName }}</div>
                  <div class="right">{{ e.equivalentHours }}分</div>
                </div>
              </div> -->
                </div>
              </div>
              <div class="ch-rig">
                <div class="ch-num"><span>60</span>分</div>
                <div class="ch-na">综合厂用电平均值</div>
              </div>
            </div>
          </dv-border-box-10>
        </div>
      </div>
    </div>
    <mapDialog></mapDialog>
     <timeVue class="times"></timeVue>
  </div>
</template>

  <script>
import * as echarts from "echarts";
import mapDialog from "./components/mapDialog.vue";
import EchartsVue from "@/components/echarts";
import { optionMonth, optionYear } from "./echarts/index2";
import top5Score from "./components/top5Score.vue";
import monthEcharts from "./components/monthEcharts.vue";
import yearEcharts from "./components/yearEcharts.vue";
import timeVue from "./components/time.vue";
export default {
  components: { mapDialog, EchartsVue, top5Score ,monthEcharts,yearEcharts ,timeVue},
  data() {
    return {
      renderer: null,
      camera: null,
      myChart: null,
      modedata: {},
      // toplist: [],
      toplist: [
        {
          powerStationName: "演示电站1",
          color: "#1fb5fc",
          equivalentHours: 50,
        },
        {
          powerStationName: "演示电站2",
          color: "#69edd1",
          equivalentHours: 60,
        },
        {
          powerStationName: "演示电站3",
          color: "#f9d071",
          equivalentHours: 80,
        },
        {
          powerStationName: "演示电站4",
          color: "#fe6dc7",
          equivalentHours: 40,
        },
        {
          powerStationName: "演示电站5",
          color: "#8400ff",
          equivalentHours: 90,
        },
      ],
      co: "",
      top5plus: [],
      year: "",
      month: [],
      numone: [0, 0, 0, 0, ".", 0, 0],
      numtwo: [0, 0, 0, 0, ".", 0, 0],
      nj: "",
      yj: "",
      income: "",
      incomelist: [0, 0, 0, 0, ".", 0, 0],
      optionYear: {},
    };
  },
  mounted() {
    this.initMOnthElectricitythree();
    this.initMOnthElectricityfour();
  },
  created() {
    this.optionYear = optionYear;
    this.getData();
    this.getco();
    this.getyear();
    this.getmonth();
    this.getnj();
    this.$nextTick(() => {
      this.initECharts();
    });
  },
  beforeDestroy() {},
  methods: {
    initECharts() {
      let echartDom = this.$refs.echartDom;
      var myChart = echarts.init(echartDom);
      const circleOptions = {
        series: [
          {
            labelLine: {
              //引导线设置
              show: false, //引导线不显示
            },
            type: "pie",
            data: [
              {
                value: 80,
                name: "A",
                itemStyle: { color: "#1FB5FC" },
              },
              {
                value: 20,
                name: "B",
                itemStyle: { color: "transparent" },
              },
            ],
            radius: ["80%", "90%"],
          },
          {
            labelLine: {
              //引导线设置
              show: false, //引导线不显示
            },
            type: "pie",
            data: [
              {
                value: 80,
                name: "A",
                itemStyle: { color: "#69EDD1" },
              },
              {
                value: 20,
                name: "B",
                itemStyle: { color: "transparent" },
              },
            ],
            radius: ["60%", "70%"],
          },
          {
            labelLine: {
              //引导线设置
              show: false, //引导线不显示
            },
            type: "pie",
            data: [
              {
                value: 80,
                name: "A",
                itemStyle: { color: "#F9D071" },
              },
              {
                value: 20,
                name: "B",
                itemStyle: { color: "transparent" },
              },
            ],
            radius: ["40%", "50%"],
          },
          {
            labelLine: {
              //引导线设置
              show: false, //引导线不显示
            },
            type: "pie",
            data: [
              {
                value: 80,
                name: "A",
                itemStyle: { color: "#FE6DC7" },
              },
              {
                value: 20,
                name: "B",
                itemStyle: { color: "transparent" },
              },
            ],
            radius: ["20%", "30%"],
          },
          {
            labelLine: {
              //引导线设置
              show: false, //引导线不显示
            },
            type: "pie",
            data: [
              {
                value: 80,
                name: "A",
                itemStyle: { color: "#8400FF" },
              },
              {
                value: 20,
                name: "B",
                itemStyle: { color: "transparent" },
              },
            ],
            radius: ["10%", "20%"],
          },
        ],
      };
      myChart.setOption(circleOptions);
    },
    initMOnthElectricitythree() {
      setTimeout(() => {
        this.myChart = echarts.init(document.getElementById("myChartthree"));
        let option = {
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "shadow",
            },
          },
          legend: {},
          grid: {
            left: "3%",
            right: "4%",
            bottom: "8%",
            top: "5%",
            containLabel: true,
          },
          xAxis: {
            type: "value",
            boundaryGap: [0, 0.01],
            axisLabel: {
              show: true,
              color: "#d7d7d7",
              lineStyle: {
                color: "#dcdcdc",
                width: 1,
                type: "solid",
              },
            },
          },
          yAxis: {
            type: "category",
            data: ["演示电站", "演示电站", "演示电站"],
            axisLabel: {
              show: true,
              color: "#d7d7d7",
              lineStyle: {
                color: "#dcdcdc",
                width: 1,
                type: "solid",
              },
            },
          },
          series: [
            {
              type: "bar",
              data: [7, 2, 4],
              barWidth: "7px",
            },
          ],
        };
        this.myChart.setOption(option);
      }, 200);
    },
    initMOnthElectricityfour() {
      setTimeout(() => {
        // var list = this.month.map((item) => {
        //   return item.dailyPowerGeneration;
        // });
        // var lists = list.slice(0, 12);
        var lists = [2.6, 2.6, 2.6, 3, 1, 4, 5, 2, 2, 10, 11, 12];

        this.myChart = echarts.init(document.getElementById("myChartfour"));
        // let option = this.optionMonth
        let option = {
          grid: {
            left: "3%",
            right: "1%",
            bottom: "2%",
            top: "5%",
            containLabel: true,
          }, //,
          tooltip: {
            trigger: "axis",
            axisPointer: {
              // 坐标轴指示器，坐标轴触发有效
              type: "shadow", // 默认为直线，可选为：'line' | 'shadow' 45f9d6
            },
          },
          xAxis: {
            type: "category",
            show: true,
            data: [
              "1",
              "2",
              "3",
              "4",
              "5",
              "6",
              "7",
              "8",
              "9",
              "10",
              "11",
              "12",
            ],
            axisTick: {
              show: false,
            },
            axisLabel: {
              show: true,
              color: "#d7d7d7",
              lineStyle: {
                color: "#31445A",
                width: 1,
                type: "solid",
              },
            },
          },
          yAxis: {
            splitLine: {
              //设置坐标轴内的背景网格线
              show: true, //显示背景网格线，默认为false
              lineStyle: {
                //设置背景网格线的样式
                color: "#4F5770", //设置线的颜色
              },
            },
            type: "value",
            axisLine: {
              show: true,
              colorL: "#31445A",
            },
            axisTick: {
              show: true,
            },
            axisLabel: {
              show: true,
              color: "#d7d7d7",
              lineStyle: {
                color: "#31445A",
                width: 1,
                type: "solid",
              },
            },
          },

          series: [
            {
              data: lists,
              type: "bar",
              itemStyle: {
                color: "#f89000",
              },
              barWidth: "7px", //设置柱状图宽度
            },
          ],
        };
        this.myChart.setOption(option);
      }, 200);
    },
    async getData() {
      const res = await this.$http.post("PcRealMonitoring/PCPositionList1", {});
      if (res.data.code === 200) {
        this.numone = Number(res.data.data[0][0].currEfficiency)
          .toFixed(0)
          .toString()
          .split("");
        if (this.numone.length <= 7) {
          for (let i = 0; i <= 9 - this.numone.length; i++) {
            this.numone.unshift(0);
          }
        }
        this.numtwo = Number(res.data.data[0][0].currDayPowerNum)
          .toFixed(0)
          .toString()
          .split("");
        if (this.numtwo.length <= 7) {
          for (let i = 0; i <= 9 - this.numtwo.length; i++) {
            this.numtwo.unshift(0);
          }
        }
        // 电站建设发电量
        this.modedata = res.data.data[0][0];
        console.log(this.modedata, 502);
        // // 电站建设完成度
        this.completeness = res.data.data[3][0];
        const colorList = [
          "#f9d071",
          "#fe6dc7",
          "#8400ff",
          "#1fb5fc",
          "#69edd1",
        ];
        res.data.data[1].forEach((item, index) => {
          item.color = colorList[index];
        });

        this.top5 = res.data.data[1];
        this.top5plus = res.data.data[2];
        this.$refs.top5Score.init(this.top5plus);
        // // 二氧化碳
        this.co = res.data.data[4][0];
        this.count = res.data.data[7][0];
      } else {
        this.$message.error(res.data);
      }
    },
    formatProgress(item) {
      return () => {
        return `${item}h`;
      };
    },
  },
};
</script>

  <style lang="less">
// .el-main {
//   height: calc(100vh - 85px);
//   background: #0a1834;
// }
.containerEight {
   background: linear-gradient(to bottom, #051525, #0e2944ed);
  .info-name {
    color: #00b2e6;
    font-size: 20px;
    margin-bottom: 10px;
    padding-top: 20px;
    padding-left: 10px;
  }
  .box {
    height: 100%;
    width: 100%;
    // background-image: url("../../assets/mapImg/bg2.jpg");
    background-size: cover;
    background-repeat: round;
    opacity: 1;
    padding: 0 20px 0 20px;
    > div.bottom-box {
      display: flex;
      .dz-info {
        flex: 2;
        .info-box {
          // height: 230px;
          background: rgba(0, 0, 0, 0.2);
          .info-o {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 20px;
            margin-left: 20px;
            margin-right: 20px;
            > div {
              padding-left: 60px;
            }
            img {
              width: 35px;
              height: 35px;
              vertical-align: middle;
            }
            .info-num {
              color: #fff;
              font-size: 18px;
            }
            .info-n {
              width: 230px;
              color: #fff;
              font-size: 18px;
              span {
                font-size: 24px;
                color: #00e6b4;
              }
            }
          }
        }
      }
      .chart-one {
        flex: 3;
        margin: 0 15px;
      }
      .rate {
        flex: 2;
        .rate-box {
          padding: 0 10px;
          display: flex;
          box-sizing: border-box;
          align-items: center;
          .b-le {
            flex: 2;
            text-align: center;
            .rate-t {
              display: flex;
              color: #fff;
              font-size: 18px;
              align-items: center;
              margin-top: 6px;
              .rate-i {
                width: 20%;
                img {
                  width: 30px;
                  height: 30px;
                }
              }
              .rate-n {
                width: 50%;
                margin: 0 10px;
              }
              .rate-r {
                width: 30%;
                span {
                  color: #f0cd43;
                }
              }
            }
          }
          .b-ri {
            flex: 1;
            color: #fff;
            text-align: center;
            font-size: 18px;
            .bi {
              span {
                color: #f0cd43;
              }
            }
            .ri {
              width: 100px;
              margin: 0 auto;
            }
          }
        }
      }
    }
    .center-top {
      .onei {
        text-align: center;
        .gl-one {
          display: inline-block;
          margin: 10px 30px;
          .on-left {
            color: #00b2e6;
            font-size: 18px;
            text-align: left;
            margin-bottom: 10px;
          }
          .on-right {
            display: flex;
            align-items: center;
            .num {
              display: flex;
              align-items: center;
              .num-l {
                background-image: linear-gradient(
                  -180deg,
                  #f89000 0%,
                  #c46300 100%
                );
                width: 40px;
                height: 70px;
                line-height: 70px;
                text-align: center;
                margin-right: 10px;
                color: #fff;
                font-size: 26px;
              }
              .num-2 {
                background-image: linear-gradient(
                  -180deg,
                  #5cabfb 0%,
                  #047ef7 100%
                );
                width: 40px;
                height: 70px;
                line-height: 70px;
                text-align: center;
                margin-right: 10px;
                color: #fff;
                font-size: 26px;
              }
            }
          }
        }
      }
    }
    .box-center {
      margin: 0 20px;
      flex: 3;
      .ok {
        display: flex;
        .ok-left {
          flex: 1;
          display: flex;
          height: 230px;
          background: rgba(0, 0, 0, 0.2);
          align-items: center;
          padding: 0 50px;
          justify-content: space-between;
          .ok-one {
            color: #fff;
            text-align: center;
            font-size: 18px;
            .ok-num {
              margin-bottom: 20px;
              span {
                font-size: 24px;
                margin-right: 5px;
                display: inline-block;
              }
            }
          }
        }
      }
      .chart-one {
        margin-top: 25px;
        width: 100%;
        // height: 260px;
      }
    }
    .right-one , .border-box-content{
      flex: 2;
      .chart-box {
        background-color: rgba(0, 0, 0, 0.2);
        align-items: center;
        // height: 200px;
        .flex {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        padding: 0 10px;
        .ch-rig {
          font-size: 18px;
          color: #fff;
          margin-top: 10px;
          text-align: center;
          flex: 1;
          .ch-num {
            span {
              color: #d9001b;
              font-size: 24px;
              font-weight: bold;
            }
          }
          .ch-na {
            width: 100px;
            margin: 0 auto;
            margin-top: 10px;
          }
        }
      }
    }
  }
  .month-finish,
  .year-finish {
    position: relative;
  }
  .month-finish::after {
    content: "月完成度";
    position: absolute;
    color: #fff;
    bottom: -30px;
    left: 50%;
    width: 100%;
    font-size: 18px;
    display: block;
    text-align: center;
    transform: translateX(-50%);
  }
  .year-finish::after {
    content: "年完成度";
    position: absolute;
    color: #fff;
    bottom: -30px;
    font-size: 18px;
    left: 50%;
    width: 100%;
    display: block;
    text-align: center;
    transform: translateX(-50%);
  }

  .echarts {
    display: flex;
    .echarts-desc {
      margin-top: 20px;
      .item {
        position: relative;
        display: flex;
        margin-bottom: 10px;
        justify-content: space-between;
        align-items: center;
        &::before {
          content: "";
          left: -20px;
          display: block;
          position: absolute;
          width: 10px;
          top: 10px;
          height: 10px;
          background: #026795;
          border-radius: 50%;
        }
        &:nth-child(1)::before {
          background-color: #1fb5fc;
        }
        &:nth-child(2)::before {
          background-color: #69edd1;
        }
        &:nth-child(3)::before {
          background-color: #f9d071;
        }
        &:nth-child(4)::before {
          background-color: #f36dc7;
        }
        &:nth-child(5)::before {
          background-color: #8400ff;
        }

        .left {
          color: #fff;
          font-size: 18px;
          margin-right: 10px;
        }
        .right {
          font-size: 18px;
        }
        &:nth-child(1) {
          color: #1fb5fc;
        }
        &:nth-child(2) {
          color: #69edd1;
        }
        &:nth-child(3) {
          color: #f9d071;
        }
        &:nth-child(4) {
          color: #f36dc7;
        }
        &:nth-child(5) {
          color: #8400ff;
        }
      }
    }
  }
  ::v-deep .month-finish .el-progress__text {
    color: #20a0ff !important;
    font-size: 20px;
  }
  ::v-deep .year-finish .el-progress__text {
    color: #e0f27b !important;
    font-size: 20px;
  }
}
</style>

