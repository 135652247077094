<template>
  <div class="map2">
    <div class="overlay1">
      <div class="box">
        <mapVue style="height: 100%"></mapVue>
      </div>
      <div class="box_right">
        <el-row>
          <el-col :span="24">
            <div class="time_gl">
              <div class="time_le">
                <!-- <div class="title">当日发电量</div> -->
                <img src="../../assets/mapNav/currentf.png" class="imgf" />
                <!--  currentf-->
                <number-flip style="margin: 6px; z-index: 5; position: relative" :number="onlineNumber2"
                  :fontSize="fontSize" font-color="#c9e6ff" :bgImage="[
                    '-webkit-linear-gradient(#131c45, #203e6a)',
                    '-webkit-linear-gradient(#203e6a, #131c45)',
                  ]" :min-count="8" space="4px"></number-flip>
                <div class="danw unit">度</div>
              </div>
            </div>
            <div class="time_gl">
              <div class="time_le">
                <!-- <div class="title">实时功率</div> -->
                <img src="../../assets/mapNav/currentg.png" class="imgf" />
                <number-flip style="margin: 6px; z-index: 5; position: relative" :number="onlineNumber"
                  :fontSize="fontSize" font-color="#ECFF87" :bgImage="[
                    '-webkit-linear-gradient(#5e5e3b, #9e9e88)',
                    '-webkit-linear-gradient(#9e9e88, #5e5e3b)',
                  ]" :min-count="8" space="4px"></number-flip>
                <div class="danw unit">kw</div>
              </div>
            </div>
          </el-col>
          <el-col :span="4">
            <!-- 天气 -->
          </el-col>
        </el-row>
        <div class="dian_i">
          <dv-border-box-8>
            <el-row :gutter="5" style="height: 100%" align="center">
              <el-col :span="6">
                <div class="dian_1">
                  <div class="in">
                    <span>{{
                      obj.sumCurrMonthPowerNum
                        ? Number(obj.sumCurrMonthPowerNum).toFixed(0)
                        : 0
                    }}</span>

                    <span class="unit">度</span>
                  </div>
                  <div class="ins">月发电量</div>
                </div>
              </el-col>
              <el-col :span="6">
                <div class="dian_1">
                  <div class="in">
                    <span>{{
                      efficient.equivalentHours
                        ? Number(efficient.equivalentHours)
                        : 0
                    }}</span>
                    <!-- <countTo
                    :startVal="0"
                    :endVal="
                      efficient.equivalentHours
                        ? Number(efficient.equivalentHours)
                        : 0
                    "
                    :duration="3000"
                    class="num222"
                  ></countTo> -->
                    <span class="unit">h</span>
                  </div>
                  <div class="ins">月有效发电时长</div>
                </div>
              </el-col>
              <el-col :span="6">
                <div class="dian_1">
                  <div class="in">
                    <span>
                      {{
                        obj.sumCurrYearPowerNum
                          ? (obj.sumCurrYearPowerNum * 1).toFixed(0)
                          : 0
                      }}</span>

                    <span class="unit">度</span>
                  </div>
                  <div class="ins">年发电量</div>
                </div>
              </el-col>
              <el-col :span="6">
                <div class="dian_1 none">
                  <div class="in">
                    <span>{{
                      efficient.yaervalentHours
                        ? Number(efficient.yaervalentHours)
                        : 0
                    }}</span>
                    <!-- <countTo
                    :startVal="0"
                    :endVal="
                      efficient.yaervalentHours
                        ? Number(efficient.yaervalentHours)
                        : 0
                    "
                    :duration="3000"
                    class="num222"
                  ></countTo> -->
                    <span class="unit">h</span>
                  </div>
                  <div class="ins">年有效发电时长</div>
                </div>
              </el-col>
            </el-row>
            <!-- <div class="rightBox2"> -->
            <!-- <div class="month-finish">
              <dv-decoration-9
                style="width: 70px; height: 70px"
                :color="['#8157ED']"
                >{{
                  completeness.month > 100 ? 100 : completeness.month
                }}%</dv-decoration-9
              >
            </div>
            <div class="year-finish">
              <dv-decoration-9
                style="width: 70px; height: 70px"
                :color="['#f9d071']"
                >{{
                  completeness.year > 100 ? 100 : completeness.year
                }}%</dv-decoration-9
              >
            </div> -->
            <!-- </div> -->
          </dv-border-box-8>
        </div>
        <div class="coli">
          <el-row :gutter="10">
            <el-col :span="6">
              <div class="co2 transition-300 shake-vertical">
                <div>
                  <span class="co2_num"> {{ obj.stationCount ? obj.stationCount * 1 : 0 }}&nbsp; 座</span>
                </div>
                <div class="co2_t">装机数量</div>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="co2 transition-300 shake-vertical">
                <div>
                  <span class="co2_num">
                    {{ obj.volumeCount ? obj.volumeCount * 1 : 0 }}&nbsp;
                    kw
                  </span>
                </div>
                <div class="co2_t">装机容量</div>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="co2 transition-300 shake-vertical">
                <div>
                  <span class="co2_num">
                    {{ obj.sumCurrYearPowerNum ? (obj.sumCurrYearPowerNum * 1).toFixed(0) : 0 }}&nbsp;
                    t
                  </span>
                </div>
                <div class="co2_t">年累计CO2减排</div>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="co2 transition-300 shake-vertical">
                <div>
                  <span class="co2_num">
                    {{ obj.sumCurrYearPowerNum ? 114 * Number(obj.sumCurrYearPowerNum).toFixed(0) : 0 }}&nbsp; t
                  </span>
                </div>
                <div class="co2_t">年累计节约标准煤</div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
    <div class="monthBox">
      <div class="item-monthbox">
        <titleInfo titleCn="月电量统计(度)"></titleInfo>
        <div style="padding-left: 0.1rem; box-sizing: border-box; height: 100%">
          <monthEcharts style="height: 100%; width: 100%"></monthEcharts>
        </div>
      </div>
      <div class="item-monthbox">
        <titleInfo titleCn="年电量统计(度)"></titleInfo>
        <div style="padding-left: 0.1rem; box-sizing: border-box; height: 100%">
          <yearEcharts style="height: 100%; width: 100%"></yearEcharts>
        </div>
      </div>
      <div class="tops item-monthbox">
        <titleInfo titleCn="月日均等有效时TOP5"></titleInfo>
        <div style="height: 100%; margin-left: -30px">
          <top5House :top5="top5" style="height: 95%"></top5House>
        </div>
      </div>
      <div class="tops_o item-monthbox">
        <titleInfo titleCn="综合考评TOP5"></titleInfo>
        <div style="margin-top: 30px">
          <top5Score ref="top5Score" class="echarts-main"></top5Score>
        </div>
      </div>
    </div>
    <timeVue class="times"></timeVue>
  </div>
</template>

<script>
import * as echarts from "echarts";
import mapDialog from "./components/mapDialog.vue";
import monthEcharts from "./components/monthEcharts.vue";
import yearEcharts from "./components/yearEcharts.vue";
import mapVue from "./components/map.vue";
import top5House from "./components/top5House.vue";
import top5Score from "./components/top5Score.vue";
import digitalScroll from "./components/digitalScroll.vue";
import timeVue from "./components/time.vue";
import titleInfo from "./components/titleInfo.vue";
import { nowSize } from "@/utils/common";
export default {
  inject: ["reload"],
  components: {
    mapDialog,
    monthEcharts,
    yearEcharts,
    mapVue,
    top5House,
    top5Score,
    digitalScroll,
    timeVue,
    titleInfo,
  },
  data() {
    return {
      modedata: {},
      numone: "",
      numtwo: "",
      completeness: {}, // 完成度
      top5plus: [],
      top5: [],
      count: {},
      co: {},
      efficient: {},
      onlineNumber: 0, //实时功率
      onlineNumber2: 0, //当日发电量
      fontSize: 0,
      obj: {},
    };
  },
  created() {
    this.fontSize = nowSize(68) + "px";
    this.getData();
    this.getData2();
    this.timer = setInterval(() => {
      // this.reload();
      this.getData();
      this.getData2();
    }, 30 * 1000);
  },
  beforeDestroy() {
    //  在组件销毁之前清除定时器
    if (this.timer) {
      clearTimeout(this.timer);
    }
  },
  mounted() {
    // const screenHeight = window.innerHeight;
    // 将元素的高度设置为屏幕的可视高度
    // document.querySelector(".container").style.height = `${screenHeight}px`;
  },
  methods: {
    // 饼图
    initEcharts() {
      this.echartDom = echarts.init(this.$refs.echartDom);
      const data = this.top5plus.map((item) => {
        return { value: item.equivalentHours, name: item.powerStationName };
      });
      const option = {
        tooltip: {
          trigger: "item",
        },
        grid: {
          top: "-50%",
        },
        series: [
          {
            name: "Access From",
            type: "pie",
            radius: "50%",
            data: data,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      };
      this.echartDom.setOption(option);
      window.onresize = () => {
        if (this.echartDom) {
          this.echartDom.resize(); // 调用 ECharts 实例的 resize 方法
        }
      };
    },
    formatProgress(item) {
      return () => {
        return `${item}h`;
      };
    },

    async getData2() {
      const res = await this.$http.post(
        "device/queryUserAllStationDailyPower",
        { userCode: JSON.parse(localStorage.getItem("userInfo")).userCode
        // stationCodeList: JSON.parse(localStorage.getItem('stationCodeList')) 
      }
      );
      if (res.data.code === 200) {
        this.obj = res.data.data;
      } else {
        this.$message.error(res.data);
      }
    },
    async getData() {
      const res = await this.$http.post("PcRealMonitoring/PCPositionList1", {});
      if (res.data.code === 200) {
        this.onlineNumber = (
          Number(res.data.data[0][0].currEfficiency) / 1000
        ).toFixed(0);
        console.log(this.onlineNumber, 359);
        this.onlineNumber2 = res.data.data[0][0].currDayPowerNum;
        this.numone = Number(res.data.data[0][0].currEfficiency)
          .toFixed(0)
          .toString()
          .padStart(8, "0");
        // if (this.numone.length < 8) {
        //   while (this.numone.length < 8) {
        //     this.numone.unshift(0);
        //   }
        // }
        this.numtwo = Number(res.data.data[0][0].currDayPowerNum)
          .toFixed(0)
          .toString()
          .padStart(8, "0");
        // if (this.numtwo.length < 8) {
        // while (this.numtwo.length < 8) {
        // this.numtwo.unshift(0);
        // }
        // }
        // 电站建设发电量
        this.modedata = res.data.data[0][0];
        console.log(this.modedata, 502);
        // // 电站建设完成度
        this.completeness = res.data.data[3][0];
        const colorList = [
          "#f9d071",
          "#fe6dc7",
          "#8400ff",
          "#1fb5fc",
          "#69edd1",
        ];
        res.data.data[1].forEach((item, index) => {
          item.color = colorList[index];
        });
        this.top5 = res.data.data[1];
        this.top5plus = res.data.data[2];
        this.$refs.top5Score.init(this.top5plus);
        // // 二氧化碳
        this.co = res.data.data[4][0];
        this.count = res.data.data[7][0];
        // 等有效
        this.efficient = res.data.data[5][0];
      } else {
        this.$message.error(res.data);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.map2 {
  position: absolute;
  top: 85px;
  left: 0;
  bottom: 0;
  right: 0;
  background: linear-gradient(to bottom, #051525, #0e2944ed);
}

.overlay1 {
  color: white;
  height: 56vh;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
}

.left,
.right {
  box-sizing: border-box;
  width: 27%;
  pointer-events: auto;
}

.box {
  width: 1200px;
  border-right: 2px solid #1fb5fc;
  border-bottom: 2px solid #1fb5fc;
}

.box_right {
  font-size: 20px;
  margin-left: 20px;
  margin-right: 20px;
  width: calc(100vw - 1200px);
  display: flex;
  flex-direction: column;

  // justify-content: space-around;
  .time_gl {
    margin-top: 1%;
    margin-bottom: 2%;

    .time_le {
      display: flex;

      .imgf {
        width: 100px;
        margin-right: 20px;
      }

      .num {
        display: flex;
        margin-top: 7px;
      }

      .nums {
        display: flex;
        margin-top: 7px;
        width: 340px;
      }
    }

    .danw {
      padding-top: 50px;
      width: 35px;
      text-align: start;
    }
  }

  .dian_i {
    margin-top: 1%;
    margin-bottom: 1%;
    background: url("../../assets/mapNav/middlebgTwo.png") right center no-repeat;
    background-size: 101% 100%;

    .dian_1 {
      padding-top: 3vh;
      padding-bottom: 3vh;
      text-align: center;
      align-items: center;
      background: url("../../assets/mapNav/line.png") right center no-repeat;
      background-size: 2px 40px;

      &.none {
        background: none;
      }

      .in {
        color: #1fb5fc;
        font-size: 26px;
        font-family: "YouSheBiaoTiHei";
      }

      .ins {
        color: #c8e9ff;
        margin-top: 10px;
        font-size: 18px;
        white-space: nowrap;
      }
    }
  }

  .bin {
    flex: 1;
    display: flex;
    font-size: 16px;

    .month-finish::after {
      content: "月完成度";
      position: absolute;
      color: #fff;
      bottom: 8px;
      left: 50%;
      bottom: 18px;
      width: 100%;
      display: block;
      text-align: center;
      transform: translateX(-50%);
    }

    .year-finish::after {
      content: "年完成度";
      position: absolute;
      color: #fff;
      bottom: 18px;
      left: 50%;
      width: 100%;
      display: block;
      text-align: center;
      transform: translateX(-50%);
    }
  }

  .coli {
    margin-top: 5%;

    .co2 {
      text-align: center;
      padding: 10px 0;
      box-sizing: border-box;
      background: url("../../assets/mapNav/bottomTwo.png") center center no-repeat;
      background-size: 180px 110px;

      .co2_t {
        color: #c8e9ff;
        font-size: 16px;
        padding-top: 5px;
        padding-bottom: 10px;
      }

      .co2_num {
        color: #93fb01;
        font-family: "YouSheBiaoTiHei";
        font-size: 22px;
      }
    }
  }
}

.monthBox {
  display: flex;
  text-align: center;
  justify-content: space-around;
  width: 100%;
  height: calc(44vh - 100px);

  .item-monthbox {
    width: 24%;
    height: 90%;
  }
}

.tops {
  .topp {
    display: flex;
    margin-top: 15px;

    .topname {
      color: #97b3cb;
    }

    .topbox {
      width: 46px;
      height: 23px;
      background-image: linear-gradient(90deg,
          #008bff 0%,
          #00e3ff 99.01715808762268%);
      margin-right: 140px;
      margin-left: 5px;
    }

    .toptime {
      color: #97b3cb;
    }
  }
}

.tops_o {
  .topp {
    display: flex;
    margin-top: 15px;
    align-items: center;

    .y {
      width: 10px;
      height: 10px;
      padding: 2px;
      border: 1px solid rgba(0, 178, 230, 0);
      background-color: #00b2e6;
      border-radius: 50%;
    }

    .topname {
      color: #97b3cb;
      margin-right: 20px;
    }

    .topbox {
      width: 46px;
      height: 23px;
      background-image: linear-gradient(90deg,
          #008bff 0%,
          #00e3ff 99.01715808762268%);
      margin-right: 140px;
      margin-left: 5px;
    }

    .toptime {
      color: #97b3cb;
    }
  }
}

.rightBox2 {
  flex: 1;
  display: flex;
  // margin-left: 1.3rem;
  margin-top: 20px;
  // width: 280px;
  // justify-content: space-between;
  margin-left: 30px;
  width: 200px;

  // width: 210px;
  .item {
    display: flex;
    flex-direction: column;
    align-items: center;

    &:nth-child(1) {
      // margin-right: 0.703rem;
      width: 88px;
    }

    .img {
      position: relative;
      width: fit-content;
      height: fit-content;
      display: flex;
      align-items: center;
      justify-content: center;

      .img-box {
        height: 33.6px;
      }

      .img-inner {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        height: 17.6px;
      }

      .img-inner-text {
        color: #efb336;
        font-size: 16px;
      }
    }

    .num {
      color: #2acfc9;
      font-size: 16px;
    }

    .elc {
      color: #efb336;
    }

    .label {
      color: #fff;
      font-size: 16px;
    }
  }
}

.rightBox2 {
  svg>path:nth-child(1) {
    stroke: url(#write); // #write 此处的id就是定义的svg标签id 做替换即可
  }

  display: flex;

  .el-progress-circle {
    // margin-right: 10px;
  }

  .el-progress__text {
    font-size: 16px !important;
    color: #1fb5fc;
  }

  .month-finish,
  .year-finish {
    position: relative;
    z-index: 9999;
    margin: 0 5px;
  }

  .year-finish {
    .el-progress__text {
      font-size: 16px !important;
      color: #8157ed;
    }
  }

  .month-finish::after {
    content: "月完成度";
    position: absolute;
    color: #fff;
    left: 50%;
    width: 100%;
    display: block;
    text-align: center;
    transform: translateX(-50%);
  }

  .year-finish::after {
    content: "年完成度";
    position: absolute;
    color: #fff;
    left: 50%;
    width: 100%;
    display: block;
    text-align: center;
    transform: translateX(-50%);
  }
}

::v-deep .rightBox2 .el-progress__text {
  color: #fff !important;
}

.echarts {
  display: flex;
  align-items: center;

  .echarts-main {
    width: 140px;
    height: 180px;
  }

  .echarts-desc {
    margin-top: 16px;
    flex: 1;
    box-sizing: border-box;

    // padding: 0 0.43rem 0 1.2rem;
    .item {
      position: relative;
      display: flex;
      margin-bottom: 5.6px;
      justify-content: space-between;
      align-items: center;

      &::before {
        content: "";
        left: -24px;
        display: block;
        position: absolute;
        width: 13px;
        top: 4px;
        height: 13px;
        background: #026795;
        border-radius: 50%;
      }

      &:nth-child(1)::before {
        background-color: #1fb5fc;
      }

      &:nth-child(2)::before {
        background-color: #69edd1;
      }

      &:nth-child(3)::before {
        background-color: #f9d071;
      }

      &:nth-child(4)::before {
        background-color: #f36dc7;
      }

      &:nth-child(5)::before {
        background-color: #8400ff;
      }

      .left {
        color: #fff;
        font-size: 16px;
        width: 220px;

        // // 省略号css
        // overflow: hidden;
        // text-overflow: ellipsis;
        // white-space: nowrap;
      }

      .right {
        font-size: 16px;
      }

      &:nth-child(1) {
        color: #1fb5fc;
      }

      &:nth-child(2) {
        color: #69edd1;
      }

      &:nth-child(3) {
        color: #f9d071;
      }

      &:nth-child(4) {
        color: #f36dc7;
      }

      &:nth-child(5) {
        color: #8400ff;
      }
    }
  }
}

::v-deep .el-progress-bar {
  margin-top: 2%;
  width: 200px;
}

::v-deep .rightBox2 .el-progress__text {
  color: #fff !important;
}

.circle {
  width: 100px;
  height: 100px;
  border: 10px solid #f00;
  /* 设置边框宽度和颜色 */
  border-radius: 50%;
  /* 使元素变为圆形 */
}

.unit {
  padding-left: 1px;
  font-size: 18px !important;
}

.title {
  font-size: 20px !important;
}

// .num222 {
//   font-size: 25px;
// }

.times {
  // position: absolute;
  left: 160px;
  // top: 10px;
}

.transition-300 {
  transition: all 300ms;
  cursor: pointer;
}

.shake-vertical {
  animation: shake-vertical 3s linear infinite;
}

@keyframes shake-vertical {
  0% {
    transform: translateY(0);
  }

  25% {
    transform: translateY(8px);
  }

  75% {
    transform: translateY(-18px);
  }

  100% {
    transform: translateY(0);
  }
}
</style>
