<template>
  <div style="height: 80%">
    <div style="height: 100%; width: 100%" id="monthChart1"></div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import { nowSize } from "@/utils/common";
export default {
  data() {
    return {
      monthChart1: {},
    };
  },
  methods: {
    formatProgress(item) {
      return () => {
        return `${item} h`;
      };
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    // 获取数据
    async getData() {
      const now = new Date();
      const currentYear = now.getFullYear();
      let currentMonth = now.getMonth() + 1;

      currentMonth = currentMonth < 10 ? "0" + currentMonth : currentMonth;
      const res = await this.$http.post(
        "powerStationDeviceSummary/selectStationDuration",
        {
          dateFormat: "%Y-%m",
          summaryDate: currentYear + "-" + currentMonth,
          userCode: JSON.parse(localStorage.getItem("userInfo")).userCode,
          // stationCodeList: JSON.parse(localStorage.getItem("stationCodeList")),
        }
      );
      if (res.data.code === 200) {
        this.$nextTick(function () {
          this.initPro(res.data.data.slice(0, 5));
        });
      } else {
        this.$message.error(res.data);
      }
    },
    initPro(top5) {
      console.log(top5);

      this.monthChart1 = echarts.init(document.getElementById("monthChart1"));
      function contains(arr, dst) {
        var i = arr.length;
        while ((i -= 1)) {
          if (arr[i] == dst) {
            return i;
          }
        }
        return false;
      }
      var attackSourcesColor = [
        new echarts.graphic.LinearGradient(0, 1, 1, 1, [
          { offset: 0, color: "#395CFE" },
          { offset: 1, color: "#2EC7CF" },
        ]),
        new echarts.graphic.LinearGradient(0, 1, 1, 1, [
          { offset: 0, color: "#FA8231" },
          { offset: 1, color: "#FFD14C" },
        ]),
        new echarts.graphic.LinearGradient(0, 1, 1, 1, [
          { offset: 0, color: "#EB3B5A" },
          { offset: 1, color: "#FE9C5A" },
        ]),
        new echarts.graphic.LinearGradient(0, 1, 1, 1, [
          { offset: 0, color: "#F7B731" },
          { offset: 1, color: "#FFEE96" },
        ]),
      ];
      var attaData = [];
      var attaName = [];
      var topName = [];
      top5.forEach((it, index) => {
        attaData[index] = Number(it.duration.toFixed(0));
        attaName[index] = Number(it.duration.toFixed(0)) + "  " + "h";
        topName[index] = it.powerStationName;
      });
      var salvProMax = []; //背景按最大值
      for (let i = 0; i < attaData.length; i++) {
        salvProMax.push(attaData[0]);
      }
      function attackSourcesDataFmt(sData) {
        var sss = [];
        sData.forEach(function (item, i) {
          let itemStyle = {
            color: i > 3 ? attackSourcesColor[3] : attackSourcesColor[i],
          };
          sss.push({
            value: item,
            itemStyle: itemStyle,
          });
        });
        return sss;
      }

      let option = {
        color: ["#F7B731"],
        grid: {
          left: "-50%",
          right: "10%",
          bottom: "2%",
          top: "5%",
          containLabel: true,
        },
        tooltip: {
          show: true,
          backgroundColor: "rgba(3,169,244, 0.2)", //背景颜色（此时为默认色）
          textStyle: {
            fontSize: nowSize(14),
          },
        },
        xAxis: {
          type: "value",

          splitLine: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLine: {
            show: false,
          },
        },
        yAxis: [
          {
            type: "category",
            inverse: true,
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            axisPointer: {
              label: {
                show: false,
                //margin: 30
              },
            },
            pdaaing: [5, 0, 0, 0],
            // data: attaName,
            axisLabel: {
              show: true,
              //   margin: 30,
              //   fontSize: 10,
              align: "left",
              //   padding: [2, 0, 0, 0],
            },
          },
          {
            type: "category",
            inverse: true,
            axisTick: "none",
            axisLine: "none",
            show: true,
            axisLabel: {
              margin: nowSize(-2),
              textStyle: {
                color: "#fff",
                fontSize: nowSize(14),
              },
            },
            data: attaName,
          },
          {
            //名称
            type: "category",
            offset: -10,
            position: "left",
            axisLine: {
              show: false,
            },
            inverse: false,
            axisTick: {
              show: false,
            },
            axisLabel: {
              interval: 0,
              color: ["#fff"],
              align: "left",
              verticalAlign: "bottom",
              lineHeight: nowSize(38),
              fontSize: nowSize(14),
            },
            data: topName,
          },
        ],
        series: [
          {
            zlevel: 1,
            type: "bar",
            barWidth: nowSize(18),
            animationDuration: 1500,
            data: attackSourcesDataFmt(attaData),
            itemStyle: {
              normal: {
                barBorderRadius: 10,
              },
            },
          },
          {
            type: "bar",
            barWidth: nowSize(18),
            barGap: "-100%",
            margin: "10",
            data: salvProMax,
            textStyle: {
              //图例文字的样式
              fontSize: nowSize(12),
              color: "#fff",
            },
            itemStyle: {
              normal: {
                color: "#05325F",
                fontSize: nowSize(12),
                barBorderRadius: 30,
              },
            },
          },
        ],
      };
      this.monthChart1.setOption(option);
      window.onresize = () => {
        if (this.monthChart1) {
          this.monthChart1.resize(); // 调用 ECharts 实例的 resize 方法
        }
      };
    },
  },
};
</script>
<style lang="scss" scoped>
.progress-area {
  // padding-left: 20px;
  box-sizing: border-box;
  height: 100%;
  .progress-area-item {
    position: relative;
    align-items: center;
    & {
      margin-bottom: 8px;
    }
    span.label {
      color: #f0fffe;
      display: inline-block;
      font-size: 14px;
      width: 100%;
      text-align: left;
    }
    .el-progress {
      // flex-grow: 1;
      ::v-deep .el-progress-bar {
        // width: 100%;
      }
      ::v-deep .el-progress__text {
        color: #bfdfff !important;
        font-size: 16px !important;
        // width: 100% !important;
        text-align: start;
      }
      .el-progress-bar__outer {
        background: transparent;
      }
    }
  }
  ::v-deep .el-progress-bar {
    margin-top: 5px;
    width: 90%;
  }
}
</style>
