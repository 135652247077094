import { render, staticRenderFns } from "./pieEcharts.vue?vue&type=template&id=b1e63a80&scoped=true"
import script from "./pieEcharts.vue?vue&type=script&lang=js"
export * from "./pieEcharts.vue?vue&type=script&lang=js"
import style0 from "./pieEcharts.vue?vue&type=style&index=0&id=b1e63a80&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b1e63a80",
  null
  
)

component.options.__file = "pieEcharts.vue"
export default component.exports