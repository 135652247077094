export default {
    props: {
        width: {
            type: String,
            required: true
        },
        height: {
            type: String,
            required: true
        },
        series: [Object, String],
        option: Object
    }
}