export const colorOptions=[
    {label:"演示电站1",color:"#1fb5fc",hour:"1h"},
    {label:"演示电站2",color:"#69edd1",hour:'2h'},
    {label:"演示电站3",color:"#f9d071",hour:"3h"},
    {label:"演示电站4",color:"#fe6dc7",hour:'4h'},
    {label:"演示电站5",color:"#8400ff",hour:"5h"}
]
export const format=(percentage,index)=>{
    return colorOptions[index].hour
}
export const circleOptions={
        series: [
          {
            labelLine: {           //引导线设置
                    show:false,       //引导线不显示
           },
            type: 'pie',
            data: [
              {
                value: 34.7,
                name: 'A',
                itemStyle: {color:"#1FB5FC"}  
              },
              {
                value: 65.3,
                name: 'B',
                itemStyle: {color:"transparent"}
              }
            ],
            radius: ['80%', '90%']
          },
          {
            labelLine: {           //引导线设置
                    show:false,       //引导线不显示
           },
            type: 'pie',
            data: [
              {
                value: 34.7,
                name: 'A',
                itemStyle: {color:"#69EDD1"} 
              },
              {
                value: 65.3,
                name: 'B',
                itemStyle: {color:"transparent"}
              }
            ],
            radius: ['60%', '70%']
          },
          {
            labelLine: {           //引导线设置
                    show:false,       //引导线不显示
           },
            type: 'pie',
            data: [
              {
                value: 34.7,
                name: 'A',
                itemStyle: {color:"#F9D071"} 
              },
              {
                value: 65.3,
                name: 'B',
                itemStyle: {color:"transparent"}
              }
            ],
            radius: ['40%', '50%']
          },
          {
            labelLine: {           //引导线设置
                    show:false,       //引导线不显示
           },
            type: 'pie',
            data: [
              {
                value: 34.7,
                name: 'A',
                itemStyle: {color:"#FE6DC7"}
                
              },
              {
                value: 53.7,
                name: 'B',
                itemStyle: {color:"transparent"}
              }
            ],
            radius: ['20%', '30%']
          },
          {
            labelLine: {           //引导线设置
                    show:false,       //引导线不显示
           },
            type: 'pie',
            data: [
              {
                value: 34.7,
                name: 'A',
                itemStyle: {color:"#8400FF"}
                
              },
              {
                value: 65.3,
                name: 'B',
                itemStyle: {color:"transparent"}
              }
            ],
            radius: ['10%', '20%']
          },
         
        ]
      };
