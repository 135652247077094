<template>
  <div class="sx-tabs">
    <div class="sx-tabs--header" :style="{height: headerHeight}">
      <div :style="{color: item.name === currentPaneName ? selectedColor : defaultColor}" v-for="(item, i) in children" :key="i" v-text="item.label" @click="clickHandler(item)"></div>
    </div>
    <div class="sx-tabs--content">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "tabs",
  data() {
    return {
      currentPaneName: null,
      children: []
    }
  },
  props: {
    value: String,
    headerHeight: String,
    defaultColor: String,
    selectedColor: String
  },
  mounted() {
    this.children = this.$children.filter(child => child.type === "TabPanel");
    this.currentPaneName = this.value || null;
  },
  watch: {
    value(v) {
      this.currentPaneName = v;
    },
    currentPaneName(name) {
      this.children.forEach(child => {
        child.display = "none";
        if (name === child.name) child.display = "block";
      })
    }
  },
  methods: {
    clickHandler(child) {
      this.currentPaneName = child.name;
      this.$emit("tab-click", child.name);
    }
  }
}
</script>

<style lang="less" scoped>
.sx-tabs {
  &--header {
    width: 100%;
    height: 100px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;

    div {
      text-align: center;
      font-size: 28px;
      cursor: pointer;
    }
  }
  &--content {
    width: 100%;
    position: relative;
  }
}
</style>