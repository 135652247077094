import { render, staticRenderFns } from "./LeftData.vue?vue&type=template&id=fd6b2e12&scoped=true"
import script from "./LeftData.vue?vue&type=script&lang=js"
export * from "./LeftData.vue?vue&type=script&lang=js"
import style0 from "./LeftData.vue?vue&type=style&index=0&id=fd6b2e12&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fd6b2e12",
  null
  
)

component.options.__file = "LeftData.vue"
export default component.exports