<template>
  <div class="rotate" :style="{width:width, height:height}">
    <div class="rotate--item"
      :style="{width:'100%', height:'100%', animationName:animationName, animationDuration:duration, animationTimingFunction:timingFunction}"
    >
      <slot></slot>
    </div>
  </div>
</template>

<script>
import Util from "../../../utils/util.js";
export default {
  name: 'rotate',
  data() {
    return {
      styleTag: null,
      animationName: "rotate-animation"
    }
  },
  props: {
    rotateAxis: {
      type: String,
      default: "z"
    },
    duration: String,
    width: String,
    height: String,
    timingFunction: {
      type: String,
      default: "linear"
    }
  },
  created() {
    this.$$_createStyle();
  },
  methods: {
    $$_createStyle() {
      this.animationName += `-${this.rotateAxis}__${Util.getRandomString(6)}`;
      // js创建@keyframes
      const runkeyframes =`@keyframes ${this.animationName} {
        0% {
          transform: ${this.rotateAxis === "z" ? "rotateZ(0)" : this.rotateAxis === "y" ? "rotateY(0)" : "rotateX(0)"}
        }
        100% {
          transform: ${this.rotateAxis === "z" ? "rotateZ(360deg)" : this.rotateAxis === "y" ? "rotateY(360deg)" : "rotateX(360deg)"}
        }
      }`
      // 创建style标签
      this.styleTag = document.createElement('style');
      this.styleTag.type = 'text/css';
      this.styleTag.innerHTML = runkeyframes;
      // 将style样式存放到head标签
      document.getElementsByTagName('head')[0].appendChild(this.styleTag);
    }
  },
  beforeDestroyed() {
    this.styleTag.parentNode.removeNode(this.styleTag);
  }
}
</script>

<style lang="less" scoped>
@import "./style.less";
</style>