<script >
import PowerStationMask from '@/components/powerStationMask/index.vue';
export default {
  components:{
    PowerStationMask
  },
  data() {
    return {
      activeIndex: "",
      subMenus: [],
      openeds:[],
      PowerStationMask:false,
      showMask: false
    };
  },
  computed: {
    userMenus() {
      return this.$store.state.menus;
    },
  },
  watch: {
    "$route.path"(newPath) {
      this.getMenu();
      this.defaultcollapse(newPath);
      this.changeActiveIndex();
      this.showMaskChange(newPath);
    },
  },
  mounted() {
    this.getMenu();
    this.changeActiveIndex();
    this.showMaskChange(this.$route.path)
  },
  methods: {
    displayMask(){
         this.PowerStationMask=false;
    },
    defaultcollapse(newPath){
      let lastSlashIndex = newPath.lastIndexOf('/');
      let beforeLastSlash = newPath.substring(0, lastSlashIndex);
      this.openeds=[beforeLastSlash];
    },
    showMaskChange(path) {
      // 根据路径的变化来决定是否显示 <PowerStationMask> 组件
      // if (path.startsWith('/analysis/analysis/') || path.startsWith('/yunwei/yunwei/')) {
      //   this.showMask = true;
      // } else {
      //   this.showMask = false;
      // }
    },
    RouterPush(item) {
      this.$router.push(item.link);
    },
    changeActiveIndex() {
      const path = this.$route.path;
      this.activeIndex = path;

    },
    handleSelect(key, keyPath) {
      this.activeIndex = key;
    },
    getMenu() {
      const path = this.$route.path.split("/")[1];
      const formattedPath = "/" + path;
      let filiterMenus;
      filiterMenus = this.userMenus.filter(
        (menu) => menu.link == formattedPath
      )[0]?.childrens;
      this.subMenus = filiterMenus;

    },
  },
};
</script>
<template>
  <div class="monitorBox">
    <div class="monitor">
    <PowerStationMask v-if="showMask" :displayMask="displayMask"></PowerStationMask>
    <div class="left">

      <el-menu
        background-color="#14222d"
        :default-active="activeIndex"
        text-color="#fff"
        router
        class="el-menu-vertical-demo"
        @select="handleSelect"
        :collapse="false"
        :default-openeds="openeds"
      >
        <el-submenu
          v-for="(item, index) in subMenus"
          :key="index"
          :index="item.link"
        >
          <template slot="title">{{ item.menuName }}</template>
          <el-menu-item
            v-for="(subItem, subIndex) in item.childrens"
            :key="subIndex"
            @click="RouterPush(subItem)"
            :index="subItem.link"
            :class="{ 'active': activeIndex === subItem.link }"
          >
            {{ subItem.menuName }}
          </el-menu-item>
        </el-submenu>
      </el-menu>
    </div>
    <div class="rightMain" style="">
      <RouterView />
    </div>
  </div>
  </div>
</template>



<style lang="less" scoped>
/deep/ .el-submenu__title {
  background: rgb(16, 27, 36) !important;
  &:hover {
    background: rgb(16, 27, 36) !important;
  }
}
/deep/ .el-menu {
  border-right: none;
  .el-menu-item:hover {
    background: rgb(16, 27, 36) !important;
  }
}
.monitorBox{
  height: 100%;
.monitor {
  display: flex;
  height: 100%;
  background: #223f6c;
  .left {
    width: 230px;
    // height: 100%;
    background-color: rgba(20, 34, 45, 1);
    overflow: auto;
  }
  .rightMain {
    // height: 100%;
    flex: 1;
    overflow-y: auto;
  }
}
}
.active {
  border-bottom: none;
  background: rgba(44, 78, 115, 1);
  &::before {
    content: "";
    display: block;
    position: absolute;
    width: calc(100% - 28px);
    height: 0.04028rem;
    background: #00b2e6;
    bottom: -0.02157rem;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>
