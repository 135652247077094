<template>
  <div :class="['pie-multi', rootClass]">
    <div class="pie-multi--item" ref="pie_multi" :style="{width: width, height: height}"></div>
    <div class="pie-multi--legend" :style="{paddingLeft: legendOffset[0], paddingTop: legendOffset[1]}">
			<x-legend :list="legendList" :percentage="percentage" :fontSize="fontSize" :unit="unit"></x-legend>
		</div>
  </div>
</template>

<script>
import Util from "../../../utils/util";
import props from "../../common/js/props";
import Legend from "../../legend";
import legendProps from "../../common/js/legend-props";
const Constant = {
  lp: {
    left: "left",
    right: "right"
  }
};
export default {
	name: "PieMulti",
	components: { 'x-legend': Legend },
  mixins: [ props, legendProps ],
  data() {
    return {
      echart: null,
      op: {
				animation: false,
				graphic: {
					elements: [{
						type: "text",
						left: "center",
						top: "40%",
						style: {
							text: Util.autoBreakLine(this.series.title.text, 2),
							fill: this.series.title.color,
							font: '20px sans-serif'
						},
					}]
				}
      },
      serie: {
        type: "pie",
        label: {
          show: false
        },
        silent: true
			},
			legendList: []
    };
  },
  props: {
    legendPostion: {
      type: String,
      default: Constant.lp.right //left,right can be use
		},
		legendOffset: {
			type: Array,
			default: _ => [0, 0]
		}
  },
  computed: {
    rootClass() {
      return this.legendPostion === Constant.lp.right
        ? "pie-multi--h"
        : "pie-multi--h-reverse";
    }
	},
	created() {
		this.$$_handleSeries();
	},
  mounted() {
		this.echart = this.$Echarts.init(this.$refs["pie_multi"]);
    this.echart.setOption(this.op);
	},
	methods: {
		$$_handleSeries() {
			const serieArr = [], data = this.series.data;
			const sum = data.reduce((sum, current) => {
				return sum + parseFloat(current.value);
			}, 0);
			for (let i = 0, len = data.length; i < len; i++) {
				let d;
				const v1 = {value: data[i].value};
				const v2 = {value: sum - parseFloat(data[i].value)};
				if (data[i].color) {
					v1['itemStyle'] = {color: data[i].color[0]};
					v2['itemStyle'] = {color: data[i].color[1]};
				}
				if (i % 2 === 0) {
					d = [v1, v2];
				} else {
					d = [v2, v1];
				}
				serieArr[i] = Util.mergeToNew(this.serie, {
					data: d,
					radius: data[i].radius
				});
				this.legendList.push({
					name: {text: data[i].name, color: data[i].color[0]},
					value: {v: data[i].value, color: data[i].color[0]},
					icon: {i: data[i].icon, color: data[i].color[0]}
				})
			}
			this.op["series"] = serieArr;
		}
	}
};
</script>
<style lang="less" scoped>
@import "./style.less";
</style>