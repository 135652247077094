<template>
  <div class="number-flip-item" :style="{width: fontSize, height: fontSize}">
    <div class="number-flip-item__num" :style="{width: fontSize, height: fontSize}">
      <div class="number-flip-item__num__up" :style="{height: `${h-1}px`, backgroundColor: bgColor, backgroundImage: bg_up_url}">
        <icon :i="num_up_i" :color="fontColor" :font-size="fontSize" style="margin-bottom:-50%; padding:5px;  box-sizing: border-box "></icon>
      </div>
      <div class="number-flip-item__num__down" :style="{height: `${h-1}px`, backgroundColor: bgColor, backgroundImage: bg_down_url}">
        <icon :i="num_down_i" :color="fontColor" :font-size="fontSize" style="margin-top:-50%; padding:5px; box-sizing: border-box "></icon>
      </div>
    </div>
    <div ref="animation_dom" :class="['number-flip-item__fy', {'flip-fy-animation': value !== oldValue}]" :style="{width: fontSize, height: h, transformOrigin: `center ${parseInt(this.fontSize) / 2}px 0px`}">
      <div class="number-flip-item__fy__front" :style="{height: `${h-1}px`, backgroundColor: bgColor, backgroundImage: bg_up_url}">
        <icon :i="fy_front_i" :color="fontColor" :font-size="fontSize" style="margin-bottom:-50%; padding:5px;  box-sizing: border-box"></icon>
      </div>
      <div class="number-flip-item__fy__back" :style="{height: `${h-1}px`, backgroundColor: bgColor, backgroundImage: bg_down_url}">
        <icon :i="fy_back_i" :color="fontColor" :font-size="fontSize" style="margin-top:-50%; padding:5px;  box-sizing: border-box"></icon>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from "../../icon";
import props from "../../common/js/number-flip-props.js";
export default {
  name: "NumberFlipItem",
  mixins: [ props ],
  components: { Icon },
  data() {
    return {
      h: parseInt(this.fontSize) / 2,
      oldValue: 0,
      heightValue: parseInt(this.fontSize)
    }
  },
  props: {
    value: {
      type: String,
      required: true,
    }
  },
  computed: {
    num_up_i() {
      return `sx-shuzi${this.value}`;
    },
    num_down_i() {
      return `sx-shuzi${this.oldValue}`;
    },
    fy_front_i() {
      return `sx-shuzi${this.oldValue}`;
    },
    fy_back_i() {
      return `sx-shuzi${this.value}`;
    },
    bg_up_url() {
      let bgUrl = undefined;
      if (this.bg instanceof Array) {
        bgUrl = `url(${this.bg[0]})`;
      } else if (this.bg instanceof String) {
        bgUrl = `url(${this.bg})`;
      } else if (this.bgImage instanceof Array) {
        bgUrl = this.bgImage[0];
      } else if (this.bgImage instanceof String) {
        bgUrl = this.bgImage;
      }
      return bgUrl;
    },
    bg_down_url() {
      let bgUrl = undefined;
      if (this.bg instanceof Array) {
        bgUrl = `url(${this.bg[1]})`;
      } else if (this.bg instanceof String) {
        bgUrl = `url(${this.bg})`;
      } else if (this.bgImage instanceof Array) {
        bgUrl = this.bgImage[1];
      } else if (this.bgImage instanceof String) {
        bgUrl = this.bgImage;
      }
      return bgUrl;
    }
  },
  created() {
    console.log('heightValue', this.heightValue)
  },
  mounted() {
    this.$refs.animation_dom.addEventListener("animationend", this.animationendCallback);
  },
  methods: {
    animationendCallback() {
      this.oldValue = this.value;
    }
  },
  beforeDestroyed() {
    this.$refs.animation_dom.removeEventListener("animationend", this.animationendCallback);
  }
}
</script>

<style lang="less" scoped>
@import "./item.less";
// .a {
//   box-sizing: border-box;
// }
</style>
