<template>
  <div class="indexMapfive">
    <div class="mapbox">
      <mapVue class="map"></mapVue>
    </div>
    <div class="box">
      <div class="box-left">
        <div class="gl-one">
          <div class="on-left">当日发电量</div>
          <div class="on-right">
            <number-flip style="margin: 6px; z-index: 5; position: relative" :number="onlineNumber2"
              :fontSize="fontSize" font-color="#ffffff" :bgImage="[
                '-webkit-linear-gradient(#007dff, #1fb5fc)',
                '-webkit-linear-gradient(#1fb5fc, #007dff)',
              ]" :min-count="8" space="4px"></number-flip>
            <div style="color: #b8dfff;">
              度
            </div>
          </div>
        </div>

        <div class="rightBox">
          <div class="month-finish">
            <dv-decoration-9 class="decoration" :color="['#8157ED']">{{
              completeness.month > 100 ? 100 : completeness.month
            }}%</dv-decoration-9>
          </div>
          <div class="year-finish">
            <dv-decoration-9 class="decoration" :color="['#f9d071']">{{
              completeness.year > 100 ? 100 : completeness.year
            }}%</dv-decoration-9>
          </div>
        </div>
        <div class="suj">
          <div class="suj-l">
            <div class="suj-ll">
              <div class="su">
                {{
                  obj.sumCurrMonthPowerNum
                    ? Number(obj.sumCurrMonthPowerNum).toFixed(0)
                    : 0
                }}&nbsp;<span>度</span>
              </div>
              <div class="sud">月发电量</div>
            </div>
          </div>
          <div class="suj-l">
            <div class="suj-ll">
              <div class="su">
                {{
                  efficient.equivalentHours
                    ? Number(efficient.equivalentHours)
                    : 0
                }}&nbsp;<span>h</span>
              </div>
              <div class="sud">月有效发电时长</div>
            </div>
          </div>
          <div class="suj-l">
            <div class="suj-ll">
              <div class="su">
                {{
                  obj.sumCurrYearPowerNum
                    ? (obj.sumCurrYearPowerNum * 1).toFixed(0)
                    : 0
                }}&nbsp;<span>度</span>
              </div>
              <div class="sud">年发电量</div>
            </div>
          </div>
          <div class="suj-l">
            <div class="suj-ll">
              <div class="su">
                {{
                  efficient.yaervalentHours
                    ? Number(efficient.yaervalentHours)
                    : 0
                }}&nbsp;<span>h</span>
              </div>
              <div class="sud">年有效发电时长</div>
            </div>
          </div>
        </div>
        <div class="montop">
          <dv-border-box-7>
            <div style="color: #b8dfff; padding-top: 10px">月发电统计(度)</div>
            <div class="top-box">
              <monthEcharts style="width: 100%; height: 100%"></monthEcharts>
            </div>
          </dv-border-box-7>
        </div>
      </div>
    </div>
    <div class="topp-box">
      <div class="bo">
        <div class="number">
          {{ obj.volumeCount ? obj.volumeCount * 1 : 0 }}
          <span>kw</span>
        </div>
        <div>装机容量</div>
      </div>
      <div class="bo">
        <div class="number">
          {{ obj.stationCount ? obj.stationCount * 1 : 0 }}
          <span>座</span>
        </div>
        <div>电站数量</div>
      </div>
      <div class="bo">
        <div class="number">
          {{ onlineNumber ? onlineNumber : 0 }}
          <span>t</span>
        </div>
        <div>实时功率</div>
      </div>
    </div>
    <div class="box2">
      <div class="box-left">
        <div class="gl-one">
          <div class="on-left" style="margin-bottom: 0.0625rem">累计发电量</div>
          <div class="on-right">
            <number-flip style="margin: 6px; z-index: 5; position: relative" :number="alllist" :fontSize="fontSize"
              font-color="#ffffff" :bgImage="[
                '-webkit-linear-gradient(#007dff, #1fb5fc)',
                '-webkit-linear-gradient(#1fb5fc, #007dff)',
              ]" :min-count="8" space="4px"></number-flip>
            <!-- <div class="num">
              <div
                class="nums"
                v-for="(item, i) in numtwo"
                :key="i"
                style="
                  background-image: linear-gradient(
                    -180deg,
                    #00b1ff 0%,
                    #0065ff 93.84548232213%
                  );
                "
              >
                {{ item }}
              </div>
            </div> -->
            <div style="color: #b8dfff;">
              kw
            </div>
          </div>
        </div>
        <div class="coli">
          <div class="co2">
            <div class="co2_num">
              <span>
                {{ obj.sumCurrYearPowerNum ? (obj.sumCurrYearPowerNum * 1).toFixed(0) : 0 }}&nbsp;t
              </span>
            </div>
            <!-- <img src="../../assets/normal_u54.png" alt="" /> -->
            <div class="co2_t">年累计CO2减排</div>
          </div>
          <div class="co2">
            <div class="co2_num">
              <span>
                {{ obj.sumCurrYearPowerNum ? 114 * Number(obj.sumCurrYearPowerNum).toFixed(0) : 0 }}&nbsp;t
              </span>
            </div>
            <!-- <img src="../../assets/normal_u58.png" alt="" /> -->
            <div class="co2_t">年累计节约标准煤</div>
          </div>
        </div>

        <div class="rightBox1">
          <div style="text-align: center; color: #b8dfff; margin-bottom: 10px">
            综合考评TOP5
          </div>
          <dv-border-box-7>
            <top5Score ref="top5Score" class="echarts-main"></top5Score>
          </dv-border-box-7>
        </div>
        <div class="montop">
          <dv-border-box-7>
            <div style="color: #b8dfff; padding-top: 10px">年发电统计(度)</div>
            <div class="top-box">
              <yearEcharts style="height: 100%; width: 100%"></yearEcharts>
            </div>
          </dv-border-box-7>
        </div>
      </div>
    </div>
    <timeVue class="times"></timeVue>
  </div>
</template>

<script>
import mapVue from "./components/map.vue";
import monthEcharts from "./components/monthEcharts.vue";
import yearEcharts from "./components/yearEcharts.vue";
import top5Score from "./components/top5Score.vue";
import timeVue from "./components/time.vue";
import digitalScroll from "./components/digitalScroll.vue";
import { nowSize } from '@/utils/common'
export default {
  inject: ["reload"],
  components: {
    mapVue,
    yearEcharts,
    monthEcharts,
    top5Score,
    timeVue,
    digitalScroll,
  },
  data() {
    return {
      modedata: {},
      numone: "",
      numtwo: "",
      completeness: {}, // 完成度
      top5plus: [],
      top5: [],
      count: {}, // 装机
      co: {}, // 二氧化碳
      efficient: {}, //有效
      onlineNumber: 0, //实时功率
      onlineNumber2: 0, //当日发电量
      alllist: "",
      fontSize: 0,
      obj: {}
    };
  },

  created() {
    this.fontSize = nowSize(53) + 'px'
    this.getData();
    this.getData2();
    this.timer = setInterval(() => {
      this.getData();
      this.getData2();
      // this.onlineNumber2++;
    }, 1000);
  },
  beforeDestroy() {
    //  在组件销毁之前清除定时器
    if (this.timer) {
      clearTimeout(this.timer);
    }
  },
  methods: {
    formatProgress(item) {
      return () => {
        return `${item}h`;
      };
    },
    // 获取数据
    async getData() {
      const res = await this.$http.post("PcRealMonitoring/PCPositionList1", {});
      if (res.data.code === 200) {
        this.onlineNumber = (
          Number(res.data.data[0][0].currEfficiency) / 1000
        ).toFixed(0);
        this.onlineNumber2 = res.data.data[0][0].currDayPowerNum;
        this.numone = Number(res.data.data[0][0].currEfficiency)
          .toFixed(0)
          .toString()
          .padStart(8, "0");
        if (this.numone.length < 8) {
          while (this.numone.length < 8) {
            this.numone.unshift(0);
          }
        }
        // 当日发电量
        this.numtwo = Number(res.data.data[0][0].currDayPowerNum)
          .toFixed(0)
          .toString()
          .padStart(8, "0");
        console.log(this.numtwo);
        if (this.numtwo.length < 8) {
          while (this.numtwo.length < 8) {
            this.numtwo.unshift(0);
          }
        }
        this.alllist = Number(res.data.data[0][0].totalPowerNum)
          .toFixed(0)
          .toString()
          .padStart(8, "0");
        // 电站建设发电量
        this.modedata = res.data.data[0][0];
        // // 电站建设完成度
        this.completeness = res.data.data[3][0];
        const colorList = [
          "#f9d071",
          "#fe6dc7",
          "#8400ff",
          "#1fb5fc",
          "#69edd1",
        ];
        res.data.data[1].forEach((item, index) => {
          item.color = colorList[index];
        });

        this.top5 = res.data.data[1];
        // this.top5plus = res.data.data[2];
        this.$refs.top5Score.init(res.data.data[2]);
        //二氧化碳
        this.co = res.data.data[4][0];
        // 装机容量
        this.count = res.data.data[7][0];
        // 等有效
        this.efficient = res.data.data[5][0];
      } else {
        this.$message.error(res.data);
      }
    },
    async getData2() {
      const res = await this.$http.post(
        "device/queryUserAllStationDailyPower",
        { userCode: JSON.parse(localStorage.getItem("userInfo")).userCode
        // stationCodeList: JSON.parse(localStorage.getItem('stationCodeList')) 
      }
      );
      if (res.data.code === 200) {
        this.obj = res.data.data;
      } else {
        this.$message.error(res.data);
      }
    },
  },
};
</script>

<style scoped lang="less">
::v-deep .rightBox .el-progress__text {
  color: #b8dfff !important;
  font-size: 16px !important;
}

.progress-area {
  padding-left: 10px;
  box-sizing: border-box;

  .progress-area-item {
    align-items: center;

    & {
      // margin-bottom: 5px;
    }

    span.label {
      color: #b8dfff;
      display: inline-block;
      font-size: 14px;
      width: 100%;
      text-align: left;

      // // 省略号css
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .el-progress {
      flex-grow: 1;

      ::v-deep .el-progress__text {
        color: #b8dfff;
        font-size: 16px !important;
        width: 60px !important;
        text-align: start;
      }

      ::v-deep .el-progress-bar {
        width: 90%;
        margin-left: 10px;
      }

      .el-progress-bar__outer {
        // background: #026795;
        border: 1px solid #026795;
        background: transparent;
      }
    }
  }
}

.mapbox {
  width: 100vw;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;

  .map {
    width: 100%;
    height: 100%;
  }
}

.indexMapfive {
  top: 85px;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  font-size: 18px;
}

.overlay {
  color: white;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
}

.box {
  height: 100%;
  position: absolute;
  text-align: center;
  z-index: 999;

  .box-left {
    box-sizing: border-box;
    padding: 0 25px;
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 26vw;
    height: 100%;
    background-color: rgba(47, 44, 42, 0.76);

    .gl-one {
      margin-top: 10px;

      .on-left {
        color: #b8dfff;
        text-align: left;
      }

      .on-right {
        display: flex;
        align-items: center;
        justify-content: center;

        .num {
          display: flex;

          .num-l {
            background-image: linear-gradient(-180deg,
                #f89000 0%,
                #c46300 100%);
            width: 36px;
            height: 58px;
            line-height: 58px;
            text-align: center;
            margin-right: 10px;
            color: #b8dfff;
            font-size: 25px;
          }
        }
      }
    }

    .suj {
      display: flex;
      flex-wrap: wrap;

      .suj-l {
        // margin-bottom: 10px;
        width: 50%;
        color: #b8dfff;

        .suj-ll {
          height: 13vh;
          background: url("../../assets/mapNav/di.png") center center no-repeat;
          background-size: 20vh 10vh;
        }

        .su {
          color: #e6a23c;
          font-size: 26px;
          font-family: "YouSheBiaoTiHei";
          margin-bottom: 20px;
        }
      }
    }

    .montop {
      height: 28vh;
      margin-bottom: 10px;

      .top-box {
        width: 100%;
        height: 88%;
      }
    }

    .gd {
      width: 48px;
      height: 170px;
      background-image: url("../../assets/gd.svg");
      background-size: contain;
      background-repeat: no-repeat;
      position: absolute;
      right: 46px;
      top: 37%;
      color: #b8dfff;
      text-align: center;
      padding-top: 56px;
    }
  }
}

.box2 {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  text-align: center;

  .box-left {
    background-color: rgba(47, 44, 42, 0.76);
    width: 26vw;
    height: 100%;
    box-sizing: border-box;
    padding: 0 25px;
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    .gl-one {
      margin-top: 10px;

      .on-left {
        color: #b8dfff;
        text-align: left;
      }

      .on-right {
        display: flex;
        align-items: center;
        justify-content: center;

        .num {
          display: flex;

          .num-l {
            background-image: linear-gradient(-180deg,
                #f89000 0%,
                #c46300 100%);
            width: 36px;
            height: 58px;
            line-height: 58px;
            text-align: center;
            margin-right: 10px;
            color: #b8dfff;
            font-size: 25px;
          }
        }
      }
    }

    .suj {
      display: flex;
      flex-wrap: wrap;

      .suj-l {
        margin-bottom: 20px;
        width: 50%;
        color: #b8dfff;

        .su {
          margin-bottom: 6px;

          span {
            font-weight: bold;
            color: #1ea5e6;
            font-size: 26px;
          }
        }
      }
    }

    .rightBox1 {
      margin-bottom: 20px;

      .echarts-main {
        height: 100%;
      }
    }

    .montop {
      height: 28vh;
      margin-bottom: 10px;

      .top-box {
        width: 100%;
        height: 88%;
      }
    }

    .coli {
      display: flex;
      justify-content: space-around;
      color: #93ceff;

      .co2 {
        flex: 1;
        border-radius: 10px;

        .co2_num {
          // padding-top: 20px;
          height: 12vh;
          background: url("../../assets/mapNav/index_back.png") center bottom no-repeat;
          font-size: 24px;
          font-family: "YouSheBiaoTiHei";
          background-size: 20vh 10vh;
          color: #26bdea;
        }
      }

      img {
        display: block;
        margin: 10px auto;
        width: 50px;
      }
    }
  }
}

.topp-box {
  position: fixed;
  top: 140px;
  // width: 100%;
  left: 50%;
  transform: translate(-50%, 0);
  display: flex;
  justify-content: center;
  z-index: 999;
  font-size: 16px;

  .bo {
    background: url("../../assets/mapNav/yellowbg.png") center center no-repeat;
    background-size: 12vw 14vh;
    width: 12vw;
    height: 14vh;
    padding: 35px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    color: #ffffff;
    text-align: center;

    .number {
      color: #ff0;
      margin-bottom: 5px;
      font-size: 26px;
      font-family: "YouSheBiaoTiHei";

      span {
        color: #fff;
        font-size: 16px;
      }
    }
  }
}

/deep/.rightBox {
  display: flex;
  margin-bottom: 30px;
  width: 100%;
  justify-content: space-between;
  padding: 0 50px;
  box-sizing: border-box;

  .item {
    display: flex;
    flex-direction: column;
    align-items: center;

    &:nth-child(1) {
      // margin-right: 0.703rem;
      width: 88px;
    }

    .img {
      position: relative;
      width: fit-content;
      height: fit-content;
      display: flex;
      align-items: center;
      justify-content: center;

      .img-box {
        height: 33.6px;
      }

      .img-inner {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        height: 17.6px;
      }

      .img-inner-text {
        color: #efb336;
        font-size: 16px;
      }
    }

    .num {
      color: #2acfc9;
      font-size: 16px;

      .unit {
        color: #b8dfff;
        padding-left: 1px;
      }
    }

    .elc {
      color: #efb336;
    }

    .label {
      color: #b8dfff;
      font-size: 16px;
    }
  }
}

/deep/.rightBox {
  svg>path:nth-child(1) {
    stroke: url(#write); // #write 此处的id就是定义的svg标签id 做替换即可
  }

  .el-progress-circle {
    height: 1rem !important;
    width: 1rem !important;
  }

  .el-progress__text {
    font-size: 0.2rem !important;
    color: #1fb5fc;
  }

  .month-finish,
  .year-finish {
    position: relative;
    color: #b8dfff;

    .decoration {
      width: 140px;
      height: 140px;
    }
  }

  .year-finish {
    .el-progress__text {
      font-size: 16px !important;
      color: #e0f27b;
    }
  }

  .month-finish::after {
    content: "月完成度";
    position: absolute;
    color: #b8dfff;
    bottom: -28px;
    left: 50%;
    width: 100%;
    display: block;
    text-align: center;
    transform: translateX(-50%);
  }

  .year-finish::after {
    content: "年完成度";
    position: absolute;
    color: #b8dfff;
    bottom: -28px;
    left: 50%;
    width: 100%;
    display: block;
    text-align: center;
    transform: translateX(-50%);
  }
}

.nums {
  background-image: linear-gradient(-180deg,
      #00b1ff 0%,
      #0065ff 93.84548232213%);
  width: 36px;
  height: 58px;
  line-height: 58px;
  text-align: center;
  margin-right: 10px;
  color: #b8dfff;
  font-size: 25px;
}

.times {
  width: 300px;
  left: 50%;
  margin-left: -150px;
  position: absolute;
}
</style>
