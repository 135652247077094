<template>
  <div class="line-dance">
    <canvas ref="canvas" :width="width" :height="height"></canvas>
  </div>
</template>

<script>
import canvasProps from "../../common/js/canvas-props";
export default {
  name: "LineDance",
  mixins: [ canvasProps ],
  data() {
    return {
      cvs: null,
      maxHeight: parseInt(this.height),
      minHeight: parseInt(this.height) / this.danceLineCount,
      w: parseInt(this.width) < this.space ? this.space - this.lineWidth / 2 : parseInt(this.width) - this.lineWidth / 2,
      heightArr: []
    }
  },
  props: {
    space: {
      type: Number,
      default: 10
    },
    lineWidth: {
      type: Number,
      default: 1
    },
    color: {
      type: String,
      default: "#00fcff"
    },
    danceLineCount: {
      type: Number,
      default: 4
    }
  },
  mounted() {
    if (this.$refs.canvas.getContext) {
      this.cvs = this.$refs.canvas.getContext('2d');
      this.$$_init();
      this.$$_draw();
      this.$$_dance();
    }
  },
  methods: {
    $$_init() {
      this.cvs.strokeStyle = this.color;
      this.cvs.lineWidth = this.lineWidth;
      let temp = this.minHeight, t = temp;
      for (let i = 0, count = Math.floor(this.w / this.space); i < count; i++) {
        this.heightArr.push({
          h: temp,
          t: 1
        });
        if (this.danceLineCount !== 1) {
          if (temp <= this.minHeight && t < 0 || temp >= this.maxHeight && t > 0) t = -t;
          temp += t;
        }
      }
    },
    $$_draw() {
      let count = Math.floor(this.w / this.space);
      this.cvs.beginPath();
      for (let i = 0; i < this.heightArr.length; i++) {
        if (this.heightArr[i].h > this.maxHeight || this.heightArr[i].h < this.minHeight) {
          if (this.heightArr[i].h > this.maxHeight) this.heightArr[i].h = this.maxHeight;
          else if (this.heightArr[i].h < this.minHeight) this.heightArr[i].h = this.minHeight;
          this.heightArr[i].t = -this.heightArr[i].t;
        }
        this.heightArr[i].h += this.heightArr[i].t;
        this.cvs.moveTo(i * this.space + this.lineWidth / 2, (this.maxHeight - this.heightArr[i].h) / 2);
        this.cvs.lineTo(i * this.space + this.lineWidth / 2, (this.maxHeight - this.heightArr[i].h) / 2 + this.heightArr[i].h);
        this.cvs.stroke();
      }      
    },
    $$_dance() {
      if (this.danceLineCount !== 1) {
        this.cvs.clearRect(0, 0, this.w + 1, this.maxHeight + 1);
        this.$$_draw();
        window.requestAnimationFrame(this.$$_dance);
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import "./style.less";
</style>