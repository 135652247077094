<template>
  <div class="indexMapseven">
    <div class="mapbox">
      <mapVue class="map"></mapVue>
    </div>
    <div class="boxRight">
      <div class="box-right">
        <div class="gl-one">
          <div class="on-left" style="margin-bottom: 10px">实时功率</div>
          <div class="on-right">
            <div class="num">
              <number-flip style="margin: 6px; z-index: 5; position: relative" :number="numone" :fontSize="fontSize"
                font-color="#f0f8ff" :bgImage="[
                  '-webkit-linear-gradient(rgb(63, 97, 255), rgb(138, 159, 255))',
                  '-webkit-linear-gradient(rgb(138, 159, 255), rgb(63, 97, 255))',
                ]" :min-count="8" space="4px"></number-flip>
            </div>
            <div style="color: #fff">w</div>
          </div>
        </div>
        <div class="rl-bo">
          <div class="bo-box">
            <div>
              <img src="../../assets/six-one.png" alt="" />
            </div>
            <div>
              <div class="bo-bnum">
                {{ obj.volumeCount ? obj.volumeCount * 1 : 0 }}
                <span class="unit">kw</span>
              </div>
              <div class="bo-bname">装机容量</div>
            </div>
          </div>
          <div class="bo-box bg2">
            <div>
              <img src="../../assets/mapNav/3.png" alt="" />
            </div>
            <div>
              <div class="bo-bnum">
                {{ obj.stationCount ? obj.stationCount * 1 : 0 }}
                <span class="unit">座</span>
              </div>
              <div class="bo-bname">电站数量</div>
            </div>
          </div>
        </div>
        <div class="gl-one" style="margin-top: 10px">
          <div class="on-left" style="margin-bottom: 10px">当日发电量</div>
          <div class="on-right">
            <div class="num">
              <number-flip style="margin: 6px; z-index: 5; position: relative" :number="numtwo" :fontSize="fontSize"
                font-color="#fff" :bgImage="[
                  '-webkit-linear-gradient(rgb(63, 97, 255), rgb(138, 159, 255))',
                  '-webkit-linear-gradient(rgb(138, 159, 255), rgb(63, 97, 255))',
                ]" :min-count="8" space="4px"></number-flip>
              <!-- <div class="num-l day" v-for="(item, i) in numtwo" :key="i">
                    {{ item }}
                  </div> -->
            </div>
            <div style="color: #fff">度</div>
          </div>
        </div>
        <div class="suj">
          <dv-border-box-8>
            <div class="sujBox">
              <div class="suj-l margint">
                <div class="su">
                  <span>{{
                    obj.sumCurrMonthPowerNum
                      ? Number(obj.sumCurrMonthPowerNum).toFixed(0)
                      : 0
                  }}</span>
                  <span class="unit">度</span>
                </div>
                <div class="sud">月发电量</div>
              </div>
              <div class="suj-l margint">
                <div class="su">
                  <span>{{
                    efficient.equivalentHours ? efficient.equivalentHours : 0
                  }}</span><span class="unit">h</span>
                </div>
                <div class="sud">月有效发电时长</div>
              </div>
              <div class="suj-l">
                <div class="su">
                  <span>{{
                    obj.sumCurrYearPowerNum
                      ? (obj.sumCurrYearPowerNum * 1).toFixed(0)
                      : 0
                  }}</span><span class="unit">度</span>
                </div>
                <div class="sud">年发电量</div>
              </div>
              <div class="suj-l">
                <div class="su">
                  <span>{{
                    efficient.yaervalentHours ? efficient.yaervalentHours : 0
                  }}</span><span class="unit">h</span>
                </div>
                <div class="sud">年有效发电时长</div>
              </div>
            </div>
          </dv-border-box-8>
        </div>

        <div class="co">
          <div class="co-box">
            <dv-border-box-7 :color="['#235fa7']">
              <div class="flex">
                <img src="@/assets/mapNav/icon003.png" alt="" />
                <div>
                  <div class="de-num">
                    {{ obj.sumCurrYearPowerNum ? (obj.sumCurrYearPowerNum * 1).toFixed(0) : 0 }}
                    <span>t</span>
                  </div>
                  <div class="de-name">年累计减排CO2</div>
                </div>
              </div>
            </dv-border-box-7>
          </div>
          <div class="co-box">
            <dv-border-box-7 :color="['#235fa7']">
              <div class="flex">
                <img src="@/assets/mapNav/icon004.png" alt="" />
                <div>
                  <div class="de-num">
                    {{ obj.sumCurrYearPowerNum ? 114 * Number(obj.sumCurrYearPowerNum).toFixed(0) : 0 }}
                    <span>t</span>
                  </div>
                  <div class="de-name">年累计节约标准煤</div>
                </div>
              </div>
            </dv-border-box-7>
          </div>
        </div>
        <div class="r-top">
          <div class="bo-l">
            <div class="gl-one" style="margin-top: 10px">
              <div class="on-left" style="margin-bottom: 10px">累计发电量</div>
              <div class="on-right">
                <div class="num">
                  <number-flip style="margin: 6px; z-index: 5; position: relative" :number="alllist"
                    :fontSize="fontSize" font-color="#fff" :bgImage="[
                      '-webkit-linear-gradient(#ea8f06, #ffcb7d)',
                      '-webkit-linear-gradient(#ffcb7d, #ea8f06)',
                    ]" :min-count="8" space="4px"></number-flip>
                  <!-- <div class="num-l day" v-for="(item, i) in alllist" :key="i">
                    {{ item }}
                  </div> -->
                </div>
                <div style="color: #fff">度</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="line"></div>
    <div class="boxLeft">
      <div class="mymon">
        <div class="mymon-title">月电量统计</div>
        <monthEcharts style="widht: 100%; height: 100%" :color="'#ed8700'"></monthEcharts>
      </div>
      <div class="mymon">
        <div class="mymon-title">年电量统计</div>
        <yearEcharts style="widht: 100%; height: 100%"></yearEcharts>
      </div>
    </div>
    <timeVue class="times"></timeVue>
  </div>
</template>

<script>
import mapVue from "./components/map.vue";
import monthEcharts from "./components/monthEcharts.vue";
import yearEcharts from "./components/yearEcharts.vue";
import timeVue from "./components/time.vue";
import { nowSize } from "@/utils/common";
export default {
  components: {
    mapVue,
    monthEcharts,
    yearEcharts,
    timeVue,
  },
  data() {
    return {
      modedata: {},
      numone: "",
      numtwo: "",
      nj: "",
      yj: "",
      alllist: "",
      fontSize: 0,
      obj: {}
    };
  },

  created() {
    this.fontSize = nowSize(52) + "px";
    this.getData();
    this.getData2()
  },

  methods: {
    // 获取数据
    async getData() {
      const res = await this.$http.post("PcRealMonitoring/PCPositionList1", {});
      if (res.data.code === 200) {
        this.numone = Number(res.data.data[0][0].currEfficiency)
          .toFixed(0)
          .toString()
          .padStart(8, "0");
        // if (this.numone.length < 8) {
        //   while (this.numone.length < 8) {
        //     this.numone.unshift(0);
        //   }
        // }
        this.numtwo = Number(res.data.data[0][0].currDayPowerNum)
          .toFixed(0)
          .toString()
          .padStart(8, "0");
        // if (this.numtwo.length < 8) {
        //   while (this.numtwo.length < 8) {
        //     this.numtwo.unshift(0);
        //   }
        // }
        this.alllist = Number(res.data.data[0][0].totalPowerNum)
          .toFixed(0)
          .toString()
          .padStart(8, "0");
        // if (this.alllist.length < 8) {
        //   while (this.alllist.length < 8) {
        //     this.alllist.unshift(0);
        //   }
        // }
        //
        // 电站建设发电量
        this.modedata = res.data.data[0][0];
        console.log(this.modedata, 502);
        // // 电站建设完成度
        this.completeness = res.data.data[3][0];
        const colorList = [
          "#f9d071",
          "#fe6dc7",
          "#8400ff",
          "#1fb5fc",
          "#69edd1",
        ];
        res.data.data[1].forEach((item, index) => {
          item.color = colorList[index];
        });

        this.top5 = res.data.data[1];
        // this.top5plus = res.data.data[2];

        //二氧化碳
        this.co = res.data.data[4][0];
        // 装机容量
        this.count = res.data.data[7][0];
        // 等有效
        this.efficient = res.data.data[5][0];
      } else {
        this.$message.error(res.data);
      }
    },
    async getData2() {
      const res = await this.$http.post(
        "device/queryUserAllStationDailyPower",
        { userCode: JSON.parse(localStorage.getItem("userInfo")).userCode
        // stationCodeList: JSON.parse(localStorage.getItem('stationCodeList')) 
      }
      );
      if (res.data.code === 200) {
        this.obj = res.data.data;
      } else {
        this.$message.error(res.data);
      }
    },

    formatProgress(item) {
      return () => {
        return `${item}h`;
      };
    },
  },
};
</script>

<style scoped lang="less">
html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: hidden;
  background: linear-gradient(to bottom, #051525, #0e2944ed);
}

.mapbox {
  width: 100%;
  height: calc(100vh - 85px);
  position: absolute;

  .map {
    width: 100%;
    height: 100%;
  }
}

.indexMapseven {
  position: absolute;
  top: 85px;
  left: 0;
  right: 0;
  bottom: 0;
}

.overlay {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
}

.eh {
  position: relative;
  width: 100%;
  height: 100%;
}

.boxRight {
  height: calc(100vh - 85px);
  direction: rtl;
  position: absolute;
  top: 0;
  right: 0;
  font-size: 18px;

  .box-right {
    background-color: rgba(0, 0, 0, 0.5);
    height: 100%;
    width: 510px;
    padding: 5px 15px 0 15px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    justify-content: space-between;
    direction: ltr;

    .r-top {
      display: flex;
      justify-content: space-between;
    }

    .gl-one {
      margin-bottom: 20px;

      .on-left {
        color: #fff;
      }

      .on-right {
        display: flex;
        align-items: center;

        .num {
          display: flex;

          .num-l {
            background-image: linear-gradient(-180deg,
                #f89000 0%,
                #c46300 100%);
            width: 40px;
            height: 64px;
            line-height: 64px;
            text-align: center;
            margin-right: 12px;
            color: #fff;
            font-size: 25px;
          }
        }
      }
    }

    .suj {
      display: flex;
      flex-wrap: wrap;
      text-align: center;

      .sujBox {
        padding: 2vh 0;
      }

      .margint {
        margin-bottom: 20px;
      }

      .suj-l {
        // padding-top: 20px;
        // padding-bottom: 20px;
        width: 45%;
        display: inline-block;
        color: #fff;

        .su {
          margin-bottom: 6px;
          font-weight: bold;
          font-size: 24px;

          span {
            background-image: linear-gradient(to right,
                rgba(0, 77, 167, 1) 0%,
                rgba(0, 244, 255, 1) 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-family: "YouSheBiaoTiHei";
          }
        }
      }
    }

    .rl-bo {
      display: flex;
      justify-content: space-between;

      .bo-box {
        background-image: linear-gradient(-180deg, #00b9ff 0%, #0060ff 100%);
        width: 48%;
        text-align: center;
        padding: 3vh 0;
        border-radius: 5px;
        font-size: 18px;

        display: flex;
        justify-content: center;

        img {
          display: block;
          width: 40px;
          height: 40px;
          margin-right: 10px;
        }

        .bo-bnum {
          font-size: 25px;
          color: #fff;
          font-family: "YouSheBiaoTiHei";

          span {
            font-size: 15px;
          }
        }

        .bo-bname {
          font-size: 18px;
          color: #fff;
        }
      }
    }

    .bo-r {
      .r-i {
        background-image: linear-gradient(-180deg, #00b9ff 0%, #0060ff 100%);
        width: 141px;
        height: 144px;
        text-align: center;
        padding: 20px 0;
        margin-bottom: 20px;

        .i-one {
          color: #fff;
          font-size: 20px;

          span {
            font-size: 13px;
          }
        }

        img {
          width: 45px;
          height: 45px;
        }

        .i-name {
          font-size: 0.2125rem;
          color: #fff;
        }
      }
    }

    .co {
      margin-top: 20px;
      display: flex;
      justify-content: space-between;

      .co-box {
        border-radius: 10px;
        width: 48%;
        padding: 1vh 0;
        text-align: center;

        .flex {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        img {
          width: 50px;
          height: 50px;
        }

        font-size: 22px;

        .de-num {
          padding-top: 1vh;
          margin-bottom: 10px;
          color: #29cfa9;
          font-family: "YouSheBiaoTiHei";

          span {
            font-size: 18px;
          }
        }

        .de-name {
          padding-bottom: 1vh;
          color: #fff;
          font-size: 16px;
          margin-bottom: 10px;
        }
      }
    }
  }

  .unit {
    font-size: 18px;
    margin-left: 5px;
    color: #fff;
  }

  .bg2 {
    background-image: linear-gradient(-180deg,
        #f28b00 0%,
        #cb6a00 100%) !important;
  }

  .day {
    background-image: linear-gradient(-180deg,
        #00b9ff 0%,
        #0060ff 100%) !important;
  }
}

.line {
  position: absolute;
  right: 510px;
  top: 0;
  background: #00b9ff;
  width: 1px;
  height: calc(100% - 270px);
}

.boxLeft {
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  width: calc(100% - 510px);
  color: #fff;
  display: flex;
  padding-bottom: 10px;
  border-top: 1px solid #00b9ff;

  .mymon {
    flex: 1;
    height: 250px;
    margin-top: 15px;
    padding-bottom: 30px;

    .mymon-title {
      margin-bottom: 10px;
      color: #fff;
      font-size: 20px;
      text-align: center;
    }
  }
}

.times {
  width: 300px;
  left: 50%;
  margin-left: -150px;
  position: absolute;
  text-align: center;
  font-size: 16px;
}
</style>
