<template>
  <div class="tab-panel" :style="{display: display}">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'TabPanel',
  data() {
    return {
      type: 'TabPanel',
      display: "none"
    }
  },
  props: {
    label: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="less" scoped>
.tab-panel {
  width: 100%;
  display: none;
}
</style>