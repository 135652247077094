import { render, staticRenderFns } from "./top5Score.vue?vue&type=template&id=f6e34504&scoped=true"
import script from "./top5Score.vue?vue&type=script&lang=js"
export * from "./top5Score.vue?vue&type=script&lang=js"
import style0 from "./top5Score.vue?vue&type=style&index=0&id=f6e34504&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f6e34504",
  null
  
)

component.options.__file = "top5Score.vue"
export default component.exports