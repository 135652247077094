<template>
  <div class="number-flip">
    <div 
      v-for="i in numberArr.length" 
      :key="i" 
      :style="{marginRight: i < numberArr.length ? space : undefined}"
    >
      <number-flip-item 
        :value="numberObj[i-1]"
        :font-size="fontSize"
        :font-color="fontColor"
        :bg-color="bgColor"
        :bgImage="bgImage"
        :bg="bg"
      ></number-flip-item>
    </div>
  </div>
</template>

<script>
import NumberFlipItem from "./item.vue";
import numberFlipItemProps from "../../common/js/number-flip-props.js";
export default {
  name: "NumberFlip",
  mixins: [ numberFlipItemProps ],
  components: { NumberFlipItem },
  data() {
    return {
      numberObj: {}
    }
  },
  props: {
    number: {
      type: [Number, String],
      required: true
    },
    space: {
      type: String,
      default: "2px"
    },
    minCount: {
      type: Number,
      default: 0
    }
  },
  watch: {
    number: {
      handler(v) {
        this.numberArr.forEach((i, index) => {
          this.numberObj[index] = i;
        });
      },
      immediate: true
    }
  },
  computed: {
    numberArr() {
      const temp = `${this.number}`.split("");
      for (let i = temp.length; i < this.minCount; i++) {
        temp.unshift("0");
      }
      return temp;
    }
  }
}
</script>

<style lang="less" scoped>
@import "./style.less";
</style>