<template>
  <div class="indexMapnine">
    <div class="mapbox">
      <mapVue class="map"></mapVue>
    </div>
    <div class="box-left">
      <div class="left-box">
        <titleInfo titleCn="发电量" style="margin-left: -15px"></titleInfo>
        <!-- <div class="left-title">发电量</div> -->
        <div class="l-box">
          <dv-border-box-7>
            <div class="l-left">
              <div class="r-list">
                <div class="r-tit">实时功率</div>
                <div class="r-num">
                  <span>
                    <countTo style="font-size: 24px" :startVal="0" :endVal="modedata.currEfficiency
                        ? modedata.currEfficiency * 1
                        : 0
                      " :duration="3000"></countTo>
                  </span>w
                </div>
              </div>
              <div class="r-list">
                <div class="r-tit">当日发电量</div>
                <div class="r-num">
                  <span>
                    <countTo style="font-size: 22px" :startVal="0" :endVal="obj.sumCurrDayPowerNum
                        ? (obj.sumCurrDayPowerNum * 1).toFixed(0)
                        : 0
                      " :duration="3000"></countTo>
                  </span>度
                </div>
              </div>
              <div class="r-list">
                <div class="r-tit">当月发电量</div>
                <div class="r-num">
                  <span>
                    <countTo style="font-size: 20px" :startVal="0" :endVal="obj.sumCurrMonthPowerNum
                        ? (obj.sumCurrMonthPowerNum * 1).toFixed(0)
                        : 0
                      " :duration="3000"></countTo>
                  </span>度
                </div>
              </div>
              <div class="r-list">
                <div class="r-tit">当年发电量</div>
                <div class="r-num">
                  <span>
                    <countTo style="font-size: 18px" :startVal="0" :endVal="obj.sumCurrYearPowerNum
                        ? (obj.sumCurrYearPowerNum * 1).toFixed(0)
                        : 0
                      " :duration="3000"></countTo>
                  </span>度
                </div>
              </div>
            </div>
          </dv-border-box-7>
        </div>
      </div>
      <div class="left-box2">
        <titleInfo titleCn="月电量统计(度)" style="margin-left: -15px"></titleInfo>
        <!-- <div class="left-title">
          月电量统计<span style="font-size: 12px">(度)</span>
        </div> -->
        <div class="l-box2">
          <dv-border-box-7>
            <monthEcharts style="width: 100%; height: 100%"></monthEcharts>
          </dv-border-box-7>
        </div>
      </div>
      <div class="left-box3">
        <titleInfo titleCn="节能减排" style="margin-left: -15px"></titleInfo>
        <div class="l-box">
          <dv-border-box-7 style="heightLauto">
            <div class="twoImgs">
              <div class="item">
                <div class="img">
                  <img src="@/assets/mapNav/icon004.png" alt="" />
                </div>
                <div class="centerText">
                  {{
                    obj.sumCurrYearPowerNum
                      ? 114 * Number(obj.sumCurrYearPowerNum).toFixed(0)
                      : 0
                  }}
                  <span class="unit">t</span>
                </div>
                <div class="lastText">年累计节约标准煤</div>
              </div>
              <div class="item">
                <div class="img">
                  <img src="@/assets/mapNav/icon003.png" alt="" />
                </div>
                <div class="centerText1">
                  {{
                    obj.sumCurrYearPowerNum
                      ? (obj.sumCurrYearPowerNum * 1).toFixed(0)
                      : 0
                  }}
                  <span class="unit">t</span>
                </div>
                <div class="lastText">年累计CO₂减排</div>
              </div>
            </div>
          </dv-border-box-7>
        </div>
      </div>
    </div>
    <div class="box-right">
      <div class="right-box">
        <titleInfo titleCn="收益" style="margin-left: -15px"></titleInfo>
        <div class="l-box">
          <dv-border-box-7>
            <div class="l-right">
              <div class="r-list">
                <div class="r-tit">当日收益</div>
                <div class="r-num">
                  <span>
                    <countTo style="font-size: 24px" :startVal="0" :endVal="obj.sumCurrDayIncome
                        ? Number(obj.sumCurrDayIncome).toFixed(0)
                        : 0
                      " :duration="3000" class="num222"></countTo>
                  </span>元
                </div>
              </div>
              <div class="r-list">
                <div class="r-tit">当月收益</div>
                <div class="r-num">
                  <span>
                    <countTo style="font-size: 22px" :startVal="0" :endVal="obj.sumCurrMonthIncome
                        ? Number(obj.sumCurrMonthIncome).toFixed(0)
                        : 0
                      " :duration="3000" class="num222"></countTo>
                  </span>元
                </div>
              </div>
              <div class="r-list">
                <div class="r-tit">当年收益</div>
                <div class="r-num">
                  <span>
                    <countTo style="font-size: 20px" :startVal="0" :endVal="obj.sumCurrYearIncome
                        ? Number(obj.sumCurrYearIncome).toFixed(0)
                        : 0
                      " :duration="3000" class="num222"></countTo>
                  </span>元
                </div>
              </div>
              <div class="r-list">
                <div class="r-tit">每户平均</div>
                <div class="r-num">
                  <span>
                    <countTo style="font-size: 18px" :startVal="0" :endVal="0" :duration="3000" class="num222">
                    </countTo>
                  </span>元
                </div>
              </div>
            </div>
          </dv-border-box-7>
        </div>
      </div>
      <div class="right-box">
        <div class="left-title">
          <titleInfo titleCn="年电量统计(度)" style="margin-left: -15px"></titleInfo>
        </div>
        <dv-border-box-7 style="height: auto">
          <div class="l-box2">
            <yearEcharts style="width: 100%; height: 100%"></yearEcharts>
          </div>
        </dv-border-box-7>
      </div>
      <div class="right-box" style="margin-bottom: 10px">
        <titleInfo titleCn="逆变器工作实况" style="margin-left: -15px"></titleInfo>
        <div style="margin-top: 10px">
          <dv-border-box-7 style="height: auto">
            <div class="l-box3">
              <nibianqiEcharts style="height: 100%; margin-top: 10px"></nibianqiEcharts>
            </div>
          </dv-border-box-7>
        </div>
      </div>
    </div>
    <timeVue class="times"></timeVue>
  </div>
</template>

<script>
import mapVue from "./components/map.vue";
import monthEcharts from "./components/monthEcharts.vue";
import yearEcharts from "./components/yearEcharts.vue";
import elcData from "./components/elcData.vue";
import timeVue from "./components/time.vue";
import nibianqiEcharts from "./components/nibianqiEcharts.vue";
import titleInfo from "./components/titleInfo.vue";
export default {
  components: {
    mapVue,
    monthEcharts,
    yearEcharts,
    timeVue,
    nibianqiEcharts,
    elcData,
    titleInfo,
  },
  data() {
    return {
      modedata: {},
      numone: [0, 0, 0, 0, 0, 0],
      numtwo: [0, 0, 0, 0, 0, 0],
      completeness: {}, // 完成度
      top5plus: [],
      top5: [],
      count: {},
      co: {},
      efficient: {},
      income: {},
      obj: {},
      workOrderList: [
        {
          name: "通讯中断",
          value: 30,
          icon: require("../../assets/bbar.png"),
          color: "#00b2e6",
        },
        {
          name: "故障停机",
          value: 30,
          icon: require("../../assets/bbar.png"),
          color: "#fe53bd",
        },
        {
          name: "告警运行",
          value: 30,
          icon: require("../../assets/bbar.png"),
          color: "#d81e06",
        },
        // {
        //   name: "正常停机",
        //   value: 30,
        //   icon: require("../../assets/bbar.png"),
        //   color: "#d57200",
        // },
        {
          name: "正常运行",
          value: 70,
          icon: require("../../assets/bbar.png"),
          color: "#3be9bd",
        },
      ],
    };
  },

  created() {
    this.getData();
    this.getData2();
  },

  methods: {
    // 获取数据
    async getData() {
      const res = await this.$http.post("PcRealMonitoring/PCPositionList1", {});
      if (res.data.code === 200) {
        // 电站建设发电量
        this.modedata = res.data.data[0][0];
        // 电站建设完成度
        this.completeness = res.data.data[3][0];

        const colorList = [
          "#f9d071",
          "#fe6dc7",
          "#8400ff",
          "#1fb5fc",
          "#69edd1",
        ];
        res.data.data[1].forEach((item, index) => {
          item.color = colorList[index];
        });
        this.top5 = res.data.data[1];
        this.top5plus = res.data.data[2];
        // this.$refs.RightData.init(this.top5plus);
        // 二氧化碳
        this.co = res.data.data[4][0];
        this.count = res.data.data[7][0];
      } else {
        this.$message.error(res.data);
      }
    },
    async getData2() {
      const res = await this.$http.post(
        "device/queryUserAllStationDailyPower",
        { userCode: JSON.parse(localStorage.getItem("userInfo")).userCode
        // stationCodeList: JSON.parse(localStorage.getItem('stationCodeList')) 
      }
      );
      if (res.data.code === 200) {
        this.obj = res.data.data;
      } else {
        this.$message.error(res.data);
      }
    },

    formatProgress(item) {
      return () => {
        return `${item}条`;
      };
    },
  },
};
</script>

<style scoped lang="less">
html,
body {
  margin: 0;
  padding: 0;
  overflow: hidden;
  background: linear-gradient(to bottom, #051525, #0e2944ed);
}

.indexMapnine {
  position: absolute;
  top: 85px;
  left: 0;
  right: 0;
  bottom: 0;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10000;
  color: white;
  padding: 10px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  pointer-events: none;
}

.eh {
  position: relative;
  width: 100%;
  height: 100%;
}

.left-title {
  color: #26bdea;
  font-size: 20px;
  margin-bottom: 10px;
}

.box-left {
  background-color: rgba(0, 0, 0, 0.76);
  width: 25%;
  position: absolute;
  left: 0;
  bottom: 0;
  top: 0;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  .left-box {
    margin: 0 30px;

    .left-title {
      color: #26bdea;
      font-size: 20px;
    }

    .l-box {
      width: 100%;
      height: 20vh;
      color: #fff;
      font-size: 16px;
      margin-top: 10px;

      .l-left {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 20vh;
        padding: 10px;
        box-sizing: border-box;

        .r-list {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .r-num {
            span {
              font-size: 24px;
              font-family: "YouSheBiaoTiHei";
              margin-right: 5px;
              background-image: linear-gradient(to right,
                  rgba(0, 77, 167, 1) 0%,
                  rgba(0, 244, 255, 1) 100%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              display: inline-block;
            }
          }
        }
      }
    }
  }

  .left-box2 {
    margin: 0 30px;

    .left-title {
      color: #26bdea;
      font-size: 20px;
    }

    .l-box {
      height: 24vh;
      display: flex;
      color: #fff;
      font-size: 16px;
      margin-top: 10px;
    }
  }

  .left-box3 {
    margin: 0 30px;

    .l-box {
      height: 23vh;
      color: #fff;
      font-size: 16px;
      margin-top: 10px;
      margin-bottom: 10px;

      .list {
        display: flex;

        .list1 {
          width: 30%;
          padding-left: 10px;
        }

        .list2 {
          width: 40%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        .list3 {
          width: 30%;
          color: #00ffff;
          text-align: right;
          padding-right: 10px;
        }
      }
    }
  }
}

.box-right {
  background-color: rgba(0, 0, 0, 0.76);
  width: 25%;
  top: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  .right-box {
    margin: 0 30px;

    .left-title {
      color: #26bdea;
      font-size: 20px;
    }

    .l-box {
      width: 100%;
      height: 20vh;
      color: #fff;
      font-size: 16px;
      margin-top: 10px;

      .l-right {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 20vh;
        padding: 10px 20px;
        box-sizing: border-box;

        .r-list {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .r-num {
            span {
              font-size: 24px;
              font-family: "YouSheBiaoTiHei";
              margin-right: 5px;
              background-image: linear-gradient(to right,
                  #eb3b5a 0%,
                  #fe9c5a 100%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              display: inline-block;
            }
          }
        }
      }
    }

    .l-box2 {
      height: 24vh;
      width: 100%;
      color: #fff;
      font-size: 16px;
    }

    .l-box3 {
      height: 21vh;
    }
  }
}

.progress-area {
  padding-left: 10px;
  box-sizing: border-box;
  padding-top: 20px;

  .progress-area-item {
    display: flex;
    align-items: center;

    & {
      margin-bottom: 20px;
    }

    span.label {
      color: #fff;
      padding-right: 8px;
      font-size: 14px;
    }

    .el-progress {
      flex-grow: 1;

      .el-progress__text {
        color: #fff;
        font-size: 16px !important;
      }

      .el-progress-bar__outer {
        // background: #026795;
        border: 1px solid #026795;
        background: transparent;
      }
    }
  }
}

.el-progress-circle {
  height: 80px !important;
  width: 80px !important;
}

.el-progress__text {
  font-size: 16px !important;
  color: #1fb5fc;
}

.month-finish,
.year-finish {
  position: relative;
  margin-top: 20px;
}

.year-finish {
  .el-progress__text {
    font-size: 18px !important;
    color: #e0f27b;
  }
}

.month-finish::after {
  content: "消缺完成率";
  position: absolute;
  color: #fff;
  bottom: -30px;
  left: 50%;
  width: 100%;
  display: block;
  text-align: center;
  transform: translateX(-50%);
}

.year-finish::after {
  content: "年完成度";
  position: absolute;
  color: #fff;
  bottom: -30px;
  left: 50%;
  width: 100%;
  display: block;
  text-align: center;
  transform: translateX(-50%);
}

.mapbox {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;

  .map {
    width: 100%;
    height: 100%;
  }
}

.twoImgs {
  display: flex;
  flex: 1;
  justify-content: space-around;
  width: 100%;
  margin: auto;
  height: 100%;
  align-items: center;
  box-sizing: border-box;

  .item {
    display: flex;
    flex-direction: column;
    color: #fff;
    align-items: center;
    font-size: 14.4px;

    >div {
      .unit {
        padding-left: 4px;
      }
    }

    img {
      height: 80px;
      width: 80px;
      display: block;
      aspect-ratio: 1; // 保持长宽比
    }
  }
}

.centerText {
  font-size: 24px;
  font-family: "YouSheBiaoTiHei";
  background-image: linear-gradient(to right,
      rgba(0, 77, 167, 1) 0%,
      rgba(0, 244, 255, 1) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.centerText1 {
  font-size: 24px;
  font-family: "YouSheBiaoTiHei";
  background-image: linear-gradient(to right,
      rgb(0, 199, 43) 0%,
      rgb(157, 255, 191) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.lastText {
  font-size: 18px;
}

.times {
  width: 300px;
  left: 50%;
  margin-left: -150px;
  position: absolute;
  text-align: center;
}

.theCircle {
  position: relative;
  width: 80px;
  /* 设置圆形容器的宽度 */
  height: 150px;
  /* 设置圆形容器的高度 */
  overflow: hidden;
  /* 确保容器裁剪水波纹效果 */
  border-radius: 10px;

  .Water {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // background-color: #38b973; /* 水波纹的颜色 */
    overflow: hidden;
    z-index: 1;
    /* 确保水波纹在文字之上 */
  }

  .CenteredText {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* 居中显示文字 */
    color: #ffffff;
    /* 文字颜色 */
    z-index: 2;
    /* 确保文字在水波纹之上 */
  }

  .Water::after {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    width: 150%;
    height: 150%;
    border-radius: 40%;
    background-color: #1d2429;
    /* 水波纹内部颜色 */
    animation: real 5s linear infinite;
    /* 实际水波纹的动画效果 */
  }

  .Water::before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    width: 150%;
    height: 150%;
    border-radius: 42%;
    background-color: #1d2429;
    /* 水波纹外部颜色及透明度 */
    animation: virtual 7s linear infinite;
    /* 虚拟水波纹的动画效果 */
  }
}

@keyframes real {
  0% {
    /* 初始状态：向上平移50%、左平移65%并旋转0度 */
    transform: translate(-50%, -65%) rotate(0deg);
  }

  100% {
    /* 终止状态：向上平移50%、左平移65%并旋转360度，形成旋转一周的效果 */
    transform: translate(-50%, -65%) rotate(360deg);
  }
}

@keyframes virtual {
  0% {
    /* 初始状态：向上平移50%、左平移60%，不进行缩放，旋转0度 */
    transform: translate(-50%, -60%) scale(1) rotate(0deg);
  }

  100% {
    /* 终止状态：向上平移50%、左平移60%，进行1.1倍的缩放，旋转360度，
            形成旋转一周的效果并放大水波纹 */
    transform: translate(-50%, -60%) scale(1.1) rotate(360deg);
  }
}
</style>
