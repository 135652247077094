<template>
  <div>
    <div style="height: 100%; width: 100%" id="nibianChart"></div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import { nowSize } from "@/utils/common";
export default {
  props: {
    color: {
      type: String,
      default: "#61dfd1",
    },
  },
  created() {
    this.initMOnthElectricity();
  },
  methods: {
    async initMOnthElectricity() {
      const res = await this.$http.post(
        "/powerStationDeviceSummary/allSummaryByMonths",
        {}
      );
      let xAxisData = [];
      let yAxisData = [];
      if (res.data.code === 200) {
        xAxisData = res.data.data.map((item) => item.date);
        yAxisData = res.data.data.map((item) => item.powerNum.toFixed(0));
      } else {
        this.$message.error(res.data);
      }
      this.nibianChart = echarts.init(document.getElementById("nibianChart"));
      var scaleData = [
        {
          name: "通讯中断",
          value: 12,
        },
        {
          name: "故障停机",
          value: 2,
        },
        {
          name: "告警运行",
          value: 8,
        },
        {
          name: "正常运行",
          value: 3687,
        },
      ];
      var placeHolderStyle = {
        normal: {
          label: {
            show: false,
          },
          labelLine: {
            show: false,
          },
          color: "rgba(0, 0, 0, 0)",
          borderColor: "rgba(0, 0, 0, 0)",
          borderWidth: 0,
        },
      };
      var data = [];
      var color = [
        "rgb(255, 153, 153)",
        "rgb(255, 176, 63)",
        "rgb(61, 186, 45)",
        "rgb(43, 166, 254)",
      ];
      var colorBorder = [
        "rgba(255, 153, 153, 0.4)",
        "rgba(255, 176, 63, 0.4)",
        "rgba(61, 186, 45, 0.4)",
        "rgba(43, 166, 254, 0.4)",
      ];
      for (var i = 0; i < scaleData.length; i++) {
        data.push(
          {
            data: scaleData[i].value,
            value: 20,
            name: scaleData[i].name,
            itemStyle: {
              normal: {
                color: color[i],
                borderWidth: 20,
                borderColor: colorBorder[i],
              },
            },
          },
          {
            value: 8,
            name: "",
            itemStyle: placeHolderStyle,
          }
        );
      }
      data.push({
        value: 40,
        name: "",
        itemStyle: placeHolderStyle,
      });
      var seriesObj = [
        {
          name: "",
          type: "pie",
          clockWise: false,
           radius: [nowSize(40), nowSize(100)],
          hoverAnimation: false,
          center: ['50%', '60%'],
          startAngle: -30,
          itemStyle: {
            normal: {
              label: {
                show: true,
                position: "inner",
                fontSize: nowSize(16),
                lineHeight: 15,
                formatter: function (params) {
                  var percent = 0;
                  var total = 0;
                  for (var i = 0; i < scaleData.length; i++) {
                    total += scaleData[i].value;
                  }
                  percent = ((params.value / total) * 100).toFixed(0);
                  if (params.name !== "") {
                    // return params.name + '\n' + params.data.data;
                    if (params.name.length > 4) {
                      return (
                        params.name.slice(0, 3) + "\n" + params.name.slice(3)
                      );
                    } else {
                      return params.name;
                    }
                  } else {
                    return "";
                  }
                },
              },
              labelLine: {
                length: 10,
                length2:20,
                show: true,
              },
            },
          },
          data: data,
        },
        {
          name: "",
          type: "pie",
          clockWise: false,
          radius: [nowSize(40), nowSize(100)],
          hoverAnimation: false,
          center: ['50%', '60%'],
          startAngle: -30,
          itemStyle: {
            normal: {
              label: {
                show: true,
                position: "outside",
                fontSize: nowSize(16),
                formatter: function (params) {
                  var percent = 0;
                  var total = 0;
                  for (var i = 0; i < scaleData.length; i++) {
                    total += scaleData[i].value;
                  }
                  percent = ((params.value / total) * 100).toFixed(0);
                  if (params.name !== "") {
                    return params.data.data;
                  } else {
                    return "";
                  }
                },
              },
              labelLine: {
                length: 10,
                length2: 20,
                show: true,
              },
            },
          },
          data: data,
        },
      ];
      let option = {
        tooltip: {
          show: false,
        },
        legend: {
          show: false,
        },
        toolbox: {
          show: false,
        },
        grid:{
          top:'25%'
        },
        series: seriesObj,
        // graphic: [
        //   {
        //     type: "group",
        //     left: "center",
        //     top: "middle",
        //   },
        // ],
      };
      this.nibianChart.setOption(option);
      window.onresize = () => {
        if (this.nibianChart) {
          this.nibianChart.resize(); // 调用 ECharts 实例的 resize 方法
        }
      };
    },
  },
};
</script>

<style></style>
