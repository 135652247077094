// import Bar3d from "./bar-3d";
import Gauge from "./gauge";
import Icon from "./icon";
import RightRun from "./right-run";
import Legend from "./legend";
import Line from "./line";
import LineDance from "./line-dance";
import NumberFlip from "./number-flip";
import PictorialBar from "./pictorial-bar";
import PieMulti from "./pie-multi";
import RadarScan from "./radar-scan";
import Rotate from "./rotate";
import ScaleDance from "./scale-dance";
// import CircleTwinkle from "./circle-twinkle";
import SvgDrawLine from "./svg-draw-line";
import Cut3d from "./cut-3d";
import Tabs from "./tabs";

const components = [
  // Bar3d,
  Gauge,
  Icon,
  RightRun,
  Legend,
  Line,
  LineDance,
  NumberFlip,
  PictorialBar,
  PieMulti,
  RadarScan,
  Rotate,
  ScaleDance,
  // CircleTwinkle,
  SvgDrawLine,
  Cut3d,
  Tabs
];

export default {
  install(Vue) {
    components.forEach(item => item.install(Vue));
  }
}
