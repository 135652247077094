<template>
  <div class="gauge" ref="gauge" :style="{width: width, height: height}"></div>
</template>

<script>
import props from "../../common/js/props";
import Util from "../../../utils/util";
export default {
  name: "gauge",
  mixins: [ props ],
  data() {
    return {
      echart: null,
      op: {
        series: {
          type: 'gauge',
          data: [this.data],
          title: {
            color: 'rgb(202, 255, 255)'
          },
          axisLabel: {
            fontSize: 18
          },
          detail: {
            padding: [50, 0, 0, 0]
          },
          splitLine: {
            length: 20
          },
          axisTick: {
            length: 12
          }
        }
      }
    }
  },
  props: {
    min: Number,
    max: Number,
    splitNumber: Number,
    axisLineStyleColor: Array,
    axisLineStyleWidth: Number,
    data: Object,
    titleFontWeight: String,  //normal bold bolder lighter 100 200 300...
    titleFontSize: Number,
    detailFontWeight: String,  //normal bold bolder lighter 100 200 300...
    detailFontSize: Number,
    detailFormatter: [String, Function]
  },
  created() {
    const s = Util.deleteKeysOfValueUndefinedToNew({
      min: this.min,
      max: this.max,
      splitNumber: this.splitNumber,
      axisLine: {
        lineStyle: {
          color: this.axisLineStyleColor,
          width: this.axisLineStyleWidth,
          shadowColor: 'rgba(255, 255, 255, 0.9)',
          shadowBlur: 15
        }
      },
      title: {
        fontWeight: this.titleFontWeight,
        fontSize: this.titleFontSize
      },
      detail: {
        fontWeight: this.detailFontWeight,
        fontSize: this.detailFontSize,
        formatter: this.detailFormatter
      }
    })
    Util.merge(this.op.series, this.series, s);
  },
  mounted() {
    this.echart = this.$Echarts.init(this.$refs.gauge);
    this.echart.setOption(this.op);
  }
}
</script>

<style lang="less">
@import "./style.less";
</style>