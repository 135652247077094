<template>
  <svg :width="width" :height="height" :viewBox="viewBox">
    <line 
      :class="[isAnimation?`svg-draw-line${i+1}`:'']" 
      v-for="(p,i) in points" :key="i" 
      :x1="p[0]" :y1="p[1]" 
      :x2="p[2]" :y2="p[3]" 
      :style="{stroke: color, strokeWidth: lineWidth, 
                strokeDasharray:`${computedLength({x:p[0], y:p[1]}, {x:p[2],y:p[3]})}px, ${computedLength({x:p[0], y:p[1]}, {x:p[2],y:p[3]}) + 300}px`, 
                animationDuration: duration}"
    ></line>
  </svg>
</template>

<script>
export default {
  name: "SvgDrawLine",
  data() {
    return {
      viewBox: `0 0 ${parseInt(this.width)} ${parseInt(this.height)}`
    }
  },
  props: {
    lineWidth: {
      type: String,
      default: "5px"
    },
    color: {
      type: String,
      default: "yellow"
    },
    duration: {
      type: String,
      default: "2s"
    },
    points: {
      type: Array,
      required: true
    },
    width: {
      type: String,
      required: true
    },
    height: {
      type: String,
      required: true
    },
    isAnimation: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    computedLength(p1, p2) {
      return Math.sqrt(Math.abs(p1.x - p2.x) ** 2 + Math.abs(p1.y - p2.y) ** 2);
      // return Math.abs(p1.x - p2.x)
    }
  }
}
</script>

<style lang="less" scoped>
.svg-draw-line1 {
  animation: svg-draw-line-animation1;
}
@keyframes svg-draw-line-animation1 {
  0% {
    stroke-dashoffset: 100%;
  }
  50% {
    stroke-dashoffset: 0%;
  }
}
.svg-draw-line2 {
  animation: svg-draw-line-animation2;
}
@keyframes svg-draw-line-animation2 {
  0% {
    stroke-dashoffset: 100%;
  }
  50% {
    stroke-dashoffset: 100%;
  }
  100% {
    stroke-dashoffset: 0%;
  }
}
</style>