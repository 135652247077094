<script>
import monthEcharts from "../components/monthEcharts.vue";
import titleInfo from "./titleInfo.vue";
import top5Score from "../components/top5Score.vue";
export default {
  components: { top5Score, monthEcharts, titleInfo },
  data() {
    return {
      modedata: {},
      numone: "",
      numtwo: "",
      completeness: {}, // 完成度
      top5plus: [],
      top5: [],
      co: {},
      count: {},
      efficient: {},
      // powerStationCount: {}, // 电站建设发电量
      onlineNumber: 0, //实时功率
      onlineNumber2: 0, //当日发电量
      obj: {}
    };
  },
  created() {
    this.getData();
    this.timer = setTimeout(() => {
      this.reload();
    }, 30 * 1000);
  },
  beforeDestroy() {
    //  在组件销毁之前清除定时器
    if (this.timer) {
      clearTimeout(this.timer);
    }
  },
  mounted() { },
  methods: {
    // 饼图
    initEcharts() {
      this.echartDom = echarts.init(this.$refs.echartDom);
      const data = this.top5plus.map((item) => {
        return { value: item.equivalentHours, name: item.powerStationName };
      });
      const option = {
        tooltip: {
          trigger: "item",
        },
        grid: {
          top: "-50%",
        },
        series: [
          {
            name: "Access From",
            type: "pie",
            radius: "50%",
            data: data,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      };
      this.echartDom.setOption(option);
      window.onresize = () => {
        if (this.echartDom) {
          this.echartDom.resize(); // 调用 ECharts 实例的 resize 方法
        }
      };
    },
    formatProgress(item) {
      return () => {
        return `${item}h`;
      };
    },
    async getData() {
      const res = await this.$http.post("device/queryUserAllStationDailyPower",
        {
          userCode: JSON.parse(localStorage.getItem('userInfo')).userCode
        });
      if (res.data.code === 200) {
        this.obj = res.data.data
      } else {
        this.$message.error(res.data);
      }
    },
  },
};
</script>
<template>
  <div class="left-main">
    <!-- 电站建设 -->
    <div class="partone">
      <titleInfo titleCn="电站建设"></titleInfo>
      <!-- <div class="titleInfo">
        <div class="text-ch">电站建设</div>
     <div class="text-en">Construction</div>
      </div> -->
      <div class="rightBox" style="margin-top:2%">
        <div class="item">
          <img class="img-box" src="../../../assets/mapNav/dianzhan.png" alt="" />
          <div>
            <span class="num"> {{ obj.stationCount ? obj.stationCount * 1 : 0 }}</span>
            <span class="unit">&nbsp;座</span>
            <div class="label">电站数量</div>
          </div>
        </div>
        <div class="item">
          <img class="img-box" src="../../../assets/mapNav/zhuangji.png" alt="" />
          <div>
            <span class="num">
              {{ obj.volumeCount ? obj.volumeCount * 1 : 0 }}</span>
            <span class="unit">&nbsp;kw</span>
            <div class="label">装机容量</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 发电量 -->
    <div class="parttwo">
      <titleInfo titleCn="发电量"></titleInfo>
      <!-- <div class="titleInfo" style="padding-top: 0">
        <div class="text-ch">发电量</div> -->
      <!-- <div class="text-en">Power Generation</div> -->
      <!-- </div> -->
      <div class="rightBox1">
        <el-row>
          <el-col :span="12">
            <div class="item1">
              <div class="num">
                <p>
                  <span>{{
                    obj.sumCurrEfficiency
                      ? (Number(obj.sumCurrEfficiency) / 1000).toFixed(0)
                      : 0
                  }}</span>
                  <span class="unit1">&nbsp;kw</span>
                </p>
              </div>
              <div class="label">实时功率</div>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="item1">
              <div class="num">
                <p>
                  <span>{{
                    obj.sumCurrDayPowerNum ? (obj.sumCurrDayPowerNum * 1).toFixed(0) : 0
                  }}</span><span class="unit1">&nbsp;度</span>
                </p>
              </div>
              <div class="label">当日发电量</div>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="item1">
              <div class="num">
                <p>
                  <span>
                    {{ obj.sumCurrMonthPowerNum ? (obj.sumCurrMonthPowerNum * 1).toFixed(0) : 0 }}</span><span
                    class="unit1">&nbsp;度</span>
                </p>
              </div>
              <div class="label">当月发电量</div>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="item1">
              <div class="num">
                <p>
                  <span>
                    {{ obj.sumCurrYearPowerNum ? (obj.sumCurrYearPowerNum * 1).toFixed(0) : 0 }}</span><span
                    class="unit1">&nbsp;度</span>
                </p>
              </div>
              <div class="label">当年发电量</div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <!-- 综合考评TOP5 -->
    <!-- <div class="partthree">
      <div class="titleInfo" style="padding-top: 0">
        <div class="text-ch">综合考评TOP5</div>
        <div class="text-en">Comprehensive Evaluation</div>
      </div>
      <div class="echarts">
        <top5Score ref="top5Score" class="echarts-main"></top5Score>
      </div>
    </div> -->
    <!-- 月电量统计 -->
    <div class="partfour">
      <titleInfo titleCn="月发电统计(度)"></titleInfo>
      <!-- <div class="titleInfo">
        <div class="text-ch">
          月发电统计<span class="text-cn"></span> <span class="unit"></span>
        </div>
      </div> -->
      <monthEcharts style="height: 90%; width: 100%"></monthEcharts>
    </div>
  </div>
</template>
<style lang="less" scoped>
.left-main {
  height: 100%;

  .partone {
    height: 18vh;
  }

  .parttwo {
    height: 35vh;
  }

  .partthree {
    height: 25vh;
  }

  .partfour {
    height: calc(45vh - 85px);
  }

  .rightBox {
    height: 11vh;
    display: flex;
    font-size: 18px;
    justify-content: space-around;
    align-items: center;

    .item {
      display: flex;
      align-items: center;
      text-align: center;

      .img-box {
        width: 50px;
        height: 50px;
        margin-right: 10px;
      }

      .num {
        font-size: 32px;
        margin-top: 8px;
        font-weight: bold;
        // font-style: italic;
        text-align: center;
        background-image: linear-gradient(to right,
            #2acfc9 0%,
            #0168ac 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        display: inline-block;
        font-family: "YouSheBiaoTiHei";
        margin-bottom: 8px;

        .unit {
          color: #fff;
          padding-left: 1px;
          font-size: 18px;
          margin-left: 3px;
        }
      }

      .label {
        color: #fff;
        font-size: 16px;
        margin-left: 10px;
      }
    }
  }

  .rightBox1 {
    margin: 0 auto;

    .item1 {
      width: 100%;
      // font-family: YouSheBiaoTiHei, serif;
      text-align: center;

      .label {
        font-size: 16px;
        // font-family: 'YouSheBiaoTiHei';
        margin-top: -10px;
      }

      .num {
        position: relative;
        color: #f7fa01;
        margin: 0 auto;
        margin-top: 3vh;
        font-size: 26px;
        font-weight: 600;
        font-family: "YouSheBiaoTiHei";
        flex-direction: column;
        height: 10vh;
        width: 100%;
        background: url("../../../assets/mapNav/bottomCicle.png") center center no-repeat;
        background-size: 20vmin 6vmin;

        &::after {
          content: "";
          position: absolute;
          width: 100%;
          height: 7vh;
          left: 0;
          top: 0;
          background: url("../../../assets/mapNav/rain.png") center center no-repeat;
          background-size: 20vmin 8vmin;
          animation: shake-horizontal 1s linear infinite both;
          z-index: 1;
        }

        .unit1 {
          font-size: 18px;
        }

        >p {
          position: relative;
          // top: -vh;
        }

        @keyframes shake-horizontal {
          0% {
            transform: translateY(0);
          }

          50% {
            transform: translateY(-28px);
          }

          100% {
            transform: translateY(0);
          }
        }
      }

      .elc {
        color: #efb336;
      }
    }
  }

  /deep/.rightBox {
    display: flex;

    .el-progress-circle {
      height: 80px !important;
      width: 80px !important;
    }

    .el-progress__text {
      font-size: 16px !important;
      color: #1fb5fc;
    }
  }

  .month-el {
    margin-left: 40px;
    margin-right: 40px;
    margin-top: 40px;

    .month-ch {
      font-size: 22px;
      color: #02a7f0;
    }

    .month-en {
      font-size: 16px;
      color: #fff;
      margin-left: 16px;
    }
  }

  ::v-deep .rightBox .el-progress__text {
    color: #fff !important;
  }
}
</style>
