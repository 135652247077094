import { render, staticRenderFns } from "./SubMenu.vue?vue&type=template&id=fd38313e&scoped=true"
import script from "./SubMenu.vue?vue&type=script&lang=js"
export * from "./SubMenu.vue?vue&type=script&lang=js"
import style0 from "./SubMenu.vue?vue&type=style&index=0&id=fd38313e&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fd38313e",
  null
  
)

component.options.__file = "SubMenu.vue"
export default component.exports