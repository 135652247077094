import Constant from "./constant.js";
export default {
  /**
   * 将传入的参数合并到obj[0]中
   * @param  {...any} obj []
   */
  merge(...obj) {
    if (obj.length === 0) return;
    return obj.reduce((result, current) => {
      if (current) {
        const keys = Object.keys(current);
        keys.forEach(key => {
          if (Object.prototype.toString.call(current[key]) === "[object Object]" && Object.prototype.toString.call(result[key]) === "[object Object]") {
            result[key] = this.merge(result[key], current[key]);
          } else {
            result[key] = current[key];
          }
        })
      }
      return result;
    }, obj[0]);
  },
  /**
   * 合并object并返回一个新的，不修改传入的参数
   * @param  {...any} obj []
   */
  mergeToNew(...obj) {
    return obj.reduce((result, current) => {
      if (current) {
        const keys = Object.keys(current);
        keys.forEach(key => {
          if (Object.prototype.toString.call(current[key]) === "[object Object]" && Object.prototype.toString.call(result[key]) === "[object Object]") {
            result[key] = this.mergeToNew(result[key], current[key]);
          } else {
            result[key] = current[key];
          }
        })
      }
      return result;
    }, {});
  },
  /**
   * 字符串自动换行
   * @param {*} str 原始字符串
   * @param {*} eachLineNumber  每一行字符串数量 
   */
  autoBreakLine(str, eachLineNumber) {
    if (str) {
      let s = "";
      for (let i = 1, len = str.length; i <= len; i++) {
        s += str.charAt(i - 1);
        if (i % eachLineNumber === 0) {
          s += "\n";
        }
      }
      str = s;
    }
    return str;
  },
  /**
   * 删除key-value对象中value为undefined/null的key
   * @param {Object} obj 
   */
  deleteKeysOfValueUndefinedToNew(obj) {
    if (Object.prototype.toString.call(obj) === "[object Object]") {
      const o = {};
      Object.keys(obj).forEach(k => {
        if (Object.prototype.toString.call(obj[k]) === "[object Object]") o[k] = this.deleteKeysOfValueUndefinedToNew(obj[k]);
        else if (typeof obj[k] !== "undefined" && Object.prototype.toString.call(obj[k]) !== "[object Null]") o[k] = obj[k];
      });
      return o;
    }
    return obj;
  },
  /**
   * 获取一个范围的随机数
   * @param {Number} min 最小值，可以取到
   * @param {Number} max 最大值，不能取到
   */
  getRandomNumber(min, max) {
    return Math.floor(Math.random() * (max - min) + min);
  },
  /**
   * 获取一个长度的随机字符串
   * @param {Number} len 随机字符串的长度
   * @param {String} pool 字符池
   */
  getRandomString(len, pool) {
    if (!pool) pool = Constant.Number_Pool + Constant.Uppercase_Letter_Pool + Constant.Lowercase_Letter_Pool;
    else if (pool === "lower") pool = Constant.Lowercase_Letter_Pool;
    else if (pool === "upper") pool = Constant.Uppercase_Letter_Pool;
    else if (pool === "number") pool = Constant.Number_Pool;
    const max = pool.length;
    let result = "";
    for (let i = 0; i < len; i++) {
      result += pool.charAt(this.getRandomNumber(0, max));
    }
    return result;
  },
  /**
   * 睡眠时间函数
   * @param {Number} ms 毫秒
   */
  sleep(ms) {
    return new Promise(resolve => {
      setTimeout(resolve, ms);
    })
  }
}