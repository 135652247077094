import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import workOrder from "./modules/workOrder";


Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    userName: "",
    loginName: "",
    portalHtml: "",
    menus: []
  },
  mutations: {
    createUserName(state, info) {
      state.userName = info;
    },
    createPortalHtml(state, info) {
      state.portalHtml = info;
    },
    createMenus(state, menus) {
      state.menus = menus;
    }
  },
  getters: {
    getUserName(state) {
      return state.userName;
    },
    getPortalHtml(state) {
      return state.portalHtml;
    }
  },
  actions: {},
  modules: {
    workOrder,
  },
  plugins: [
    createPersistedState({
      paths: ['menus'] // 配置需要持久化的属性
    })
  ]
});
