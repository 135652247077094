<template>
  <div class="home-class">
    <div class="top-class">
      <el-select style="margin-right: 10px" v-model="formInline.powerStationCode" filterable placeholder="请选择电站">
        <el-option v-for="item in powerStationList" :key="item.id" :label="item.powerStationName"
          :value="item.powerStationCode">
        </el-option>
      </el-select>
      <el-cascader placeholder="请选择省市区" filterable v-model="formInline.zone" :options="zoneOptions"
        @change="handleChange">
      </el-cascader>
    </div>
    <div class="power-station">
      <el-drawer :visible.sync="isDown" direction="ttb" :modal="false" :withHeader="false" class="drawer-class">
        <span @click="download">点击下载VLC插件!</span>
      </el-drawer>
      <!-- <img @load="imgload" @emptied="empty" @ended="end" @error="err" @complete="complete" @contextmenu="right($event)"
        v-show="img" :class="{ 'img': img }" src="https:jin88.top/hsxy/img/bg2.png" alt=""> -->
      <!-- <video width="320" height="240" controls>
        <source src="http://114.55.113.180:7788/live/34020000001320000001_34020000001320000001/hls.m3u8"
          type="video/mp4">
      </video> -->
      <div class="flexBox">
        <div class="item-class" v-for="(item, index) in checkArr" :key="index">
          <!-- <video ref="video" id="videoPlayer" controls autoplay></video> -->
          <video-player class="video-player vjs-custom-skin" ref="videoPlayer" :options="playerOptions"></video-player>
          <!-- <video width="320" height="240" controls id="videoPlayer" <source
            src="http://114.55.113.180:7788/live/34020000001320000001_34020000001320000001/hls.m3u8" type="video/mp4">
          </video> -->
          <!-- <object type="application/x-vlc-plugin" :id="'vlc' + index" @error="error($event, index)" events="True"
            width="100%" height="220px" pluginspage="http://www.videolan.org"
            codebase="http://downloads.videolan.org/pub/videolan/vlc-webplugins/2.2.2/npapi-vlc-2.2.2.tar.xz">
            <param name="volume" value="0" />
            <param name="autoplay" value="true" />
            <param name="loop" value="false" />
            <param name="fullscreen" value="true" />
          </object> -->
          <div class="info-class">
            <div class="div1">
              状&emsp;&emsp;态：{{ item.status === 0 ? "正常" : "故障" }}
            </div>
            <div class="div1">装机容量：{{ item.installedCapacity }}</div>
            <div class="div2">投运时间：{{ item.operationTime }}</div>
            <div class="div1">运行时间：{{ item.runningDays }}天</div>
            <div class="div1">联系人：中亮新能源</div>
            <!-- <div class="div1">联系人：{{ item.linkUser }}</div> -->
            <div class="div2">联系方式：{{ item.linkNum }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import flvjs from "flv.js";
import Hls from 'hls.js';
export default {
  data() {
    return {
      isDown: false,
      showDown: false,
      img: true,
      checkArr: [],
      id: "1",
      formInline: {},
      powerStationList: [],
      loadedItems: [], // 存放已加载的监控项数据
      zoneOptions: [
        {
          value: "zhinan",
          label: "安徽省",
          children: [
            {
              value: "shejiyuanze",
              label: "合肥市",
              children: [
                {
                  value: "yizhi",
                  label: "包河区",
                },
                {
                  value: "fankui",
                  label: "瑶海区",
                },
                {
                  value: "xiaolv",
                  label: "蜀山区",
                },
              ],
            },
            {
              value: "daohang",
              label: "六安市",
              children: [
                {
                  value: "cexiangdaohang",
                  label: "舒城县",
                },
                {
                  value: "dingbudaohang",
                  label: "霍山县",
                },
              ],
            },
          ],
        },
        {
          value: "zujian",
          label: "浙江省",
          children: [
            {
              value: "basic",
              label: "杭州市",
              children: [
                {
                  value: "layout",
                  label: " 西湖区",
                },
              ],
            },
          ],
        },
      ],
      // src: 'http://114.55.113.180:7788/live/34020000001320000001_34020000001320000001/hls.m3u8',
      playerOptions: {
        //视频url设置,直播流为例
        sources: [{
          // src: 'https://yunweibang.nengliangkeji.com/live/34020000001320000001_34020000001320000001/hls.m3u8',//视频文件地址
          src: 'http://114.55.113.180:7788/live/34020000001320800003_34020000001320800003/hls.m3u8',//视频文件地址
          // type: 'application/x-mpegURL'//视频类型，这里可以不写，如果写一定要写对，否则会无法播放
        }],
        // 其他设置项
        notSupportedMessage: "此视频暂无法播放，请稍后再试",//提示信息
        autoplay: true,//是否自动播放
        controls: true,//是否显示控制栏
        poster: 'http://path/to/poster.jpg',//视频封面
      }

    };
  },
  created() {
    this.getList();
    this.getPowerStationList();
  },
  mounted() {
    this.isInit = true;
    this.getVideo()
  },

  methods: {
    getVideo() {
      const videoPlayer = document.getElementById('videoPlayer');
      videoPlayer.src = 'm3u8文件的URL';
      videoPlayer.crossOrigin = 'anonymous';
      videoPlayer.addEventListener('loadedmetadata', function () {
        // 在这里添加视频加载完成后的操作
        console.log('pppppppppppoooooooo')
      });
      // const video = this.$refs.video;
      // if (Hls.isSupported()) {
      //   const hls = new Hls();
      //   hls.loadSource('http://114.55.113.180:7788/live/34020000001320000001_34020000001320000001/hls.m3u8');
      //   hls.attachMedia(video);
      //   hls.on(Hls.Events.MANIFEST_PARSED, () => {
      //     video.play();
      //     console.log('0000000000000')
      //   });
      // } else if (video.canPlayType('application/vnd.apple.mpegURL')) {
      //   video.src = 'http://114.55.113.180:7788/live/34020000001320000001_34020000001320000001/hls.m3u8';
      //   video.addEventListener('loadedmetadata', () => {
      //     video.play();
      //   });
      //   console.log('11111111111111')
      // }

      // const video = this.$refs.video;
      // if (Hls.isSupported()) {
      //   const hls = new Hls();
      //   hls.loadSource(this.src);
      //   hls.attachMedia(video);
      //   hls.on(Hls.Events.MANIFEST_PARSED, () => {
      //     video.play();
      //   });
      // } else if (video.canPlayType('application/vnd.apple.mpegURL')) {
      //   video.src = this.src;
      //   video.addEventListener('loadedmetadata', () => {
      //     video.play();
      //   });
      // }
      // let video = document.getElementById('videoPlayer');
      // if (Hls.isSupported()) {
      //   let hls = new Hls();
      //   hls.loadSource('path_to_your_video.m3u8');
      //   hls.attachMedia(video);
      //   hls.on(Hls.Events.MANIFEST_PARSED, function () {
      //     video.play();
      //   });
      //   console.log('oooooooppppppppppp')
      // } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
      //   video.src = 'path_to_your_video.m3u8';
      //   video.addEventListener('loadedmetadata', function () {
      //     video.play();
      //   });
      // }
    },
    async loadItemsSequentially() {
      for (let i = 0; i < this.checkArr.length; i++) {
        await this.loadItem(this.checkArr[i]); // 等待当前监控项加载完成再加载下一个
      }
    },
    // 获取电站列表
    async getPowerStationList() {
      let data = {
        condition: {
          userCode: JSON.parse(localStorage.getItem("userInfo")).userCode
        }
      }
      let res = await this.$http.post("powerStation/list", data)
      if (res.data.code) {
        this.powerStationList = res.data.data.data
      } else {
        this.$message.error(res.data.message);
      }
    },
    async loadItem(item) {
      // 模拟异步加载监控项数据的过程，可以根据实际情况修改
      return new Promise(resolve => {
        setTimeout(() => {
          this.loadedItems.push(item); // 将加载完成的监控项数据添加到 loadedItems 中
          resolve();
        }, 1000); // 模拟每个监控项加载需要的时间，这里设为1秒
      });
    },
    handleChange() {

    },
    getList() {
      this.$http.post("powerStationMonitor/list", {}).then(async (res) => {
        if (res.data.code === 200) {
          this.checkArr = res.data.data.data;
          // this.checkArr = []
          // res.data.data.data.map(item => {
          //   setTimeout(() => {
          //     this.checkArr.push(item)
          //   }, 2000);
          // })
          // console.log(this.checkArr, 128);
          // await this.loadItemsSequentially(); // 调用加载监控项数据的方法
          // this.init();
        } else {
        }
      });
    },
    download() {
      this.isDown = false;
      let a = document.createElement("a");
      a.download = "vlc.exe";
      a.href = "../../assets/vlc-3.0.18-win32.exe";
      a.click();
    },
    init() {
      this.$nextTick(() => {
        //获取dom节点
        for (var i = 0; i < this.checkArr.length; ++i) {
          let str = "vlc" + i;
          var vlc = document.getElementById(str);
          console.log(vlc, str, i);
          var options = new Array("rtsp-tcp");
          var id = vlc.playlist.add(
            this.checkArr[i].palyUrl,
            "fancy name",
            options
          );
          vlc.playlist.playItem(id);
        }
      });
    },
    error(e, index) {
      if (index === 0) {
        this.showDown = true;
        this.$notify({
          title: "提示",
          message: "请点击按钮安装插件后，使用360浏览器极速模式查看视频",
          type: "info",
        });
        this.isDown = true;
      }
    },
  },
  beforeDestroy() { },
};
</script>
<style scoped lang="scss">
.drawer-class {
  text-align: center;
}

/deep/ .el-drawer__open .el-drawer.ttb {
  height: 8% !important;
  padding-top: 20px;
  background: #506274;
  color: #fff;
  font-size: 16px;
}

.home-class {
  background: #223f6c;
  padding: 8px;
  height: 92.9vh;

  .top-class {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 6px 0;

    /deep/ .el-input__inner {
      border: 1px solid rgba(151, 179, 203, 0.55);
      background-color: rgba(255, 255, 255, 0);
      box-sizing: border-box;
      font-family: "Arial", sans-serif;
      color: #f2f2f2;
    }
  }

  .power-station {
    height: 98%;
    width: 100%;
    background: #2d5981;
    padding: 8px;

    .flexBox {
      width: 100%;
      height: 98%;
      overflow: auto;
      display: flex;
      flex-wrap: wrap;

      .item-class {
        height: 38vh;
        width: 24.5%;
        margin-right: 5px;
        background: #223f6c;
        border: 1px solid #449be2;
        border-radius: 10px;
        padding: 7px;
        margin-bottom: 10px;

        .info-class {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;

          .flex {
            display: flex;
            justify-content: space-between;
            padding: 0 20px;
            box-sizing: border-box;
            margin-top: 15px;

            .title2 {
              width: 80px;
              height: 20px;
              line-height: 20px;
              text-align: start;

              .success {
                display: inline-block;
                width: 10px;
                height: 10px;
                background-color: #00ff00;
                border-radius: 50%;
              }

              .error {
                display: inline-block;
                width: 10px;
                height: 10px;
                background-color: #ff0000;
                border-radius: 50%;
              }
            }
          }

          .div1 {
            width: 30%;
            color: #fff;
            font-size: 12px;
            margin: 5px 0px;

            .radio-class {
              width: 5px;
              height: 5px;
              border-radius: 50%;
              color: greenyellow;
            }
          }

          .div2 {
            width: 36%;
            color: #fff;
            font-size: 12px;
            margin: 5px 0px;
          }
        }
      }
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.img {
  animation: fadeIn 2s;
}

.all {
  height: 100vh;
  display: flex;
}

::v-deep .video-js {
  height: 28.5vh;
}

.flexBox {
  width: calc(100% - 230px);
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  height: 100px;

  >div {
    position: relative;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
  }

  video {
    width: 100%;
    height: 100%;
    display: block;
  }

  .video1 {
    width: 100%;
    height: 100vh;
  }

  .video2 {
    width: 50%;
    height: 100vh;
  }

  .video3 {
    width: 50%;
    height: 50vh;
  }

  .video3:nth-child(3) {
    width: 100%;
    height: 50vh;
  }

  .video4 {
    width: 50%;
    height: 50vh;
  }

  .video5,
  .video6 {
    width: calc(100% / 3);
    height: 50vh;
  }

  .video7,
  .video8,
  .video9 {
    width: calc(100% / 3);
    height: calc(100vh / 3);
  }
}

.right {
  width: 230px;
  margin: 0;
  padding: 0;
  height: 100vh;
  border: 1px solid #ccc;
}

li {
  list-style: none;
}

::v-deep .tHead1 {
  th:nth-child(1) {
    .cell::before {
      content: "1/9";
    }

    .cell {
      label {
        display: none;
      }
    }
  }
}

::v-deep .tHead2 {
  th:nth-child(1) {
    .cell::before {
      content: "2/9";
    }

    .cell {
      label {
        display: none;
      }
    }
  }
}

::v-deep .tHead3 {
  th:nth-child(1) {
    .cell::before {
      content: "3/9";
    }

    .cell {
      label {
        display: none;
      }
    }
  }
}

::v-deep .tHead4 {
  th:nth-child(1) {
    .cell::before {
      content: "4/9";
    }

    .cell {
      label {
        display: none;
      }
    }
  }
}

::v-deep .tHead5 {
  th:nth-child(1) {
    .cell::before {
      content: "5/9";
    }

    .cell {
      label {
        display: none;
      }
    }
  }
}

::v-deep .tHead6 {
  th:nth-child(1) {
    .cell::before {
      content: "6/9";
    }

    .cell {
      label {
        display: none;
      }
    }
  }
}

::v-deep .tHead7 {
  th:nth-child(1) {
    .cell::before {
      content: "7/9";
    }

    .cell {
      label {
        display: none;
      }
    }
  }
}

::v-deep .tHead8 {
  th:nth-child(1) {
    .cell::before {
      content: "8/9";
    }

    .cell {
      label {
        display: none;
      }
    }
  }
}

::v-deep .tHead9 {
  th:nth-child(1) {
    .cell::before {
      content: "9/9";
    }

    .cell {
      label {
        display: none;
      }
    }
  }
}

::v-deep .tHead0 {
  .cell {
    label {
      display: none !important;
    }
  }

  th:nth-child(1) {
    .cell::before {
      content: "0/9";
    }
  }
}
</style>
