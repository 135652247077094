<template>
  <div class="indexMapfour">
    <div class="mapbox">
      <mapVue class="map"></mapVue>
    </div>
    <div class="right">
      <div class="top">
        <div class="top-left">
          <div class="top1">
            <div class="title" style="padding-top: 15px">
              <span>
                {{ obj.sumCurrDayPowerNum ? (obj.sumCurrDayPowerNum * 1).toFixed(0) : 0 }} </span>度
            </div>
            <div class="">当日发电量</div>
            <div class="content">
              <el-row>
                <el-col :span="12">
                  <div>
                    <div class="subtitle">
                      <span>{{ obj.sumCurrMonthPowerNum
                        ? Number(obj.sumCurrMonthPowerNum).toFixed(0)
                        : 0 }}</span>度
                    </div>
                    <div class="white">月发电量</div>
                  </div>
                </el-col>
                <el-col :span="12">
                  <div>
                    <div class="subtitle">
                      <span>{{ efficient.equivalentHours
                        ? Number(efficient.equivalentHours)
                        : 0 }} </span>h
                    </div>
                    <div class="white">月有效发电时长</div>
                  </div>
                </el-col>
                <el-col :span="12" style="margin-top: 10px">
                  <div>
                    <div class="subtitle"><span>{{ obj.sumCurrYearPowerNum
                      ? (obj.sumCurrYearPowerNum * 1).toFixed(0)
                      : 0 }}</span>度</div>
                    <div class="white">年发电量</div>
                  </div>
                </el-col>
                <el-col :span="12" style="margin-top: 10px">
                  <div>
                    <div class="subtitle">
                      <span>
                        {{ efficient.yaervalentHours
                          ? Number(efficient.yaervalentHours)
                          : 0 }}</span>h
                    </div>
                    <div class="white">年有效发电时长</div>
                  </div>
                </el-col>
              </el-row>
            </div>
          </div>
          <div class="top2">
            <div class="title" style="padding-top: 10px">
              <span>
                {{ obj.sumCurrDayIncome ? Number(obj.sumCurrDayIncome).toFixed(0) : 0 }}</span>元
            </div>
            <div class="">当日收益</div>
            <div class="content">
              <el-row>
                <el-col :span="12">
                  <div>
                    <div class="subtitle"><span> {{ obj.sumCurrMonthIncome ? Number(obj.sumCurrMonthIncome).toFixed(0) :
                        0}}</span>元</div>
                    <div class="white">当月收益</div>
                  </div>
                </el-col>
                <el-col :span="12">
                  <div>
                    <div class="subtitle"><span>{{ obj.sumCurrYearIncome ? Number(obj.sumCurrYearIncome).toFixed(0) :
                        0}}</span>元</div>
                    <div class="white">当年收益</div>
                  </div>
                </el-col>
              </el-row>
            </div>
          </div>
        </div>
        <div class="top-right">
          <div class="cicle blue">
            <div class="ciclebg"></div>
            <div class="ciclebox">
              <!-- <img src="../../assets/mapNav/state.png" alt="" width="22px" /> -->
              <div class="title">
                <span>{{ obj.volumeCount ? obj.volumeCount * 1 : 0 }}</span>kw
              </div>
              <div class="cicleText">装机</div>
            </div>
          </div>
          <div class="cicle orange">
            <div class="ciclebg"></div>
            <div class="ciclebox">
              <!-- <img src="../../assets/mapNav/four1.png" alt="" width="22px" /> -->
              <div class="title">
                <span>{{ obj.stationCount ? obj.stationCount * 1 : 0 }}</span>座
              </div>
              <div class="cicleText">电站</div>
            </div>
          </div>
          <div class="cicle green">
            <div class="ciclebg"></div>
            <div class="ciclebox">
              <!-- <img src="../../assets/mapNav/four2.png" alt="" width="22px" /> -->
              <div class="title">
                <span>{{ obj.sumCurrEfficiency ? parseInt(obj.sumCurrEfficiency / 1000) : 0 }}</span>kw
              </div>
              <div class="cicleText">功率</div>
            </div>
          </div>
        </div>
      </div>
      <!-- 节能 -->
      <div class="energy">
        <dv-border-box-12>
          <el-row type="flex">
            <el-col :span="12">
              <div class="flex">
                <img src="@/assets/mapNav/icon003.png" alt="" />
                <div>
                  <div class="number">
                    <span>
                      {{ obj.sumCurrYearPowerNum ? (obj.sumCurrYearPowerNum * 1).toFixed(0) : 0 }}</span>t
                  </div>
                  <div class="word">年累计减排CO₂</div>
                </div>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="flex">
                <img src="@/assets/mapNav/icon004.png" alt="" />
                <div>
                  <div class="number">
                    <span>
                      {{ obj.sumCurrYearPowerNum ? 114 * Number(obj.sumCurrYearPowerNum).toFixed(0) : 0 }}</span>t
                  </div>
                  <div class="word">年累计节约标准煤</div>
                </div>
              </div>
            </el-col>
          </el-row>
        </dv-border-box-12>
      </div>
      <div class="ml20 mr20 box5">
        <dv-border-box-12>
          <div class="title">月日均等有效时TOP5</div>
          <div style="height:100%;margin-left:20px">
            <top5House :top5="top5" style="margin-left:20px;height:85%"></top5House>
          </div>
        </dv-border-box-12>
      </div>
    </div>
    <timeVue class="times"></timeVue>
  </div>
</template>

<script>
import mapVue from "./components/map.vue";
import top5House from "./components/top5House.vue";
import timeVue from "./components/time.vue";
export default {
  components: { mapVue, top5House, timeVue },
  data() {
    return {
      modedata: {},
      numone: [0, 0, 0, 0, 0, 0],
      numtwo: [0, 0, 0, 0, 0, 0],
      completeness: {}, // 完成度
      top5plus: [],
      top5: [],
      count: {}, // 装机
      co: {}, // 二氧化碳
      efficient: {}, //有效
      obj: {}
    };
  },
  created() {
    this.getData();
    this.getData2()
  },
  methods: {
    async getData() {
      const res = await this.$http.post("PcRealMonitoring/PCPositionList1", {});
      if (res.data.code === 200) {
        this.numone = Number(res.data.data[0][0].currEfficiency)
          .toFixed(0)
          .toString()
          .split("");
        if (this.numone.length < 8) {
          while (this.numone.length < 8) {
            this.numone.unshift(0);
          }
        }
        this.numtwo = Number(res.data.data[0][0].currDayPowerNum)
          .toFixed(0)
          .toString()
          .split("");
        if (this.numtwo.length < 8) {
          while (this.numtwo.length < 8) {
            this.numtwo.unshift(0);
          }
        }
        // 电站建设发电量
        this.modedata = res.data.data[0][0];
        // // 电站建设完成度
        this.completeness = res.data.data[3][0];
        const colorList = [
          "#f9d071",
          "#fe6dc7",
          "#8400ff",
          "#1fb5fc",
          "#69edd1",
        ];
        res.data.data[1].forEach((item, index) => {
          item.color = colorList[index];
        });

        this.top5 = res.data.data[1];
        this.top5plus = res.data.data[2];
        // this.$refs.top5Score.init(this.top5plus);
        // // 二氧化碳
        this.co = res.data.data[4][0];
        this.count = res.data.data[7][0];
        // 等有效
        this.efficient = res.data.data[5][0];
      } else {
        this.$message.error(res.data);
      }
    },
    async getData2() {
      const res = await this.$http.post(
        "device/queryUserAllStationDailyPower",
        { userCode: JSON.parse(localStorage.getItem("userInfo")).userCode, 
        // stationCodeList: JSON.parse(localStorage.getItem('stationCodeList')) 
      }
      );
      if (res.data.code === 200) {
        this.obj = res.data.data;
      } else {
        this.$message.error(res.data);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.indexMapfour {
  display: flex;
  position: absolute;
  top: 85px;
  left: 0;
  bottom: 0;
  right: 0;
  background: linear-gradient(to bottom, #051525, #0e2944ed);
  background-size: cover;

  .mapbox {
    width: 1300px;
    height: calc(100vh - 85px);

    .map {
      width: 1300px;
      height: 100%;
      border: 1px solid #02a7f0;
    }
  }

  .right {
    width: calc(100vw - 1300px);
    height: calc(100vh - 85px);
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    .top {
      width: 100%;
      display: flex;

      // margin-top: 10px;
      .top-left {
        width: 66%;
        height: 100%;

        .top1 {
          width: 90%;
          height: 240px;
          background: url(../../assets/mapNav/fivebg.png) no-repeat;
          background-size: cover;
          margin-left: 5%;
          margin-bottom: 30px;
          text-align: center;
          color: #daecff;
          font-size: 17px;

          .title {
            font-size: 18px;
            text-align: center;
            color: #daecff;

            span {
              font-family: "YouSheBiaoTiHei";
              color: #02a7f0;
              font-size: 22px;
              font-weight: bold;
              margin-right: 5px;
              display: inline-block;
            }
          }

          .subtitle {
            font-size: 16px;
            text-align: center;
            color: #daecff;

            span {
              font-family: "YouSheBiaoTiHei";
              color: #02a7f0;
              font-size: 20px;
              font-weight: bold;
              margin-right: 5px;
              display: inline-block;
            }
          }

          .content {
            margin-top: 30px;
            text-align: center;

            .white {
              color: #daecff;
              font-size: 16px;
            }
          }
        }

        .top2 {
          width: 90%;
          height: 160px;
          background: url(../../assets/mapNav/fivebg1.png) no-repeat;
          background-size: cover;
          margin-left: 5%;
          margin-top: 2%;
          text-align: center;
          color: #daecff;
          font-size: 17px;

          .title {
            font-size: 18px;
            text-align: center;
            color: #daecff;

            span {
              font-family: "YouSheBiaoTiHei";
              color: #e6a23c;
              font-size: 22px;
              font-weight: bold;
              margin-right: 5px;
              display: inline-block;
            }
          }

          .subtitle {
            font-size: 16px;
            text-align: center;
            color: #daecff;

            span {
              font-family: "YouSheBiaoTiHei";
              color: #e6a23c;
              font-size: 20px;
              font-weight: bold;
              margin-right: 5px;
              display: inline-block;
            }
          }

          .content {
            margin-top: 30px;
            text-align: center;

            .white {
              color: #daecff;
              font-size: 16px;
            }
          }
        }
      }

      .top-right {
        width: 34%;

        .cicle {
          text-align: center;
          width: 140px;
          height: 140px;
          margin: 0 auto;
          color: #fff;
          font-size: 12px;
          margin-bottom: 8px;
          position: relative;

          .ciclebox {
            padding-top: 50px;

            .title {
              margin-bottom: 5px;
            }

            .cicleText {
              font-size: 16px;
            }

            span {
              font-family: "YouSheBiaoTiHei";
              color: #ff0;
              font-size: 20px;
              font-weight: bold;
              margin-right: 5px;
              display: inline-block;
            }

            &.blue span {
              color: #02a7f0;
            }

            &.orange span {
              color: orange;
            }

            &.green span {
              color: #09fcbb;
            }
          }

          .ciclebg {
            width: 140px;
            height: 140px;
            background: url(../../assets/mapNav/5.png);
            background-size: 140px 140px;
            animation: circle 4s infinite linear;
            position: absolute;
          }

        }
      }
    }

    .energy {
      background-size: cover;
      text-align: center;
      margin: 0 20px;
      color: #fff;

      .flex {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px 0;

        img {
          display: block;
          width: 50px;
          height: 50px;
          margin-right: 20px;
        }
      }

      .number {
        padding-top: 2vh;
        font-size: 18px;

        span {
          font-family: "YouSheBiaoTiHei";
          color: #09fcbb;
          font-size: 22px;
          margin-right: 5px;
          font-weight: bold;
        }
      }

      .word {
        padding-bottom: 2vh;
        margin-top: 5px;
        font-size: 16px;
      }
    }

    .flex {

      // display: flex;
      .title {
        text-align: center;
        color: #02a7f0;
        margin-bottom: 10px;
        padding-top: 0px;
      }
    }
  }

  //   ::v-deep .dv-border-box-11{

  // }
}

.ml20 {
  margin-left: 20px;
}

.mr20 {
  margin-right: 20px;
}

.mt10 {
  margin-top: 10px;
}

.times {
  top: 20px;
  position: absolute;
  right: 20px;
}

.box5 {
  height: 31vh;

  // margin-top: 10px;
  .title {
    width: 100%;
    text-align: center;
    color: #02a7f0;
    padding: 10px 0 10px;
    box-sizing: border-box;
    font-size: 20px;
  }
}

@keyframes circle {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(-360deg);
  }
}
</style>
