export const optionMonth = {
  grid: {
    left: "3%",
    right: "4%",
    bottom: "8%",
    top: "5%",
  }, //,
  tooltip: {
    trigger: "axis",
    axisPointer: {
      // 坐标轴指示器，坐标轴触发有效
      type: "shadow", // 默认为直线，可选为：'line' | 'shadow' 45f9d6
    },
  },
  xAxis: {
    type: "category",
    show: true,
    data: [
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月",
    ],
    axisTick: {
      show: true,
    },
    axisLabel: {
      show: true,
      interval: 0,
      color: "#d7d7d7",
      lineStyle: {
        color: "#31445A",
        width: 1,
        type: "solid",
      },
    },
  },
  yAxis: {
    splitLine: {
      //设置坐标轴内的背景网格线
      show: true, //显示背景网格线，默认为false
      lineStyle: {
        //设置背景网格线的样式
        color: "#4F5770", //设置线的颜色
      },
    },
    type: "value",
    axisLine: {
      show: true,
      colorL: "#31445A",
    },
    axisTick: {
      show: true,
    },
    axisLabel: {
      show: true,
      color: "#d7d7d7",
      lineStyle: {
        color: "#31445A",
        width: 1,
        type: "solid",
      },
    },
  },

  series: [
    {
      data: [20, 30, 40, 50, 60, 70, 60, 50, 40, 30, 25, 20],
      type: "bar",
      itemStyle: {
        color: "#45f9d6",
      },
      barWidth: "7px", //设置柱状图宽度
    },
  ],
};

export const optionYear = {
  grid: {
    x: 40,
    y: 10,
    x2: 10,
    y2: 20,
  }, //,
  tooltip: {
    trigger: "axis",
    axisPointer: {
      // 坐标轴指示器，坐标轴触发有效
      type: "shadow", // 默认为直线，可选为：'line' | 'shadow' 45f9d6
    },
  },
  xAxis: {
    type: "category",
    show: true,
    data: [
      "2017年",
      "2018年",
      "2019年",
      "2020年",
      "2021年",
      "2022年",
      "2023年",
      "2024年",
    ],
    axisTick: {
      show: true,
    },
    axisLabel: {
      show: true,
      interval: 0,
      color: "#d7d7d7",
      lineStyle: {
        color: "#31445A",
        width: 1,
        type: "solid",
      },
    },
  },
  yAxis: {
    splitLine: {
      //设置坐标轴内的背景网格线
      show: true, //显示背景网格线，默认为false
      lineStyle: {
        //设置背景网格线的样式
        color: "#4F5770", //设置线的颜色
      },
    },
    type: "value",
    axisLine: {
      show: true,
      colorL: "#31445A",
    },
    axisTick: {
      show: true,
    },
    axisLabel: {
      show: true,
      color: "#d7d7d7",
      lineStyle: {
        color: "#31445A",
        width: 1,
        type: "solid",
      },
    },
  },

  series: [
    {
      data: [20, 30, 40, 50, 60, 70, 60, 30, 25, 20],
      type: "bar",
      itemStyle: {
        color: "#45f9d6",
      },
      barWidth: "7px", //设置柱状图宽度
    },
  ],
};
