<template>
  <div class="fixed">
    <!-- <el-button
      type="primary"
      @click="up"
      plain
      round
      style="color: #fff; background: none"
      >选择地图</el-button
    > -->
    <el-dialog
      title="选择地图导航样式"
      :visible.sync="dialogVisible"
      width="50%"
      custom-class="addMicroDialog"
      append-to-body
    >
      <div
        style="
          display: flex;
          flex-wrap: wrap;
          overflow: auto;
          overflow-y: scroll;
          scrollbar-width: none; /* Firefox */
          -ms-overflow-style: none; /* IE and Edge */
        "
      >
        <div
          @click="gomap(item)"
          class="map_index"
          v-for="(item, index) in maplist"
          :key="index"
        >
          <img class="imgs" :src="item.url" alt="" />
          <div class="name">{{ item.name }}</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      maplist: [
        {
          url: require("../../../assets/mapImg/1.png"),
          name: "地图导航（1）",
          path: "/map",
        },
        {
          url: require("../../../assets/mapImg/2.png"),
          name: "地图导航（2）",
          path: "/mapone",
        },
        {
          url: require("../../../assets/mapImg/6.png"),
          name: "地图导航（3）",
          path: "/mapfive",
        },
        {
          url: require("../../../assets/mapImg/4.png"),
          name: "地图导航（4）",
          path: "/mapthree",
        },
        {
          url: require("../../../assets/mapImg/5.png"),
          name: "地图导航（5）",
          path: "/mapfour",
        },
        {
          url: require("../../../assets/mapImg/3.png"),
          name: "地图导航（6）",
          path: "/maptwo",
        },
        {
          url: require("../../../assets/mapImg/7.png"),
          name: "地图导航（7）",
          path: "/mapsix",
        },
        {
          url: require("../../../assets/mapImg/8.png"),
          name: "地图导航（8）",
          path: "/mapseven",
        },
        // {
        //   url: require("../../../assets/mapImg/9.png"),
        //   name: "地图导航（9）",
        //   path: "/mapeight",
        // },
        {
          url: require("../../../assets/mapImg/9.png"),
          name: "地图导航（9）",
          path: "/mapnine",
        },
      ],
      dialogVisible: false,
    };
  },
  methods: {
    up() {
      this.dialogVisible = true;
      
    },
    gomap(item) {
      this.$emit('getMapValue',item.name)
      this.$router.push({ path: item.path });
      this.dialogVisible = false;
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .el-dialog__wrapper {
  z-index: 99999 !important;
}

.name {
  width: 100%;
  text-align: center;
}
.map_index {
  width: 32%;
  margin: 5px;
  .imgs {
    width: 100%;
    height: 170px;
    // margin-bottom: 5px;
  }
}

::v-deep .el-dialog__body {
  padding: 0px 20px !important;
}
</style>
>
