<template>
  <div class="indexMapsix">
    <div class="mapbox">
      <mapVue class="map"></mapVue>
    </div>
    <div class="box">
      <div class="box-right">
        <div class="r-top">
          <div class="bo-l">
            <div class="gl-one">
              <titleInfo titleCn="当日发电量"></titleInfo>
              <!-- <div class="on-left">当日发电量</div> -->
              <div class="on-right">
                <number-flip style="margin: 6px; z-index: 5; position: relative" :number="onlineNumber2"
                  :fontSize="fontSize" font-color="#fff" :bgImage="[
                    '-webkit-linear-gradient(#1c7cdf, #67aef7)',
                    '-webkit-linear-gradient(#67aef7, #1c7cdf)',
                  ]" :min-count="8" space="4px"></number-flip>
                <div style="color: #fff; height: 58px; line-height: 80px">
                  度
                </div>
              </div>
            </div>
            <div class="suj">
              <div class="suj-l">
                <div class="su">
                  <span>
                    <countTo :startVal="0" :endVal="obj.sumCurrMonthPowerNum
                        ? Number(obj.sumCurrMonthPowerNum).toFixed(0)
                        : 0
                      " :duration="3000" class="num222"></countTo>
                  </span>度
                </div>
                <div class="sud">月发电量</div>
              </div>
              <div class="suj-l">
                <div class="su">
                  <countTo :startVal="0" :endVal="efficient.equivalentHours
                      ? Number(efficient.equivalentHours)
                      : 0
                    " :duration="3000" class="num222"></countTo>h
                </div>
                <div class="sud">月日均等有效时</div>
              </div>
              <div class="suj-l">
                <div class="su">
                  <countTo :startVal="0" :endVal="obj.sumCurrYearPowerNum
                      ? Number(obj.sumCurrYearPowerNum).toFixed(0)
                      : 0
                    " :duration="3000" class="num222"></countTo>度
                </div>
                <div class="sud">年发电量</div>
              </div>
              <div class="suj-l">
                <div class="su">
                  <countTo :startVal="0" :endVal="efficient.yaervalentHours
                      ? Number(efficient.yaervalentHours)
                      : 0
                    " :duration="3000" class="num222"></countTo>度
                </div>
                <div class="sud">年日均等有效时</div>
              </div>
            </div>
            <div class="gl-one">
              <titleInfo titleCn="当日收益"></titleInfo>
              <div class="on-right">
                <number-flip style="margin: 6px; z-index: 5; position: relative" :number="incomelist"
                  :fontSize="fontSize" font-color="#fff" :bgImage="[
                    '-webkit-linear-gradient(#1a9d75, #57cc8b)',
                    '-webkit-linear-gradient(#57cc8b, #1a9d75)',
                  ]" :min-count="8" space="4px"></number-flip>
                <div style="color: #fff; height: 58px; line-height: 80px">
                  元
                </div>
              </div>
            </div>
            <div class="suj priceBox" style="margin-top: 0.25rem">
              <div class="suj-l">
                <div class="su"><span style="color: #29cfa9">{{ obj.sumCurrMonthIncome ?
                  Number(obj.sumCurrMonthIncome).toFixed(0) : 0}}</span>元</div>
                <div class="sud">月收益</div>
              </div>
              <div class="suj-l">
                <div class="su"><span style="color: #29cfa9">{{ obj.sumCurrYearIncome ?
                  Number(obj.sumCurrYearIncome).toFixed(0) : 0}}</span>元</div>
                <div class="sud">年收益</div>
              </div>
            </div>
          </div>
          <div class="bo-r">
            <div class="r-i">
              <dv-border-box-7 :color="['#178bc5']">
                <div class="i-one">
                  {{ obj.volumeCount ? obj.volumeCount * 1 : 0 }}
                  <span>kw</span>
                </div>
                <img src="../../assets/six-one.png" alt="" />
                <div class="i-name">装机容量</div>
              </dv-border-box-7>
            </div>
            <div class="r-i r-i2">
              <dv-border-box-7 :color="['#178bc5']">
                <div class="i-one">
                  {{ obj.stationCount ? obj.stationCount * 1 : 0 }}
                  <span>座</span>
                </div>
                <img src="../../assets/six-two.png" alt="" />
                <div class="i-name">电站数量</div>
              </dv-border-box-7>
            </div>
            <div class="r-i r-i3">
              <dv-border-box-7 :color="['#178bc5']">
                <div class="i-one">
                  <countTo :startVal="0" :endVal="0" :duration="3000" class="num222"
                    style="color: #fff; font-size: 20px"></countTo>
                  <span>元</span>
                </div>
                <img src="../../assets/six-three.png" alt="" />
                <div class="i-name">户平均收益</div>
              </dv-border-box-7>
            </div>
          </div>
        </div>
        <div class="wat">
          <titleInfo titleCn="逆变器工作实况"></titleInfo>
          <div class="cicle">
            <div class="round">
              <img src="../../assets/mapNav/map5.png" alt="" />
              <div class="num1">
                <div class="number">3687</div>
                <div>正常运行</div>
              </div>
            </div>
            <div class="round">
              <img src="../../assets/mapNav/map6.png" alt="" />
              <div class="num1">
                <div class="number">8</div>
                <div>告警运行</div>
              </div>
            </div>
            <div class="round">
              <img src="../../assets/mapNav/map7.png" />
              <div class="num1">
                <div class="number">2</div>
                <div>故障停机</div>
              </div>
            </div>
            <div class="round">
              <img src="../../assets/mapNav/map8.png" />
              <div class="num1">
                <div class="number">12</div>
                <div>通讯中断</div>
              </div>
            </div>
          </div>
        </div>
        <div class="co">
          <div>
            <dv-border-box-8>
              <div class="co-box">
                <img src="@/assets/mapNav/icon004.png" alt="" />
                <div class="co-de">
                  <div class="de-num">
                    {{ obj.sumCurrYearPowerNum ? (obj.sumCurrYearPowerNum * 1).toFixed(0) : 0 }}
                    <span>t</span>
                  </div>
                  <div class="de-name">年累计减排CO2</div>
                </div>
              </div>
            </dv-border-box-8>
          </div>
          <div>
            <dv-border-box-8>
              <div class="co-box">
                <img src="@/assets/mapNav/icon003.png" alt="" />
                <div class="co-de">
                  <div class="de-num">
                    {{ obj.sumCurrYearPowerNum ? 114 * Number(obj.sumCurrYearPowerNum).toFixed(0) : 0 }}
                    <span>t</span>
                  </div>
                  <div class="de-name">年累计节约标准煤</div>
                </div>
              </div>
            </dv-border-box-8>
          </div>
        </div>
      </div>
    </div>
    <timeVue class="times"></timeVue>
  </div>
</template>

<script>
import mapVue from "./components/map.vue";
import PieEcharts from "./components/pieEcharts.vue";
import timeVue from "./components/time.vue";
import titleInfo from "./components/titleInfo.vue";
import { nowSize } from "@/utils/common";
export default {
  components: {
    mapVue,
    timeVue,
    titleInfo,
    PieEcharts,
  },
  data() {
    return {
      modedata: {},
      numone: [0, 0, 0, 0, 0, 0],
      // numtwo: [0, 0, 0, 0, 0, 0],
      incomelist: [0, 0, 0, 0, 0, 0],
      completeness: {}, // 完成度
      top5plus: [],
      top5: [],
      count: {}, // 装机
      co: {}, // 二氧化碳
      efficient: {}, //有效
      workOrderList: [
        {
          name: "通讯中断",
          value: 30,
          icon: require("../../assets/bbar.png"),
          color: "#00b2e6",
        },
        {
          name: "故障停机",
          value: 30,
          icon: require("../../assets/bbar.png"),
          color: "#fe53bd",
        },
        {
          name: "告警运行",
          value: 30,
          icon: require("../../assets/bbar.png"),
          color: "#d81e06",
        },
        // {
        //   name: "正常停机",
        //   value: 30,
        //   icon: require("../../assets/bbar.png"),
        //   color: "#d57200",
        // },
        {
          name: "正常运行",
          value: 70,
          icon: require("../../assets/bbar.png"),
          color: "#3be9bd",
        },
      ], //
      onlineNumber: 0, //实时功率
      onlineNumber2: 0, //当日发电量
      fontSize: 0,
      obj: {}
    };
  },

  created() {
    this.fontSize = nowSize(56) + "px";
    this.getData();
    this.getData2()
  },

  methods: {
    formatProgress(item) {
      return () => {
        return `${item}h`;
      };
    },
    // 获取数据
    async getData() {
      const res = await this.$http.post("PcRealMonitoring/PCPositionList1", {});
      if (res.data.code === 200) {
        this.onlineNumber = (
          Number(res.data.data[0][0].currEfficiency) / 1000
        ).toFixed(0);
        this.onlineNumber2 = res.data.data[0][0].currDayPowerNum;
        this.numone = Number(res.data.data[0][0].currEfficiency)
          .toFixed(0)
          .toString()
          .split("");
        if (this.numone.length < 8) {
          while (this.numone.length < 8) {
            this.numone.unshift(0);
          }
        }
        this.numtwo = Number(res.data.data[0][0].currDayPowerNum)
          .toFixed(0)
          .toString()
          .split("");
        if (this.numtwo.length < 8) {
          while (this.numtwo.length < 8) {
            this.numtwo.unshift(0);
          }
        }
        this.incomelist = res.data.data[0][0].currDayIncome;
        // this.incomelist = Number(res.data.data[0][0].currDayIncome)
        //   .toFixed(0)
        //   .toString()
        //   .split("");
        // if (this.incomelist.length < 8) {
        //   while (this.incomelist.length < 8) {
        //     this.incomelist.unshift(0);
        //   }
        // }
        // 电站建设发电量
        this.modedata = res.data.data[0][0];
        console.log(this.modedata, 502);
        // // 电站建设完成度
        this.completeness = res.data.data[3][0];
        const colorList = [
          "#f9d071",
          "#fe6dc7",
          "#8400ff",
          "#1fb5fc",
          "#69edd1",
        ];
        res.data.data[1].forEach((item, index) => {
          item.color = colorList[index];
        });

        this.top5 = res.data.data[1];
        // this.top5plus = res.data.data[2];

        //二氧化碳
        this.co = res.data.data[4][0];
        // 装机容量
        this.count = res.data.data[7][0];
        // 等有效
        this.efficient = res.data.data[5][0];
      } else {
        this.$message.error(res.data);
      }
    },
    async getData2() {
      const res = await this.$http.post(
        "device/queryUserAllStationDailyPower",
        { userCode: JSON.parse(localStorage.getItem("userInfo")).userCode
        // stationCodeList: JSON.parse(localStorage.getItem('stationCodeList')) 
      }
      );
      if (res.data.code === 200) {
        this.obj = res.data.data;
      } else {
        this.$message.error(res.data);
      }
    },
  },
};
</script>

<style scoped lang="less">
html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: hidden;
}

.indexMapsix {
  position: absolute;
  top: 85px;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to bottom, #051525, #0e2944ed);

  .wat {
    margin-top: -12vh;
    // height: 22vh;
  }

  .cicle {
    margin-top: -3vh;
    margin-left: 20px;
    height: 10vh;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .round {
      width: 100%;
      text-align: center;
      position: relative;
      color: #d6f2ff;

      // background: url("../../assets/mapNav/map.png") no-repeat center center;
      img {
        position: absolute;
        // top: 25px;
        left: 50%;
        margin-left: -55px;
        width: 110px;
        display: block;
        animation: circle 4s infinite linear;
      }

      .num1 {
        position: absolute;
        width: 100%;
        text-align: center;

        .number {
          font-size: 28px;
          color: #e6db3c;
          font-family: "YouSheBiaoTiHei";
          margin-bottom: 10px;
        }
      }
    }
  }
}

.overlay {
  color: white;
  padding: 10px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  pointer-events: none;
}

.box {
  width: 700px;
  height: 100%;
  position: absolute;
  z-index: 999;
  right: 0;
  direction: rtl;
  font-size: 18px;
  display: flex;
  justify-content: space-around;

  .box-right {
    background-color: rgba(0, 0, 0, 0.76);
    height: 100%;
    width: 700px;
    padding-right: 15px;
    box-sizing: border-box;
    direction: ltr;
    display: flex;
    overflow: scroll;
    flex-direction: column;
    justify-content: space-between;

    .r-top {
      display: flex;
      justify-content: space-between;
    }

    .bo-l {
      .gl-one {
        margin-bottom: 10px;

        .on-left {
          color: #fff;
        }

        .on-right {
          display: flex;
          justify-content: center;

          .num {
            display: flex;

            .num-l {
              background-image: linear-gradient(-180deg,
                  #f89000 0%,
                  #c46300 100%);
              width: 36px;
              height: 58px;
              line-height: 58px;
              text-align: center;
              margin-right: 10px;
              color: #fff;
              font-size: 25px;
            }
          }
        }
      }

      .suj {
        display: flex;
        flex-wrap: wrap;
        margin-left: 20px;

        .suj-l {
          width: 50%;
          color: #fff;
          text-align: center;

          .su {
            margin-bottom: 6px;

            span {
              color: #1ea5e6;
              font-size: 26px;
              font-family: "YouSheBiaoTiHei";
              margin-right: 5px;
            }
          }
        }
      }
    }

    .bo-r {
      margin-top: 10px;

      .r-i2 {
        background: rgba(0, 0, 0, 0.1);
      }

      .r-i3 {
        background: rgba(0, 0, 0, 0.1);
      }

      .r-i {
        background: rgba(0, 0, 0, 0.1);
        width: 160px;
        height: 150px;
        text-align: center;
        padding-top: 15px;
        margin-bottom: 10px;

        .i-one {
          padding-top: 10px;
          color: #bfe9ff;
          font-size: 26px;
          font-family: "YouSheBiaoTiHei";

          span {
            font-size: 18px;
          }
        }

        img {
          width: 45px;
          height: 45px;
        }

        .i-name {
          font-size: 17px;
          color: #bfe9ff;
        }
      }
    }

    .co {
      display: flex;
      justify-content: center;
      margin-left: 20px;
      margin-bottom: 10px;

      >div {
        flex: 1;
      }

      .co-box {
        padding: 2vh 0;
        display: flex;
        border-radius: 5px;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;

        img {
          width: 60px;
          height: 60px;
          margin-right: 10px;
        }

        .co-de {
          font-size: 20px;

          .de-num {
            text-align: center;
            margin-bottom: 10px;
            color: #29cfa9;
            font-size: 24px;
            font-family: "YouSheBiaoTiHei";

            span {
              font-size: 18px;
            }
          }

          .de-name {
            color: #fff;
            font-size: 16px;
          }
        }
      }
    }
  }
}

.mapbox {
  width: 100vw;
  height: calc(100vh - 85px);
  position: absolute;

  .map {
    width: 100%;
    height: 100%;
  }
}
</style>
