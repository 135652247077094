<template>
  <div class="clock" :class="isclassName ? 'blue' : 'orange'">
    <div class="flip">
      <div class="digital front" :data-number="nextNumberArr[0]"></div>
      <div class="digital back" :data-number="numberArr[0]"></div>
    </div>
    <div class="flip">
      <div class="digital front" :data-number="nextNumberArr[1]"></div>
      <div class="digital back" :data-number="numberArr[1]"></div>
    </div>
    <div class="flip">
      <div class="digital front" :data-number="nextNumberArr[2]"></div>
      <div class="digital back" :data-number="numberArr[2]"></div>
    </div>
    <div class="flip">
      <div class="digital front" :data-number="nextNumberArr[3]"></div>
      <div class="digital back" :data-number="numberArr[3]"></div>
    </div>
    <div class="flip">
      <div class="digital front" :data-number="nextNumberArr[4]"></div>
      <div class="digital back" :data-number="numberArr[4]"></div>
    </div>
    <div class="flip">
      <div class="digital front" :data-number="nextNumberArr[5]"></div>
      <div class="digital back" :data-number="numberArr[5]"></div>
    </div>
    <div class="flip">
      <div class="digital front" :data-number="nextNumberArr[6]"></div>
      <div class="digital back" :data-number="numberArr[6]"></div>
    </div>
    <div class="flip">
      <div class="digital front" :data-number="nextNumberArr[7]"></div>
      <div class="digital back" :data-number="numberArr[7]"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ClockData",
  props: {
    number: {
      type: String,
      default: "00000000",
    },
    isclassName: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      duration: 650,
      numberArr: [],
      nextNumberArr: [],
      timer: {},
    };
  },
  watch: {
    // number(newValue, oldValue) {
    //   this.lastActiveItem = oldValue;
    // },
  },
  created(){
  },
  mounted() {
    this.updateTime()
    this.timer = setInterval(() => {
      this.updateTime();
    }, 3000);
  },
  methods: {
    updateTime() {
      let numberArr = this.getTimeFromDate(this.number);
      let nextNumberArr = [1, 1, 1, 1, 1, 1, 1, 1];
      for (let i = 0; i < 8; i++) {
        if (numberArr[i] !== nextNumberArr[i]) {
          this.setSpin(i, numberArr[i], nextNumberArr[i]);
        }
      }
    },
    setSpin(index, nowTime, nextTime) {
      let nodes = document.querySelectorAll(".flip");
      nodes[index].classList.add("running");
      this.numberArr.splice(index, 1, nowTime);
      this.nextNumberArr.splice(index, 1, nextTime);
      setTimeout(() => {
        nodes[index].classList.remove("running");
        this.numberArr.splice(index, 1, nextTime);
      }, this.duration);
    },
    getTimeFromDate(value) {
      let numVal = [];
      let valueStr = value.toString().slice(0, 8).split(":").join("");
      for (let i = 0; i < valueStr.length; i++) {
        numVal.push(parseInt(valueStr[i]));
      }
      return numVal;
    },
  },
  destroyed() {
    // 销毁定时器
    clearInterval(this.timer);
  },
};
</script>
<style lang="less" scoped>
.clock {
  display: flex;
}
.clock .flip {
  position: relative;
  width: 37px;
  height: 70px;
  margin-right: 5px;
  font-size: 30px;
  line-height: 70px;
  text-align: center;
  border-radius: 4px;
}
.clock.blue .flip {
    background-image: linear-gradient(
      90deg,
      #008bff 0%,
      #00e3ff 99.01715808762268%
    );
}
.clock.orange .flip {
    background-image: linear-gradient(-180deg, #f89000 0%, #c46300 100%);
}
.clock.blue .flip .digital::before,.clock.blue .flip .digital::after {
  position: absolute;
  content: attr(data-number);
  left: 0;
  right: 0;
  color: white;
  background-image: linear-gradient(
    90deg,
    #008bff 0%,
    #00e3ff 99.01715808762268%
  );
  overflow: hidden;
  -webkit-perspective: 160px;
  perspective: 160px;
}
.clock.orange .flip .digital::before,.clock.orange .flip .digital::after {
  position: absolute;
  content: attr(data-number);
  left: 0;
  right: 0;
  color: white;
  background-image: linear-gradient(-180deg, #f89000 0%, #c46300 100%);
  overflow: hidden;
  -webkit-perspective: 160px;
  perspective: 160px;
}
.clock .flip .digital::before {
  top: 0;
  bottom: 50%;
  border-bottom: 1px solid #666;
  border-radius: 10px 10px 0 0;
}
.clock .flip .digital::after {
  top: 50%;
  bottom: 0;
  line-height: 0;
  border-radius: 0 0 10px 10px;
}
.clock .flip .back::before,
.clock .flip .front::after {
  z-index: 1;
}
.clock .flip .back::after {
  z-index: 2;
}
.clock .flip .front::before {
  z-index: 3;
}
.clock .flip .back::after {
  -webkit-transform-origin: center top;
  transform-origin: center top;
  -webkit-transform: rotateX(0.5turn);
  transform: rotateX(0.5turn);
}
.clock .flip.running .front::before {
  -webkit-transform-origin: center bottom;
  transform-origin: center bottom;
  -webkit-animation: frontFlipDown 0.6s ease-in-out;
  animation: frontFlipDown 0.6s ease-in-out;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.clock .flip.running .back::after {
  -webkit-animation: backFlipDown 0.6s ease-in-out;
  animation: backFlipDown 0.6s ease-in-out;
}
@-webkit-keyframes frontFlipDown {
  to {
    -webkit-transform: rotateX(0.5turn);
    transform: rotateX(0.5turn);
  }
}
@keyframes frontFlipDown {
  to {
    -webkit-transform: rotateX(0.5turn);
    transform: rotateX(0.5turn);
  }
}
@-webkit-keyframes backFlipDown {
  to {
    -webkit-transform: rotateX(0);
    transform: rotateX(0);
  }
}
@keyframes backFlipDown {
  to {
    -webkit-transform: rotateX(0);
    transform: rotateX(0);
  }
}
</style>