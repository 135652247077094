<script>
import titleInfo from "./titleInfo.vue";
import { colorOptions } from "./config/index";
import * as echarts from "echarts";
import yearEcharts from "../components/yearEcharts.vue";
import { data } from "jquery";
import Top5Score from "./top5Score.vue";
import { nowSize } from '@/utils/common'
import top5House from "./top5House.vue";
export default {
  components: { titleInfo, Top5Score, yearEcharts,top5House },
  props: {
    completeness: {
      type: Object,
      default: {},
    },
    co: { type: Object, default: {} },
    obj: { type: Object, default: {} },
  },
  data() {
    return {
      colorOptions: colorOptions,
      dialogVisible: false,
      maplist: [
        {
          url: require("../../../assets/mapImg/1.png"),
          name: "地图导航（1）",
          path: "/map",
        },
        {
          url: require("../../../assets/mapImg/2.png"),
          name: "地图导航（2）",
          path: "/mapone",
        },
        {
          url: require("../../../assets/mapImg/3.png"),
          name: "地图导航（3）",
          path: "/maptwo",
        },
        {
          url: require("../../../assets/mapImg/4.png"),
          name: "地图导航（4）",
          path: "/mapthree",
        },
        {
          url: require("../../../assets/mapImg/5.png"),
          name: "地图导航（5）",
          path: "/mapfour",
        },
        {
          url: require("../../../assets/mapImg/6.png"),
          name: "地图导航（6）",
          path: "/mapfive",
        },
        {
          url: require("../../../assets/mapImg/7.png"),
          name: "地图导航（7）",
          path: "/mapsix",
        },
        {
          url: require("../../../assets/mapImg/8.png"),
          name: "地图导航（8）",
          path: "/mapseven",
        },
        {
          url: require("../../../assets/mapImg/9.png"),
          name: "地图导航（9）",
          path: "/mapeight",
        },
        {
          url: require("../../../assets/mapImg/9.png"),
          name: "地图导航（10）",
          path: "/mapnine",
        },
      ],
      myChart: null,
    };
  },
  created(){
    this.init()
  },
  methods: {
    format(percentage, index) {
      return data[index].hour;
    },
    gomap(item) {
      this.$router.push({ path: item.path });
    },
    formatProgress(item) {
      return () => {
        return `${item} 度`;
      };
    },
    handleClose() {
      this.dialogVisible = false;
    },
    up() {
      this.dialogVisible = true;
    },
    // 饼图
    initEcharts() {
      this.echartDom = echarts.init(this.$refs.echartDom);
      let series = [];
      let pieDatas = [
        {
          value: 23218,
          name: "安庆富士龙光伏电站",
        },
        {
          value: 15434,
          name: "安徽宣城乐方光伏电站",
        },
        {
          value: 8218,
          name: "恒风风机厂光伏电站",
        },
        {
          value: 23453,
          name: "安徽中能国信环保光伏电站",
        },
        {
          value: 13442,
          name: "舒城乙滨",
        },
      ];
      let maxRadius = 80,
        barWidth = 7,
        barGap = 5;
      let sumValue = 0;
      let showValue = true,
        showPercent = true;
      pieDatas.map((item) => {
        sumValue += item.value;
      });
      let barColor = [
        {
          color1: "rgba(222, 125, 255, 1)",
          color2: "",
        },
        {
          color1: "rgba(250, 118, 121, 1)",
          color2: "",
        },
        {
          color1: "rgba(53, 198, 215, 1)",
          color2: "",
        },
        {
          color1: "rgba(101, 223, 138, 1)",
          color2: "",
        },
        {
          color1: "rgba(253, 161, 79, 1)",
          color2: "",
        },
        {
          color1: "rgba(68,165,255,1)",
          color2: "",
        },
      ];
      pieDatas.map((item, i) => {
        series.push({
          type: "pie",
          clockWise: false, //顺时加载
          hoverAnimation: false, //鼠标移入变大
          radius: [
            maxRadius - i * (barGap + barWidth) + "%",
            maxRadius - (i + 1) * barWidth - i * barGap + "%",
          ],
          center: ["20%", "50%"],
          label: {
            show: false,
          },
          itemStyle: {
            label: {
              show: false,
            },
            labelLine: {
              show: false,
            },
            borderWidth: 5,
          },
          data: [
            {
              value: item.value,
              name: item.name,
              itemStyle: {
                color:
                  (barColor[i] && barColor[i].color1) || "rgba(68,165,255,1)",
              },
            },
            {
              // value:item.value / 200,
              value: (sumValue - item.value) / 100,
              name: "",
              itemStyle: {
                color: "transparent",
              },
              tooltip: {
                show: false,
              },
              hoverAnimation: false,
            },
          ],
        });
        series.push({
          name: "blank",
          type: "pie",
          silent: true,
          z: 0,
          clockWise: false, //顺时加载
          hoverAnimation: false, //鼠标移入变大
          radius: [
            maxRadius - i * (barGap + barWidth) + "%",
            maxRadius - (i + 1) * barWidth - i * barGap + "%",
          ],
          center: ["20%", "50%"],
          label: {
            show: false,
          },
          itemStyle: {
            label: {
              show: false,
            },
            labelLine: {
              show: false,
            },
            borderWidth: 5,
          },
          data: [
            {
              value: 1,
              itemStyle: {
                color: "rgba(255, 255, 255,.13)",
                borderWidth: 0,
              },
              tooltip: {
                show: false,
              },
              hoverAnimation: false,
            },
          ],
        });
      });
      let option = {
        grid: {
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
        },
        tooltip: {
          show: true,
          trigger: "item",
        },
        legend: {
          show: true,
          left: "36%",
          top: "middle",
          icon: "circle",
          itemWidth: 10,
          itemHeight: 10,
          itemGap: 5,
          textStyle: {
            fontSize: nowSize(14),
            color: "#fff",
          },
          formatter: (name) => {
            var datas = pieDatas;
            let total = 0;
            datas.map((item) => {
              total += item.value - 0;
            });
            let valueIndex = datas.map((item) => item.name).indexOf(name);
            return (
              name +
              "  " +
              (showValue
                ? datas[valueIndex].value +
                  (option.legendValueUnit || "") +
                  "度"
                : "")
            );
          },
        },
        series: series,
      };

      this.echartDom.setOption(option);
      window.onresize = () => {
        if (this.echartDom) {
          this.echartDom.resize(); // 调用 ECharts 实例的 resize 方法
        }
      };
    },
    init() {
      this.initEcharts();
    },
  },
};
</script>
<template>
  <div class="right-main">
    <!-- 节能减排 -->
    <div class="partone">
      <titleInfo titleCn="节能减排"></titleInfo>
      <!-- <div class="titleInfo">
        <div class="text-ch">节能减排</div> -->
        <!-- <div class="text-en">Emissions Reduction</div> -->
      <!-- </div> -->
      <div class="rightBox" style="margin-top: 2%">
        <div class="item">
          <img class="img-box" src="../../../assets/mapNav/eryang.png" alt="" />
          <div>
            <span class="num">
            {{  obj.sumCurrYearPowerNum?  114 * Number(obj.sumCurrYearPowerNum).toFixed(0) : 0}}


            </span
            >
            <span class="unit">&nbsp;t</span>
            <div class="label">年累计节约标准煤</div>
          </div>
        </div>
        <div class="item">
          <img
            class="img-box"
            src="../../../assets/mapNav/state.png"
            alt=""
          />
          <div>
            <span class="num">
              {{ obj.sumCurrYearPowerNum ? (obj.sumCurrYearPowerNum * 1).toFixed(0) : 0 }}</span
            >
            <span class="unit">&nbsp;t</span>
            <div class="label">年累计CO₂减排</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 年月完成度 -->
    <!-- <div class="rightBox">
      <div class="month-finish">
        <dv-decoration-9 class="decoration9" :color="['#8157ED']"
          >{{
            completeness.month > 100 ? 100 : completeness.month
          }}%</dv-decoration-9
        >
      </div>
      <div class="year-finish">
        <dv-decoration-9 class="decoration9" :color="['#f9d071']"
          >{{
            completeness.year > 100 ? 100 : completeness.year
          }}%</dv-decoration-9
        >
      </div>
    </div> -->
    <!-- 月日均等效时TOP5 -->
    <div class="parttwo">
      <div class="progress-top5">
        <titleInfo titleCn="月日均等效时TOP5"></titleInfo>
        <!-- <div class="titleInfo">
          <div class="text-ch">月日均等效时TOP5</div> -->
          <!-- <div class="text-en">Equivalent Utilization Hours</div> -->
        <!-- </div> -->
      </div>
      <div class="progress-area" style="margin-top:10px; margin-left:40px">
        <top5House></top5House>
        <!-- <div
          v-for="(item, index) in top5"
          :key="index"
          class="progress-area-item"
        >
          <span class="label">{{ item.powerStationName }}</span>
          <el-progress
            :color="item.color"
            :format="formatProgress(item.equivalentHours.toFixed(0))"
            :percentage="item.equivalentHours.toFixed(0)"
            style="display: flex"
          ></el-progress>
        </div> -->
      </div>
    </div>
    <!-- 年发电统计 -->
    <div class="partfour">
      <titleInfo titleCn="年发电统计(度)"></titleInfo>
      <!-- <div class="titleInfo">
        <div class="text-ch">
          <span class="text-cn">年发电统计</span> <span class="unit">(度)</span>
        </div>
      </div> -->
      <yearEcharts style="height: 90%; width: 100%;"></yearEcharts>
    </div>
    <el-dialog
      title="选择地图导航样式"
      :visible.sync="dialogVisible"
      width="50%"
      custom-class="addMicroDialog"
    >
      <div
        style="
          display: flex;
          flex-wrap: wrap;
          height: 300px;
          overflow: auto;
          overflow-y: scroll;
          scrollbar-width: none; /* Firefox */
          -ms-overflow-style: none; /* IE and Edge */
        "
      >
        <div
          @click="gomap(item)"
          class="map_index"
          style="width: 30%; margin-right: 3%"
          v-for="(item, index) in maplist"
          :key="index"
        >
          <img style="width: 100%" :src="item.url" alt="" />
          <div>{{ item.name }}</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<style lang="less" scoped>
.right-main {
  height: 100%;
  // flex-direction: column;
  // justify-content: space-around;
  .partone {
    height: 18vh;
  }
  .parttwo {
    height: 35vh;
  }
  .partthree {
    height: 25vh;
  }
  .partfour {
    height: calc(45vh - 85px);
  }
  .rightBox {
    height: 11vh;
    display: flex;
    font-size: 18px;
    justify-content: space-around;
    .item {
      display: flex;
      // flex-direction: column;
      align-items: center;
      text-align: center;
      .img-box {
        width: 50px;
        height: 50px;
        margin-right: 10px;
      }
      .num {
        font-size: 30px;
        margin-top: 8px;
        font-weight: bold;
        // font-style: italic;
        background-image: linear-gradient(to right, #2acfc9 0%, #0168ac 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        display: inline-block;
        font-family: "YouSheBiaoTiHei";
        margin-bottom: 8px;
        .unit {
          color: #fff;
          padding-left: 1px;
          font-size: 18px;
          margin-left: 3px;
        }
      }
      .label {
        color: #fff;
        font-size: 16px;
        margin-left: 10px;
      }
    }
    .year-finish {
      position: relative;
      margin-top: 20px;
      flex: 1;
      text-align: center;
      .decoration9 {
        width: 120px;
        height: 120px;
        margin: 0 auto;
      }
    }
    .year-finish {
      .el-progress__text {
        font-size: 16px !important;
        color: #8157ed;
      }
    }
    .month-finish::after {
      content: "月完成度";
      position: absolute;
      color: #fff;
      bottom: -22px;
      left: 50%;
      width: 100%;
      font-size: 16px;
      display: block;
      text-align: center;
      transform: translateX(-50%);
    }
    .year-finish::after {
      content: "年完成度";
      position: absolute;
      color: #fff;
      font-size: 16px;
      bottom: -22px;
      left: 50%;
      width: 100%;
      display: block;
      text-align: center;
      transform: translateX(-50%);
    }
  }
  .progress-tops {
    margin-top: 48px;
  }
  .progress-area {
    height: 100%;
    padding-left: 20px;
    box-sizing: border-box;
    .progress-area-item {
      display: flex;
      align-items: center;
      & {
        margin-bottom: 14px;
      }
      span.label {
        color: #fff;
        display: inline-block;
        padding-right: 8px;
        font-size: 16px;
        width: 280px;
        text-align: end;

        // // 省略号css
        // overflow: hidden;
        // text-overflow: ellipsis;
        // white-space: nowrap;
      }
      .el-progress {
        flex-grow: 1;
        ::v-deep .el-progress__text {
          color: #fff;
          font-size: 16px !important;
          width: 140px !important;
        }
        .el-progress-bar__outer {
          border: 1px solid #026795;
          background: transparent;
        }
      }
    }
  }
}
.echarts {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  .echarts-main {
    width: 100%;
    height: 180px;
  }
  .echarts-desc {
    margin-top: 16px;
    flex: 1;
    .item {
      position: relative;
      display: flex;
      margin-bottom: 6px;
      justify-content: space-between;
      align-items: center;
      &::before {
        content: "";
        left: -24px;
        display: block;
        position: absolute;
        width: 12.8px;
        top: 4px;
        height: 13px;
        background: #026795;
        border-radius: 50%;
      }
      &:nth-child(1)::before {
        background-color: #1fb5fc;
      }
      &:nth-child(2)::before {
        background-color: #69edd1;
      }
      &:nth-child(3)::before {
        background-color: #f9d071;
      }
      &:nth-child(4)::before {
        background-color: #f36dc7;
      }
      &:nth-child(5)::before {
        background-color: #8400ff;
      }

      .left {
        color: #fff;
        font-size: 14px;
        width: 200px;

        // // 省略号css
        // overflow: hidden;
        // text-overflow: ellipsis;
        // white-space: nowrap;
      }
      .right {
        font-size: 14px;
      }
      &:nth-child(1) {
        color: #1fb5fc;
      }
      &:nth-child(2) {
        color: #69edd1;
      }
      &:nth-child(3) {
        color: #f9d071;
      }
      &:nth-child(4) {
        color: #f36dc7;
      }
      &:nth-child(5) {
        color: #8400ff;
      }
    }
  }
}

.map_xz {
  cursor: pointer;
  position: absolute;
  top: -15px;
  right: 50px;
  button ::focus {
    border: none;
  }
}
.fixed {
  position: fixed;
  top: 70px;
  right: 0;
  z-index: 9999;
}
.el-dialog__wrapper {
  z-index: 200030;
}

::v-deep .el-progress-bar {
  margin-top: 2%;
  width: 200px;
}
</style>
