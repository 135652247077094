export default {
  props: {
    title: String,
    percentage: Boolean,
    fontSize: {
      type: String,
      default: "20px"
    },
    unit: String
  }
}