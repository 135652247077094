<template>
  <div class="radar-scan" :style="{width:size,height:size,zIndex:zIndex}">
    <canvas class="radar-scan--canvas__top" ref="canvas" :width="size" :height="size" :style="{opacity:opacity}"></canvas>
    <canvas class="radar-scan--canvas__bottom" ref="scaner" :width="size" :height="size" :style="{opacity:opacity}"></canvas>
  </div>
</template>

<script>
export default {
  name: "RadarScan",
  data() {
    return {
      cvs: null,
      scaner: {
        cvs: null,
        deg: 0
      },
      s: parseInt(this.size)
    }
  },
  props: {
    size: {
      type: String,
      required: true
    },
    circleCount: {
      type: Number,
      default: 2
    },
    lineColor: {
      type: String,
      default: 'rgb(0, 255, 0)'
    },
    lineWidth: {
      type: Number,
      default: 1
    },
    dotColor: {
      type: String,
      default: 'rgb(255, 255, 255)'
    },
    scanerColor: {
      type: String,
      default: 'rgba(0, 200, 0, 0.7)'
    },
    bgColor: {
      type: String,
      default: 'rgb(0, 50, 0)'
    },
    zIndex: {
      type: [Number, String],
      default: 100
    },
    opacity: {
      type: Number,
      default: 0.5
    },
    speed: {
      type: Number,
      default: 1
    },
    coverColor: {
      type: String,
      default: 'rgba(0, 0, 0, 0.02)'
    }
  },
  mounted() {
    if (this.$refs.canvas.getContext) {
      this.cvs = this.$refs.canvas.getContext('2d');
      this.scaner.cvs = this.$refs.scaner.getContext('2d');
      this.$$_init();
      this.$$_draw();
    }
  },
  methods: {
    $$_init() {
      const r = this.s / 2;
      this.scaner.cvs.fillStyle = this.bgColor;
      this.scaner.cvs.arc(r, r, r, 0, 2 * Math.PI);
      this.scaner.cvs.fill();
    },
    $$_draw() {
      this.$$_drawLine();
      this.$$_drawCircle();
      this.$$_drawDot();
      this.$$_scan();
    },
    $$_drawLine() {
      //画直线
      this.cvs.strokeStyle  = this.lineColor;
      this.cvs.lineWidth = this.lineWidth;
      this.cvs.beginPath();
      //横线
      this.cvs.moveTo(0, this.s / 2);
      this.cvs.lineTo(this.s, this.s / 2);
      //竖线
      this.cvs.moveTo(this.s / 2, 0);
      this.cvs.lineTo(this.s / 2, this.s);
      //斜线
      let p = Math.sqrt((Math.sqrt((this.s / 2) ** 2 + (this.s / 2) ** 2) - this.s / 2) ** 2 / 2);
      this.cvs.moveTo(p, p);
      this.cvs.lineTo(this.s - p, this.s - p);
      this.cvs.moveTo(this.s - p, p);
      this.cvs.lineTo(p, this.s - p);
      //画
      this.cvs.stroke();
      // this.cvs.closePath();
    },
    $$_drawCircle() {
      const radius = [], r = this.s / 2;
      let p = r / this.circleCount;  //相隔的距离
      for (let i = 0; i < this.circleCount; i++) {
        radius.push(r - i * p);
      }
      //画圈
      this.cvs.beginPath();
      radius.forEach(i => {
        this.cvs.arc(r, r, i, 0, Math.PI * 2);
      });
      //画
      this.cvs.stroke();
      this.cvs.closePath();
    },
    $$_drawDot() {
      //画中心点
      this.cvs.beginPath();
      //大小固定 6
      this.cvs.fillStyle = this.dotColor;
      this.cvs.arc(this.s / 2, this.s / 2, 6, 0, Math.PI * 2);
      this.cvs.fill();
    },
    $$_scan() {
      this.$$_cover();
      const r = this.s / 2;
      this.scaner.cvs.fillStyle = this.scanerColor;
      this.scaner.cvs.beginPath();
      this.scaner.cvs.moveTo(r, r);
      this.scaner.cvs.arc(r, r, r, (-this.speed + this.scaner.deg) / 180 * Math.PI, this.scaner.deg / 180 * Math.PI);
      this.scaner.cvs.fill();
      this.scaner.deg += this.speed;
      
      window.requestAnimationFrame(this.$$_scan);
    },
    $$_cover() {
      const r = this.s / 2;
      this.scaner.cvs.save();
      this.scaner.cvs.fillStyle = this.coverColor;//'rgba(0, 0, 0, 0.02)';
      this.scaner.cvs.beginPath();
      this.scaner.cvs.arc(r, r, r, 0, 2 * Math.PI);
      this.scaner.cvs.fill();
      this.scaner.cvs.restore();
    }
  }
}
</script>

<style lang="less" scoped>
@import "./style.less";
</style>