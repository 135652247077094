export default {
  namespaced: true,
  state: {
    tableList: [],
  },
  getters: {

  },
  mutations: {
    setTableList(state, data) {
      state.tableList = data;
    },
  },
};
