<template>
  <svg class="iconfont" :style="{color: color, fontSize: fontSize}" aria-hidden="true">
    <use :xlink:href="xlinkHref"></use>
  </svg>
</template>

<script>
export default {
  name: "icon",
  props: {
    i: {
      type: String,
      required: true
    },
    color: String,
    fontSize: String
  },
  computed: {
    xlinkHref() {
      return `#${this.i}`;
    }
  }
}
</script>

<style lang="less" scoped>
@import "./style.less";
</style>