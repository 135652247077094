<template>
  <div>
    <div style="height: 100%; width: 100%" id="monthChart"></div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import { nowSize } from '@/utils/common'
export default {
  props: {
    color: {
      type: String,
      default: "#61dfd1",
    },
  },
  mounted() {
    this.initMOnthElectricity();
  },
  methods: {
    async initMOnthElectricity() {
      const res = await this.$http.post(
        "/powerStationDeviceSummary/allSummaryByMonths",
        {}
      );
      let xAxisData = []
      let yAxisData = []
      if (res.data.code === 200) {
        xAxisData = res.data.data.map((item) => item.date = item.date.slice(-2));
        yAxisData = res.data.data.map((item) => item.powerNum.toFixed(0));
      } else {
        this.$message.error(res.data);
      }
      this.monthChart = echarts.init(document.getElementById("monthChart"));
      let option = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          top: "19%",
          right: "8%",
          left: "80px",
          bottom: "15%",
        },
        xAxis: [
          {
            type: "category",
            show: true,
            data: xAxisData,
            axisLine: {
              lineStyle: {
                color: "rgba(255,255,255,0.12)",
              },
            },
            axisLabel: {
              margin: 5,
              color: "#e2e9ff",
              interval: 0,
              textStyle: {
                fontSize: nowSize(14),
              },
            },
          },
        ],
        yAxis: [
          {
            axisLabel: {
              formatter: "{value}",
              color: "#e2e9ff",
            },
            axisLine: {
              show: false,
              lineStyle: {
                color: "rgba(255,255,255,1)",
              },
            },
            splitLine: {
              lineStyle: {
                color: "rgba(255,255,255,0.12)",
              },
            },
          },
        ],
        series: [
          {
            type: "bar",
            data: yAxisData,
            barWidth: nowSize(14),
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: "rgba(0,244,255,1)", // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: "rgba(0,77,167,1)", // 100% 处的颜色
                    },
                  ],
                  false
                ),
                barBorderRadius: [30, 30, 30, 30],
                shadowColor: "rgba(0,160,221,1)",
                shadowBlur: 4,
              },
            },
            label: {
              normal: {
                // show: true,
                lineHeight: nowSize(28),
                width: nowSize(100),
                height: nowSize(28),
                backgroundColor: "rgba(0,160,221,0.1)",
                borderRadius: 200,
                position: ["-15", "-40"],
                distance: 1,
                formatter: ["    {d|●}", " {a|{c}}     \n", "    {b|}"].join(
                  ","
                ),
                rich: {
                  d: {
                    color: "#3CDDCF",
                  },
                  a: {
                    color: "#fff",
                    align: "center",
                    fontSize:nowSize(14)
                  },
                  b: {
                    width: 1,
                    height: 20,
                    borderWidth: 1,
                    borderColor: "#234e6c",
                    align: "left",
                    fontSize:nowSize(14)
                  },
                },
              },
            },
          },
        ],
      };
      this.monthChart.setOption(option);
      window.onresize = () => {
        if (this.monthChart) {
          this.monthChart.resize(); // 调用 ECharts 实例的 resize 方法
        }
      };
    },
  },
};
</script>

<style></style>
