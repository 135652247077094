<template>
  <div id="app">
    <!-- 路由占位符 -->
    <router-view v-if="routerAlive"></router-view>
  </div>
</template>

<script>
// import DevicePixelRatio from "./assets/js/px"
export default {
  name: "app",
  //暴露 reload 这个方法，方便组件调用
  provide() {
    return {
      reload: this.reload,
    };
  },
  data() {
    return { routerAlive: true };
  },

  methods: {
    //重新加载的方法
    reload() {
      this.routerAlive = false;
      this.$nextTick(function () {
        this.routerAlive = true;
      });
    },
  },
  // created(){
  //   new DevicePixelRatio().init()
  // }
};
</script>

<style>
html,
body,
#app {
  margin: 0;
  height: 100%;
  padding: 0;
  min-width: 1200px;
  box-sizing: border-box;
  background: #2c4e73;
}
.el-menu {
  border-right: none;
}

div {
  box-sizing: border-box;
}
li {
  list-style: none;
}
/* 取消滚动条 */
::-webkit-scrollbar {
  display: none;
}

</style>
