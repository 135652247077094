<script>
export default {
  props: {
    titleCn: {
      type: String,
      default: "",
    },
    titleEn: {
      type: String,
      default: "",
    },
  },
};
</script>
<template>
  <div class="titleInfo">
    <div class="text-ch">{{ titleCn }}</div>
    <span class="text-en">{{ titleEn }}</span>
  </div>
</template>


<style lang="less" scoped>
.titleInfo {
  padding: 10px 20px 0 20px;
  display: flex;
  .text-ch {
    color: #02a7f0;
    font-size: 20px;
    width: 300px;
    padding: 10px;
    display: flex;
    align-items: center;
    background: url("../../../assets/mapNav/titlebg.png") left center
      no-repeat;
    background-size: 200px 60px;
    padding-left: 30px;
  }
  .text-en {
    color: #d7d7d7;
    font-size: 16px;
    display: flex;
    align-items: center;
    margin-left: 5px;
  }
}
.unit {
  font-size: 18px;
}
</style>
