export default {
  props: {
    fontColor: String,
    fontSize: {
      type: String,
      required: true
    },
    bg: [String, Array],
    bgColor: String,
    speed: String,
    bgImage: [String, Array]
  }
}