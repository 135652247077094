<template>
  <div>
    <div :id="chartId" :style="{ width: width, height: height }"></div>
         <!-- <EchartsVue
            :chartId="'year'"
            :option="optionYear"
            :height="'150px'"
            :width="'100%'"
          /> -->
  </div>
</template>

<script>
import * as echarts from "echarts";
export default {
  props: {
    chartId: {
      type: String,
      required: true,
    },
    width: {
      type: String,
      default: "400px",
    },
    height: {
      type: String,
      default: "170px",
    },
    option: {
      type: Object,
      default: null,
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.chartIndex = echarts.init(document.getElementById(this.chartId));
      console.log(document.getElementById(this.chartId), 35);
      console.log(this.option, this.width,this.height , 36);
      let option = this.option;
      this.chartIndex.setOption(option);

      window.onresize = () => {
        this.chartIndex.resize();
      };
    },
  },
};
</script>

<style>
.mycharts {
  width: 100%;
  height: 100%;
}
</style>
