<template>
  <div class="cut-3d" 
    :style="{width: width, height: height}"
    @mouseenter="handlerMouseenter"
  >
    <div ref="one" class="cut-3d--one" :style="{transformOrigin: transformOrigin, backgroundImage: `url(${bg})`, borderRadius: borderRadius}">
      <div class="cut-3d--one__title" :style="{fontSize: titleFontSize}" v-text="data.title"></div>
      <div class="cut-3d--one__content" :style="{fontSize: contentFontSize}">
        <div class="cut-3d--one__content__line">收视人数(<span style="color:red;">UV</span>)：<span style="color:red;" v-text="data.uv"></span></div>
        <div class="cut-3d--one__content__line">收视次数(<span style="color:rgb(72, 216, 228)">PV</span>)：<span style="color:rgb(72, 216, 228)" v-text="data.pv"></span></div>
      </div>
    </div>
    <div ref="two" class="cut-3d--two" :style="{transformOrigin: transformOrigin, backgroundImage: `url(${image})`, borderRadius: borderRadius}"></div>
  </div>
</template>

<script>
import Util from "../../../utils/util";
export default {
  name: "cut-3d",
  data() {
    return {
      refList: ['one', 'two'],
      showIndex: 0,
      w: parseInt(this.width),
      h: parseInt(this.height),
      styleTag: null,
      transformOrigin: this.transformOriginUpDown
    }
  },
  props: {
    width: {
      type: String,
      required: true
    },
    height: {
      type: String,
      required: true
    },
    borderRadius: String,
    image: String,
    bg: String,
    reverseRoll: {  //是否反向滚动，默认是
      type: Boolean,
      default: true
    },
    titleFontSize: {
      type: String,
      default: "24px"
    },
    contentFontSize: {
      type: String,
      default: "20px"
    },
    data: {
      type: Object,
      required: true
    }
  },
  computed: {
    transformOriginUpDown() {
      return `${parseInt(this.width) / 2}px ${parseInt(this.height) / 2}px ${-parseInt(this.height) / 2}px`;
    },
    transformOriginLeftRight() {
      return `${parseInt(this.width) / 2}px ${parseInt(this.height) / 2}px ${-parseInt(this.width) / 2}px`;
    }
  },
  mounted() {
    this.$$_createStyle();
  },
  methods: {
    handlerMouseenter(e) {
      const [offsetX, offsetY] = [e.offsetX, e.offsetY];
      const inDirection = this.$$_getInDirection(offsetX, offsetY);
      if (inDirection === "up") {
        this.transformOrigin = this.transformOriginUpDown;
        if (this.reverseRoll) this.$$_move("to-up");
        else this.$$_move("to-down");
      } else if (inDirection === "down") {
        this.transformOrigin = this.transformOriginUpDown;
        if (this.reverseRoll) this.$$_move("to-down");
        else this.$$_move("to-up");
      } else if (inDirection === "left") {
        this.transformOrigin = this.transformOriginLeftRight;
        if (this.reverseRoll) this.$$_move("to-left");
        else this.$$_move("to-right");
      } else {
        this.transformOrigin = this.transformOriginLeftRight;
        if (this.reverseRoll) this.$$_move("to-right");
        else this.$$_move("to-left");
      }
    },
    $$_createStyle() {
      // js创建@keyframes
      const runkeyframes =`
        @keyframes cut-3d-animation--to-right__hidetoshow {
          0% { transform: rotateY(-90deg); }
          100% { transform: rotateY(0); }
        }
        @keyframes cut-3d-animation--to-right__showtohide {
          0% { transform: rotateY(0); }
          100% { transform: rotateY(90deg); }
        }

        @keyframes cut-3d-animation--to-left__hidetoshow {
          0% { transform: rotateY(90deg); }
          100% { transform: rotateY(0); }
        }
        @keyframes cut-3d-animation--to-left__showtohide {
          0% { transform: rotateY(0); }
          100% { transform: rotateY(-90deg); }
        }

        @keyframes cut-3d-animation--to-up__hidetoshow {
          0% { transform: rotateX(-90deg); }
          100% { transform: rotateX(0); }
        }
        @keyframes cut-3d-animation--to-up__showtohide {
          0% { transform: rotateX(0); }
          100% { transform: rotateX(90deg); }
        }

        @keyframes cut-3d-animation--to-down__hidetoshow {
          0% { transform: rotateX(90deg); }
          100% { transform: rotateX(0); }
        }
        @keyframes cut-3d-animation--to-down__showtohide {
          0% { transform: rotateX(0); }
          100% { transform: rotateX(-90deg); }
        }
      `
      // 创建style标签
      this.styleTag = document.createElement('style');
      this.styleTag.type = 'text/css';
      this.styleTag.innerHTML = runkeyframes;
      // 将style样式存放到head标签
      document.getElementsByTagName('head')[0].appendChild(this.styleTag);
    },
    $$_getInDirection(offsetX, offsetY) {
      const [v, v1] = [this.h / this.w, offsetY / offsetX];
      let dir = "";
      if (v1 >= v) {
        //left, down
        const vx = (this.h - offsetY) / offsetX;
        if (vx >= v) {
          //left
          dir = "left";
        } else {
          dir = "down";
        }
      } else {
        //up, right
        const vx = offsetY / (this.w - offsetX);
        if (vx <= v) {
          //up
          dir = "up";
        } else {
          dir = "right";
        }
      }
      return dir;
    },
    $$_move(toDir) {
      let i = (this.showIndex + 1) % 2;
      this.$refs[this.refList[i]].style.animationName = `cut-3d-animation--${toDir}__hidetoshow`;
      this.$refs[this.refList[this.showIndex]].style.animationName = `cut-3d-animation--${toDir}__showtohide`;
      this.showIndex = i;
    }
  },
  beforeDestroyed() {
    this.styleTag.parentNode.removeNode(this.styleTag);
  }
}
</script>

<style lang="less" scoped>
@import "./style.less";
</style>