<template>
  <div ref="line" class="line" :style="{width: width, height: height}"></div>
</template>

<script>
import props from "../../common/js/props";
import Util from "../../../utils/util.js";
export default {
  name: "XLine",
  mixins: [ props ],
  data() {
    return {
      echart: null,
      op: {
        xAxis: {
          type: 'category',
          boundaryGap: false
        },
        yAxis: {
          type: 'value'
        }
      }
    }
  },
  props: {
    xAxisData: Array,
    yAxisName: String,
    yAxisNameTextStylePadding: Array,
    yAxisSplitLineShow: {
      type: Boolean,
      default: false
    },
    data: Array,
    areaStyleColor: String,
    lineStyleColor: String,
    itemStyleColor: String,
    smooth: {
      type: Boolean,
      default: true
    }
  },
  created() {
    const se = Util.deleteKeysOfValueUndefinedToNew({
      data: this.data,
      type: 'line',
      areaStyle: {
        color: this.areaStyleColor
      },
      lineStyle: {
        color: this.lineStyleColor
      },
      itemStyle: {
        color: this.itemStyleColor
      },
      smooth: this.smooth
    })
    const op = Util.deleteKeysOfValueUndefinedToNew({
      xAxis: {
        data: this.xAxisData
      },
      yAxis: {
        name: this.yAxisName,
        nameTextStyle: {
          padding: this.yAxisNameTextStylePadding
        },
        splitLine: {
          show: this.yAxisSplitLineShow
        }
      },
      series: [Util.merge(se, this.series)]
    });
    Util.merge(this.op, op, this.option);
  },
  mounted() {
    this.echart = this.$Echarts.init(this.$refs.line);
    this.echart.setOption(this.op);
  }
}
</script>

<style lang="less" scoped>
@import "./style.less";
</style>