<template>
  <div class="map1">
    <div class="overlay">
      <div class="left">
        <LeftData ref="LeftData"></LeftData>
      </div>
      <div class="yuanBox">
        <img src="../../assets/mapNav/guangyun1.png" alt="" class="img1" />
        <img src="../../assets/mapNav/guangyun2.png" alt="" class="img2" />
        <div class="yuan">
          <mapVue class="map"></mapVue>
        </div>
      </div>
      <div class="right">
        <RightData ref="RightData" :completeness="completeness" :obj="obj"></RightData>
      </div>
    </div>
    <timeVue class="times"></timeVue>
    <mapDialog ref="mapDialog"></mapDialog>
  </div>
</template>

<script>
import LeftData from "./components/LeftData.vue";
import RightData from "./components/RightData.vue";
import mapDialog from "./components/mapDialog.vue";
import mapVue from "./components/map.vue";
import timeVue from "./components/time.vue";

export default {
  components: { LeftData, RightData, mapDialog, mapVue, timeVue },
  data() {
    return {
      renderer: null,
      camera: null,
      modedata: {},
      toplist: [],
      co: {},
      year: "",
      month: "",
      animationFrameId: null,
      powerStationCount: {}, // 电站建设发电量
      completeness: {}, // 电站建设完成度
      top5: [],
      top5plus: [],
      locationList: [], //经纬度
      count: {},
      obj: {}
    };
  },
  created() {
    this.getData();
    this.initYearElectricitys();
  },
  methods: {
    // 获取数据
    async getData() {
      console.log(JSON.parse(localStorage.getItem('userInfo')).userCode,'000000000')
      const res = await this.$http.post("device/queryUserAllStationDailyPower", {
        userCode: JSON.parse(localStorage.getItem('userInfo')).userCode
      });
      if (res.data.code === 200) {
        this.obj = res.data.data
      } else {
        this.$message.error(res.data);
      }
    },
    // 获取年
    async initYearElectricitys() {
      const res = await this.$http.post(
        "/powerStationDeviceSummary/allSummaryByYears",
        {}
      );
      if (res.data.code === 200) {
        const data = {};
        const xAxis = res.data.data.map((item) => item.date);
        const yAxis = res.data.data.map((item) => item.powerNum);
        data.xAxis = xAxis;
        data.yAxis = yAxis;
        // this.$refs.RightData.initTop(data);
      }
    },
  },
};
</script>

<style lang="less">
html,
body {
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.map1 {
  position: relative;
  // background-color: #051525;
  background: linear-gradient(to bottom, #051525, #0e2944ed);
  position: absolute;
  top: 85px;
  left: 0;
  bottom: 0;
  right: 0;

  // background: url("../../assets/mapImg/bg.jpg") no-repeat center center;
  .left {
    width: 30%;
    height: 100%;
    pointer-events: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  .right {
    width: 30%;
    height: 100%;
    pointer-events: auto;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
  }

  .yuanBox {
    width: 700px;
    height: 700px;
    margin: 0 auto;
    top: 50%;
    margin-top: -330px;
    flex-shrink: 0;
    position: relative;

    .yuan {
      border-radius: 50%;
      width: 700px;
      height: 700px;
      position: absolute;
      overflow: hidden;
      z-index: 999;
      animation: fadeOut 2s infinite alternate;
    }

    // border: 10px solid #59fded;
    // filter: drop-shadow(0 0 30px rgba(255, 255, 255, 0.8)); /* 元素白色发光效果 */
    // filter: drop-shadow(0 0 30px rgba(86, 248, 239, 0.6)); /* 元素白色发光效果 */
    .img1 {
      position: absolute;
      display: block;
      width: 260px;
      top: 80px;
      left: -140px;
    }

    .img2 {
      position: absolute;
      display: block;
      width: 260px;
      top: 80px;
      right: -140px;
    }

    .map {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      // animation: twinkling 1.6s infinite;
      // animation-fill-mode: both;
    }
  }
}

.overlay {
  color: white;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  height: 100%;
}

@keyframes fadeOut {
  0% {
    box-shadow: 0 0 5px #70b3ff;
    /* 初始状态 */
  }

  50% {
    box-shadow: 0 0 10px #fff, 0 0 30px #017da0;
    /* 增加发光效果 */
  }

  100% {
    box-shadow: 0 0 5px #70b3ff;
    /* 恢复初始状态 */
  }
}

.times {
  position: absolute;
  left: 50%;
  top: 10px;
  width: 300px;
  margin-left: -150px;
  text-align: center;
  background: rgba(0, 0, 0, 0.2);
  // padding:10px 0;
  font-size: 18px;
  text-shadow: 0 0 1.2vmin #0096ff;
}
</style>
