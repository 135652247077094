<template>
  <div class="echarts" id="ranking-board">
    <div ref="echartDom" class="echarts-main"></div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import { nowSize } from '@/utils/common'
export default {
  data() {
    return {
    };
  },
  methods: {
    // 饼图
    initEcharts() {
      this.echartDom = echarts.init(this.$refs.echartDom);
      // const data = this.top5plus.map((item) => {
      //   return {
      //     value: item.equivalentHours.toFixed(0),
      //     name: item.powerStationName,
      //   };
      // });
      // let name = data.map((item) => item.name);
      // let value = data.map((item) => item.value);
      // let sum = value.reduce((a, b) => {
      //   return a + b;
      // });
      // let color = [
      //   [
      //     "rgb(1, 179, 238)",
      //     "rgb(213, 181, 102)",
      //     "rgb(254, 83, 189)",
      //     "rgb(113, 85, 197)",
      //     "rgb(105, 237, 209)",
      //   ],
      //   [
      //     "rgba(1, 179, 238,1)",
      //     "rgba(213, 181, 102,1)",
      //     "rgba(254, 83, 189,1)",
      //     "rgba(113, 85, 197,1)",
      //     "rgb(105, 237, 209)",
      //   ],
      // ];
      // let series = [];
      // let yAxis = [];
      // for (let i = 0; i < data.length; i++) {
      //   series.push({
      //     name: "",
      //     type: "pie",
      //     clockWise: false, //顺时加载
      //     hoverAnimation: false, //鼠标移入变大
      //     radius: [45 - i * 10 + "%", 40 - i * 10 + "%"],
      //     center: ["15%", "50%"],
      //     label: {
      //       show: false,
      //     },
      //     itemStyle: {
      //       label: {
      //         show: false,
      //       },
      //       labelLine: {
      //         show: false,
      //       },
      //       borderWidth: 5,
      //     },
      //     data: [
      //       {
      //         value: data[i].value,
      //         name: data[i].name,
      //       },
      //       {
      //         value: sum - data[i].value,
      //         name: "",
      //         itemStyle: {
      //           color: "transparent",
      //         },
      //         tooltip: {
      //           show: false,
      //         },
      //         hoverAnimation: false,
      //       },
      //     ],
      //   });
      //   series.push({
      //     name: "",
      //     type: "pie",
      //     silent: true,
      //     z: 1,
      //     clockWise: false, //顺时加载
      //     hoverAnimation: false, //鼠标移入变大
      //     radius: [45 - i * 10 + "%", 40 - i * 10 + "%"],
      //     center: ["15%", "50%"],
      //     label: {
      //       show: false,
      //     },
      //     itemStyle: {
      //       label: {
      //         show: false,
      //       },
      //       labelLine: {
      //         show: false,
      //       },
      //       borderWidth: 5,
      //     },
      //     data: [
      //       {
      //         value: 7.5,
      //         itemStyle: {
      //           color: color[1][i],
      //         },
      //         tooltip: {
      //           show: false,
      //         },
      //         hoverAnimation: false,
      //       },
      //       {
      //         value: 2.5,
      //         itemStyle: {
      //           color: "rgba(0,0,0,0)",
      //           borderWidth: 0,
      //         },
      //         tooltip: {
      //           show: false,
      //         },
      //         hoverAnimation: false,
      //       },
      //     ],
      //   });
      //   yAxis.push(((data[i].value / sum) * 100).toFixed(2) + "%");
      // }
      // const option = {
      //   color: color[0],
      //   legend: {
      //     show: true,
      //     icon: "circle",
      //     top: "center",
      //     left: "25%",
      //     data: name,
      //     orient: "vertical",
      //     formatter: (name) => {
      //       return (
      //         "{title|" +
      //         name +
      //         "} {value|" +
      //         data.find((item) => {
      //           return item.name == name;
      //         }).value +
      //         "} {value|度}"
      //       );
      //     },
      //     textStyle: {
      //       rich: {
      //         title: {
      //           fontSize: 10,
      //           lineHeight: 12,
      //           color: "#fff",
      //         },
      //         value: {
      //           fontSize: 10,
      //           lineHeight: 12,
      //           color: "rgb(0, 178, 246)",
      //         },
      //       },
      //     },
      //   },
      //   tooltip: {
      //     show: true,
      //     trigger: "item",
      //     formatter: "{a}<br>{b}:{c}({d}%)",
      //   },
      //   grid: {
      //     top: "10%",
      //     left: "-30%",
      //     width: "40%",
      //     height: "21%",
      //     containLabel: false,
      //   },
      //   yAxis: [
      //     {
      //       type: "category",
      //       inverse: true,
      //       axisLine: {
      //         show: false,
      //       },
      //       axisTick: {
      //         show: false,
      //       },
      //       axisLabel: {
      //         interval: 0,
      //         inside: true,
      //         textStyle: {
      //           color: "#fff",
      //           fontSize: 14,
      //         },
      //         show: false,
      //       },
      //       data: yAxis,
      //     },
      //   ],
      //   xAxis: [
      //     {
      //       show: false,
      //     },
      //   ],
      //   series: series,
      // };
      let series = [];
      let pieDatas = [
        {
          value: 23218,
          name: "安庆富士龙光伏电站",
        },
        {
          value: 15434,
          name: "安徽宣城乐方光伏电站",
        },
        {
          value: 8218,
          name: "恒风风机厂光伏电站",
        },
        {
          value: 23453,
          name: "安徽中能国信环保光伏电站",
        },
        {
          value: 13442,
          name: "舒城乙滨",
        }
      ];
      let maxRadius = 80,
        barWidth = 7,
        barGap = 5;
      let sumValue = 0;
      let showValue = true,
        showPercent = true;
      pieDatas.map((item) => {
        sumValue += item.value;
      });
      let barColor = [
        {
          color1: "rgba(222, 125, 255, 1)",
          color2: "",
        },
        {
          color1: "rgba(250, 118, 121, 1)",
          color2: "",
        },
        {
          color1: "rgba(53, 198, 215, 1)",
          color2: "",
        },
        {
          color1: "rgba(101, 223, 138, 1)",
          color2: "",
        },
        {
          color1: "rgba(253, 161, 79, 1)",
          color2: "",
        },
        {
          color1: "rgba(68,165,255,1)",
          color2: "",
        },
      ];
      pieDatas.map((item, i) => {
        series.push({
          type: "pie",
          clockWise: false, //顺时加载
          hoverAnimation: false, //鼠标移入变大
          radius: [
            maxRadius - i * (barGap + barWidth) + "%",
            maxRadius - (i + 1) * barWidth - i * barGap + "%",
          ],
          center: ["20%", "50%"],
          label: {
            show: false,
          },
          itemStyle: {
            label: {
              show: false,
            },
            labelLine: {
              show: false,
            },
            borderWidth: 5,
          },
          data: [
            {
              value: item.value,
              name: item.name,
              itemStyle: {
                color:
                  (barColor[i] && barColor[i].color1) || "rgba(68,165,255,1)",
              },
            },
            {
              // value:item.value / 200,
              value: (sumValue - item.value) / 100,
              name: "",
              itemStyle: {
                color: "transparent",
              },
              tooltip: {
                show: false,
              },
              hoverAnimation: false,
            },
          ],
        });
        series.push({
          name: "blank",
          type: "pie",
          silent: true,
          z: 0,
          clockWise: false, //顺时加载
          hoverAnimation: false, //鼠标移入变大
          radius: [
            maxRadius - i * (barGap + barWidth) + "%",
            maxRadius - (i + 1) * barWidth - i * barGap + "%",
          ],
          center: ["20%", "50%"],
          label: {
            show: false,
          },
          itemStyle: {
            label: {
              show: false,
            },
            labelLine: {
              show: false,
            },
            borderWidth: 5,
          },
          data: [
            {
              value: 1,
              itemStyle: {
                color: "rgba(255, 255, 255,.13)",
                borderWidth: 0,
              },
              tooltip: {
                show: false,
              },
              hoverAnimation: false,
            },
          ],
        });
      });
      let option = {
        grid: {
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
        },
        tooltip: {
          show: true,
          trigger: "item",
        },
        legend: {
          show: true,
          left: "38%",
          top: "middle",
          icon: "circle",
          itemWidth: 10,
          itemHeight: 10,
          itemGap: 5,
          textStyle: {
            fontSize: nowSize(14),
            color: "#fff",
          },
          formatter: (name) => {
            var datas = pieDatas;
            let total = 0;
            datas.map((item) => {
              total += item.value - 0;
            });
            let valueIndex = datas.map((item) => item.name).indexOf(name);
            return (
              name +
              "  " +
              (showValue
                ? datas[valueIndex].value + (option.legendValueUnit || "") + "度"
                : "")
            );
          },
        },
        series: series,
      };
      this.echartDom.setOption(option);
      window.onresize = () => {
        if (this.echartDom) {
          this.echartDom.resize(); // 调用 ECharts 实例的 resize 方法
        }
      };
    },
    init(data) {
      this.top5plus = data;
      this.initEcharts();
    },
  },
};
</script>

<style lang="scss" scoped>
.echarts {
  display: flex;
  align-items: center;
  .echarts-main {
    width: 100%;
    height: 200px;
  }
  .echarts-desc {
    margin-top: 16px;
    flex: 1;
    .item {
      position: relative;
      display: flex;
      margin-bottom: 6px;
      justify-content: space-between;
      align-items: center;
      &::before {
        content: "";
        left: -24px;
        display: block;
        position: absolute;
        width: 12.8px;
        top: 4px;
        height: 13px;
        background: #026795;
        border-radius: 50%;
      }
      &:nth-child(1)::before {
        background-color: #1fb5fc;
      }
      &:nth-child(2)::before {
        background-color: #69edd1;
      }
      &:nth-child(3)::before {
        background-color: #f9d071;
      }
      &:nth-child(4)::before {
        background-color: #f36dc7;
      }
      &:nth-child(5)::before {
        background-color: #8400ff;
      }

      .left {
        color: #fff;
        font-size: 14px;
        width: 200px;

        // // 省略号css
        // overflow: hidden;
        // text-overflow: ellipsis;
        // white-space: nowrap;
      }
      .right {
        font-size: 14px;
      }
      &:nth-child(1) {
        color: #1fb5fc;
      }
      &:nth-child(2) {
        color: #69edd1;
      }
      &:nth-child(3) {
        color: #f9d071;
      }
      &:nth-child(4) {
        color: #f36dc7;
      }
      &:nth-child(5) {
        color: #8400ff;
      }
    }
  }
}
// #ranking-board {
  .dv-scroll-ranking-board {
      .ranking-info {
        font-size: 12px !important;
      }
  }
  .ranking-board-title {
    font-weight: bold;
    height: 50px;
    display: flex;
    align-items: center;
    font-size: 20px;
  }

  // color: #1fb5fc;
  // color: #69edd1;
  // color: #f9d071;
  // color: #f36dc7;
  // color: #8400ff;
// }
</style>
