<template>
  <div>
    <!-- <div class="yuan"> -->
    <div id="container2"></div>
    <!-- </div> -->
  </div>
</template>

<script>
import AMapLoader from "@amap/amap-jsapi-loader";
export default {
  created() {
    // this.getData();
    this.getlocation();
    // this.initYearElectricitys();
  },
  methods: {
    // 获取坐标
    getlocation() {
      this.$http.post("/PcRealMonitoring/PCPosition", {}).then((res) => {
        this.locationList = res.data.data;
        this.initAMap();
      });
    },
    initAMap() {
      window._AMapSecurityConfig = {
        securityJsCode: "5e8845c918ec7001f3786bd5084600a2",
      };
      AMapLoader.load({
        key: "799fe76ff4c5688043063daf13d29ff9", // 申请好的Web端开发者Key，首次调用 load 时必填
        version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: ["AMap.Scale"], //需要使用的的插件列表，如比例尺'AMap.Scale'，支持添加多个如：['...','...']
      })
        .then((AMap) => {
          const map = new AMap.Map("container2", {
            // 设置地图容器id
            //   viewMode: "3D", // 是否为3D地图模式
            zoom: 5, // 初始化地图级别
            layers: [
              new AMap.TileLayer.Satellite(),
              new AMap.TileLayer.RoadNet(),
            ],
            center: [116.397428, 29.90923], // 初始化地图中心点位置
          });
          var redIcon = new AMap.Icon({
            size: new AMap.Size(36, 36), // 图标尺寸
            image:
              "https://yunweibang.nengliangkeji.com/resources/upload/qizione.png", // 图标的图片地址
            imageSize: new AMap.Size(40, 40), // 图标的尺寸
          });
          var yellowIcon = new AMap.Icon({
            size: new AMap.Size(36, 36), // 图标尺寸
            image:
              "https://yunweibang.nengliangkeji.com/resources/upload/qizi2.png", // 图标的图片地址
            imageSize: new AMap.Size(40, 40), // 图标的尺寸
          });
              var geryIcon = new AMap.Icon({
            size: new AMap.Size(36, 36), // 图标尺寸
            image:
              "https://yunweibang.nengliangkeji.com/resources/upload/qizi3.png", // 图标的图片地址
            imageSize: new AMap.Size(40, 40), // 图标的尺寸
          });
          // 生成坐标
          this.locationList.forEach((item) => {
            const longitude = parseFloat(item.longitude);
            const latitude = parseFloat(item.latitude);

            let icon;

            // 根据不同的状态选择不同的图标  1:正常 2:故障 3:通讯中断
            switch (Number(item.powerStationStatus)) {
              case 1:
                icon = redIcon;
                break;
              case 2:
                icon = yellowIcon;
                break;
              case 3:
                icon = geryIcon;
                break;
            }

            let marker = new AMap.Marker({
              position: new AMap.LngLat(longitude, latitude), // 经纬度位置
              icon:icon, // 使用自定义的红色图标
            });
            // 添加
            map.add(marker);

            const infoWindow = new AMap.InfoWindow({
              content: "", // 初始为空，或者可以放一个加载中的提示
              anchor: "bottom-right",
            });
            // 点击
            marker.on("mouseover", async (e) => {
              // 创建一个模板信息窗体

              infoWindow.on("open", function () {
                // 获取InfoWindow的DOM元素
                const infoWindowDom =
                  document.querySelector(".amap-info-content");
                // 修改InfoWindow的背景色为黑色，文字颜色为白色
                infoWindowDom.style.backgroundColor = "#051525";
              });
              // 打开
              infoWindow.open(map, marker.getPosition());
              const res = await this.$http.post(
                "/PcRealMonitoring/PCPositionList",
                {
                  powerStationCode: item.powerStationCode,
                  longitude: item.longitude,
                  latitude: item.latitude,
                }
              );
              if (res.data.code === 200) {
                let ddll = res.data.data;
                // <img style="display:inline-block;width: 50px;height: 100%;vertical-align:middle" src="${ddll[0]}" alt="">
                //信息窗体的内容
                let content = [
                  `  <div style="display: flex;width: 200px;height: 70px; color:#fff;font-size:14px ">
                       <div style="display:inline-block;vertical-align:middle">
                            <div style="margin:5px;">    ${ddll[1]}</div>
                              <div style="margin:5px"> 今日发电量：${ddll[2]} <span style="font-size:12px">度</span></div>
                       </div>
                   </div>`,
                ];

                // 更新信息窗体内容
                if (infoWindow) {
                  infoWindow.setContent(content);
                  infoWindow.open(map, marker.getPosition());
                }
              } else {
                this.$message.error(res.data);
              }
            });

            marker.on("mouseout", function (e) {
              infoWindow.close(map, marker.getPosition());
            });
          });
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
// .yuan {
//   width: 900px;
//   height: 900px;
//   margin: auto;
//   flex-shrink: 0;
//   border-radius: 50%;
//   overflow: hidden;
//   position: relative;

//   // border: 10px solid #59fded;
//   filter: drop-shadow(0 0 30px rgba(255, 255, 255, 0.8)); /* 元素白色发光效果 */
//   // filter: drop-shadow(0 0 30px rgba(86, 248, 239, 0.6)); /* 元素白色发光效果 */
//   z-index: 999;
// }

#container2 {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}
.amap-copyright {
  opacity: 0;
}
.amap-container {
  background-color: transparent !important;
}
</style>
