<template>
  <div class="container">
    <div class="mapbox">
      <mapVue class="map"></mapVue>
    </div>
    <div class="left">
      <img src="../../assets/mapNav/cicle.png" width="50px" class="cicle" />
      <div class="leftList">
        <div class="left-item">
          <div class="color color1"></div>
          <div class="title">实时功率</div>
          <div class="value">
            <countTo :startVal="0" :endVal="modedata.currEfficiency
                ? (modedata.currEfficiency / 1000) * 1
                : 0
              " :duration="3000"></countTo>
            <!-- {{
              modedata.currEfficiency
                ? Number(modedata.currEfficiency).toFixed(0)
                : 0
            }} -->
            <span class="unit">kw</span>
          </div>
        </div>
        <div class="left-item">
          <div class="color color2"></div>
          <div class="title">日发电量</div>
          <div class="value">
            <countTo :startVal="0" :endVal="obj.sumCurrDayPowerNum
                ? (obj.sumCurrDayPowerNum * 1).toFixed(0)
                : 0
              " :duration="3000"></countTo>

            <!-- {{
              modedata.currDayPowerNum
                ? Number(modedata.currDayPowerNum).toFixed(0)
                : 0
            }} -->
            <span class="unit">度</span>
          </div>
        </div>
        <div class="left-item">
          <div class="color color3"></div>
          <div class="title">月发电量</div>
          <div class="value">
            <countTo :startVal="0" :endVal="obj.sumCurrMonthPowerNum
                ? (obj.sumCurrMonthPowerNum * 1).toFixed(0)
                : 0
              " :duration="3000"></countTo>

            <span class="unit">度</span>
          </div>
        </div>
        <div class="left-item">
          <div class="color color4"></div>
          <div class="title">年发电量</div>
          <div class="value">
            <countTo :startVal="0" :endVal="obj.sumCurrYearPowerNum
                ? (obj.sumCurrYearPowerNum * 1).toFixed(0)
                : 0
              " :duration="3000"></countTo>

            <!-- {{
              modedata.totalPowerNum
                ? Number(modedata.totalPowerNum).toFixed(0)
                : 0
            }} -->
            <span class="unit">度</span>
          </div>
        </div>
        <div class="left-item">
          <div class="color color5"></div>
          <div class="title">月日均等效时</div>
          <div class="value">
            <countTo :startVal="0" :endVal="efficient.equivalentHours ? efficient.equivalentHours * 1 : 0
              " :duration="3000"></countTo>
            <!-- {{
              efficient.equivalentHours
                ? Number(efficient.equivalentHours).toFixed(0)
                : 0
            }} -->
            <span class="unit">度</span>
          </div>
        </div>
        <div class="left-item">
          <div class="color color6"></div>
          <div class="title">年日均等效时</div>
          <div class="value">
            <countTo :startVal="0" :endVal="efficient.yaervalentHours ? efficient.yaervalentHours * 1 : 0
              " :duration="3000"></countTo>
            <!-- {{
              efficient.yaervalentHours
                ? Number(efficient.yaervalentHours).toFixed(0)
                : 0
            }} -->
            <span class="unit">度</span>
          </div>
        </div>
      </div>
      <dv-decoration-2 style="width: 5px; height: 600px" :reverse="true" />
    </div>
    <div class="right">
      <div class="top">
        <dv-border-box-13 :color="['#11aeda']">
          <div class="topList">
            <el-row type="flex" align="center">
              <div class="top-item">
                <div class="cicle">
                  <div class="subCicle"></div>
                  <img src="@/assets/mapNav/1.png" alt="" />
                </div>
                <div class="top-item-right">
                  <div class="numberS">
                    <span class="num">
                      {{ obj.volumeCount ? obj.volumeCount * 1 : 0 }}</span>
                    <span class="unit">kw</span>
                  </div>
                  <div>装机容量</div>
                </div>
              </div>
              <div class="top-item">
                <div class="cicle">
                  <div class="subCicle"></div>
                  <img src="@/assets/mapNav/3.png" alt="" />
                </div>
                <div class="top-item-right">
                  <div class="numberS">
                    <span class="num">{{
                      obj.stationCount ? obj.stationCount * 1 : 0
                    }}</span>
                    <span class="unit">座</span>
                  </div>
                  <div>电站数量</div>
                </div>
              </div>
            </el-row>
            <el-row type="flex" align="center" style="margin-top: 10px">
              <div class="top-item">
                <div class="cicle">
                  <div class="subCicle"></div>
                  <img src="@/assets/mapNav/2.png" alt="" />
                </div>
                <div class="top-item-right">
                  <div class="numberS">
                    <span class="num">{{
                      obj.sumCurrYearPowerNum ? (obj.sumCurrYearPowerNum * 1).toFixed(0) : 0
                    }}</span>
                    <span class="unit">t</span>
                  </div>
                  <div>年累计减排CO2</div>
                </div>
              </div>
              <div class="top-item">
                <div class="cicle">
                  <div class="subCicle"></div>
                  <img src="@/assets/mapNav/4.png" alt="" />
                </div>
                <div class="top-item-right">
                  <div class="numberS">
                    <span class="num">{{
                      obj.sumCurrYearPowerNum ? 114 * Number(obj.sumCurrYearPowerNum).toFixed(0) : 0
                    }}</span>
                    <!-- {{ co ? Number(co.yearCO2Reduction).toFixed(0) : 0
                      }} -->
                    <span class="unit">t</span>
                  </div>
                  <div>年累计节约标准煤</div>
                </div>
              </div>
            </el-row>
          </div>
        </dv-border-box-13>
      </div>
      <div class="center">
        <dv-border-box-13 :color="['#11aeda']">
          <div class="du">月电量统计<span class="fx14">(度)</span></div>
          <monthEcharts class="monthEcharts"></monthEcharts>
        </dv-border-box-13>
      </div>
      <div class="bottom">
        <dv-border-box-13 :color="['#11aeda']">
          <div class="du">年电量统计<span class="fx14">(度)</span></div>
          <yearEcharts class="yearEcharts"></yearEcharts>
        </dv-border-box-13>
      </div>
    </div>
    <timeVue class="times"></timeVue>
  </div>
</template>

<script>
import mapVue from "./components/map.vue";
import monthEcharts from "./components/monthEcharts.vue";
import yearEcharts from "./components/yearEcharts.vue";
import timeVue from "./components/time.vue";
export default {
  components: { mapVue, monthEcharts, yearEcharts, timeVue },
  data() {
    return {
      count: {},
      co: {},
      modedata: {},
      efficient: {},
      obj: {},
    };
  },
  created() {
    this.getData();
    this.getData2();
  },
  methods: {
    // 获取数据
    async getData() {
      const res = await this.$http.post("PcRealMonitoring/PCPositionList1", {});
      if (res.data.code === 200) {
        // 电站建设发电量
        this.modedata = res.data.data[0][0];

        const colorList = [
          "#f9d071",
          "#fe6dc7",
          "#8400ff",
          "#1fb5fc",
          "#69edd1",
        ];
        res.data.data[1].forEach((item, index) => {
          item.color = colorList[index];
        });

        // 二氧化碳
        this.co = res.data.data[4][0];
        // 数量 容量
        this.count = res.data.data[7][0];
        this.efficient = res.data.data[5][0];
      } else {
        this.$message.error(res.data);
      }
    },
    async getData2() {
      const res = await this.$http.post(
        "device/queryUserAllStationDailyPower",
        { userCode: JSON.parse(localStorage.getItem("userInfo")).userCode
        // stationCodeList: JSON.parse(localStorage.getItem('stationCodeList')) 
      }
      );
      if (res.data.code === 200) {
        this.obj = res.data.data;
      } else {
        this.$message.error(res.data);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  position: absolute;
  top: 85px;
  left: 0;
  bottom: 0;
  right: 0;
  background: linear-gradient(to bottom, #051525, #0e2944ed);
}

.mapbox {
  width: 70%;
  height: 100%;

  .map {
    width: 100%;
    height: 100%;
    border: 1px solid #11aeda;
  }
}

::v-deep .rightBox .el-progress__text {
  color: #fff !important;
}

.left {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(0, -50%);
  // background-color: skyblue;
  width: 300px;
  height: 700px;
  background: url("../../assets/mapNav/u4.png") no-repeat center center;
  background-size: cover;

  .cicle {
    position: absolute;
    right: 0;
    top: 14px;
    animation: circle 2s infinite linear;
  }

  .leftList {
    position: absolute;
    left: 30px;
    top: 100px;
    width: 210px;
    height: 600px;

    .left-item {
      width: 100%;
      height: 80px;
      background-color: #1e466b;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      text-align: end;
      padding: 10px;
      box-sizing: border-box;

      margin: 10px;

      .title {
        color: #dae8ff;
      }

      .value {
        color: #8bf7f4;
        font-family: "YouSheBiaoTiHei";
        font-size: 22px;
      }

      .color {
        width: 20px;
        height: 20px;
        position: absolute;
        left: 0;
        top: 0;
        border-radius: 5px;
      }

      .color1 {
        background-color: #f9d071;
      }

      .color2 {
        background-color: #fe53bd;
      }

      .color3 {
        background-color: #7155c5;
      }

      .color4 {
        background-color: #1fb5fc;
      }

      .color5 {
        background-color: #d81e06;
      }

      .color6 {
        background-color: #2acfa9;
      }
    }
  }
}

.right {
  width: calc(100vw - 1300px);
  height: 100%;
  box-sizing: border-box;
  background-color: #001933;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  .top {
    width: 100%;
    height: 25vh;
    color: #fff;

    .topList {
      padding: 10% 5%;
      text-align: center;
      display: flex;
      justify-content: center;
      flex-direction: column;
      height: 100%;

      .top-item {
        flex: 1;
        display: flex;
        align-items: center;
        width: 100%;
        padding: 2% 0;
        background-color: #1b4167;
        border-radius: 100px;

        .cicle {
          position: relative;
          width: 70px;
          height: 70px;

          .subCicle {
            position: absolute;
            border-radius: 50%;
            width: 70px;
            height: 70px;
            background: url("../../assets/mapNav/ciclebg.png") no-repeat center center;
            animation: circle 4s infinite linear;
            background-size: cover;
          }

          img {
            position: absolute;
            width: 30px;
            height: 30px;
            display: block;
            top: 50%;
            left: 50%;
            margin-left: -15px;
            margin-top: -15px;
          }
        }

        .top-item-right {
          margin-left: 15px;
        }

        .numberS {
          color: #dff8ff;

          .num {
            color: #e6a23c;
            font-size: 26px;
            font-family: "YouSheBiaoTiHei";
          }
        }
      }
    }

    img {
      width: 80px;
      height: 80px;
    }
  }

  .center,
  .bottom {
    width: 100%;
    height: 32vh;
    position: relative;

    .monthEcharts,
    .yearEcharts {
      width: 100%;
      height: 85%;
    }

    ::v-deep .border-box-content {
      // padding: 60px 20px 20px 20px;
      box-sizing: border-box;
    }

    .du {
      font-size: 18px;
      color: #dff8ff;
      padding-top: 30px;
      padding-left: 20px;

      .fx14 {
        font-size: 16px;
      }
    }
  }
}

.unit {
  display: inline-block;
  margin-left: 5px;
  font-size: 16px;
}

/* 使用伪元素 ::after 来选择 (度) 部分 */
// ::v-deep .dv-border-box-11::after {
//   content: "(度)";
//   font-size: 18px; /* 小一号字体 */
//   color: #fff;
//   position: absolute;
//   right: 100px;
//   top: 20px;
// }
.dv-border-box-11-title {
  font-size: 16px !important;
}

@keyframes circle {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(-360deg);
  }
}
</style>
